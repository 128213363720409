export const getNumberAppendixFromString = (str: string) => {
  let appendix = '';
  let numberString = str.replace(',', '.');

  while (numberString.length > 0 && isNaN(Number(numberString))) {
    appendix = numberString.slice(-1) + appendix;
    numberString = numberString.slice(0, -1);
  }

  return {
    value: numberString,
    appendix,
  };
};
