import React, { useMemo } from 'react';

import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import Underline from 'images/highlights/line-3-cross.svg';

import { TextDecorationSvg } from '@/components/functional';
import { AnimatedNumber } from '@/components/functional/AnimatedNumber';
import { AnimateWhenInViewWrapper, SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { StatisticsFragment } from '@/graphql/generated';
import { getNumberAppendixFromString } from '@/utils/helper-functions/getNumberAppendixFromString';

type StatisticsSectionProps = {
  heading?: string | null;
  darkBackground?: boolean | null;
  underlinedValues?: boolean | null;
  statistics: Array<StatisticsFragment | null>;
};

export const StatisticsSection = ({
  statistics,
  heading,
  underlinedValues,
  darkBackground,
}: StatisticsSectionProps) => {
  const mappedStats = useMemo(() => {
    return statistics
      ?.map((stat) => {
        if (!stat) return null;
        const { value } = stat;

        const valueWithAppendix = getNumberAppendixFromString(value);
        return {
          ...stat,
          valueWithAppendix,
        };
      })
      .sort((a, b) => Number(a?.valueWithAppendix.value) - Number(b?.valueWithAppendix.value));
  }, [statistics]);

  return (
    <SectionWrapper zIndex={0} id="statistics" bg={darkBackground ? 'violet.500' : undefined}>
      <SectionContentWrapper>
        {heading && (
          <Heading
            textAlign="center"
            color={darkBackground ? 'white' : 'black'}
            variant="component-heading-large/2xl"
            mb="60px"
          >
            {heading}
          </Heading>
        )}
        <Flex
          w="100%"
          justifyContent="space-around"
          alignItems={{
            base: 'center',
            sm: 'start',
          }}
          mb={14}
          flexDir={{
            base: 'column',
            sm: 'row',
          }}
        >
          {mappedStats?.map((stat, index) => (
            <Flex
              flexDir="column"
              justifyContent="center"
              p={2}
              flex={1}
              key={`statistic-number-${index}`}
              mb={{
                base: 10,
                sm: 0,
              }}
            >
              <AnimateWhenInViewWrapper>
                <Text
                  variant={{
                    base: 'text-6xl/lineHeight-12/font-semibold',
                    sm: 'text-5xl/lineHeight-11/font-semibold',
                    md: 'text-6xl/lineHeight-12/font-semibold',
                  }}
                  textAlign="center"
                  pos="relative"
                >
                  {underlinedValues && (
                    <Box as="span" display="block" pos="absolute" left="50%" transform="translateX(-50%)">
                      <TextDecorationSvg line={Underline} color="lime">
                        <Box as="span" aria-hidden="true" visibility="hidden" w="200px">
                          0000
                        </Box>
                      </TextDecorationSvg>
                    </Box>
                  )}
                  <Box as="span" color={darkBackground ? 'white' : 'violet.500'}>
                    <AnimatedNumber
                      durationS={index + 1 / 2}
                      finalValue={Number(stat?.valueWithAppendix.value)}
                      startingValue={0}
                    />
                  </Box>

                  <Box as="span" color={darkBackground ? 'white' : 'lime.600'}>
                    {stat?.valueWithAppendix.appendix}
                  </Box>
                </Text>

                <Text
                  color="purple.500"
                  mt={underlinedValues ? 10 : 3.5}
                  variant={{
                    base: 'text-md/lineHeight-6/font-semibold',
                    sm: 'text-sm/lineHeight-5/font-semibold',
                    md: 'text-md/lineHeight-6/font-semibold',
                  }}
                  textAlign="center"
                >
                  {stat?.description}
                </Text>
                <Text
                  variant={{
                    base: 'text-xs/lineHeight-4/font-semibold/0.75rem',
                  }}
                  textAlign="center"
                >
                  {stat?.descriptionAdd}
                </Text>
              </AnimateWhenInViewWrapper>
            </Flex>
          ))}
        </Flex>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};
