export const normalizeUrl = (url: string | undefined): string => {
  if (url && url.startsWith('https://')) {
    return url;
  }
  if (url && url.startsWith('http://')) {
    return url.replace('http://', 'https://');
  }
  if (url) {
    return `https://${url}`;
  }
  return '#';
};

export const isUrl = (url: string | undefined | null): boolean => {
  const value = url?.trim() || '';
  return value.startsWith('https://') || value.startsWith('http://');
};
