import React, { FC } from 'react';

import { Heading, Text, VStack } from '@chakra-ui/react';

import { Button } from '@/components/atoms/Button';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { DecoratedTextImgFragment, DynamicHeroFragment } from '@/graphql/generated';

import { DynamicTextWithDecoration } from '../DynamicTextWithDecoration';
import { getDecoratedTextImages } from '../utils/getDecoratedTextImages';

export const DynamicHeroBlock: FC<DynamicHeroFragment> = ({
  title,
  description,
  buttonCaption,
  buttonLink,
  decoratedTextImages,
}) => {
  const parsedImages = getDecoratedTextImages(decoratedTextImages as DecoratedTextImgFragment);

  return (
    <SectionWrapper>
      <SectionContentWrapper>
        <VStack
          spacing={{
            base: 9,
            sm: 10,
            lg: 12,
          }}
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          minH={{
            base: '250px',
            md: '350px',
            lg: '400px',
          }}
        >
          <Heading
            variant={{
              base: 'component-heading-large/xl',
              xs: 'component-heading-large/2xl',
              sm: 'component-heading-large/3xl',
            }}
            textAlign="center"
            color="purple.500"
            maxW={{
              base: '95%',
              sm: '85%',
              lg: '70%',
            }}
          >
            <DynamicTextWithDecoration {...parsedImages} isDarkMode>
              {title}
            </DynamicTextWithDecoration>
          </Heading>
          {description ? (
            <Text
              variant={{
                base: 'text-md/lineHeight-6/font-normal',
                sm: 'text-xl/lineHeight-7/font-normal',
              }}
              textAlign="center"
              color="purple.400"
              maxW={{
                base: '80%',
                sm: '80%',
                lg: '70%',
              }}
            >
              <DynamicTextWithDecoration>{description}</DynamicTextWithDecoration>
            </Text>
          ) : null}
          {buttonCaption && buttonLink ? (
            <Button
              w={{
                base: '100%',
                xs: 'auto',
              }}
              size="lg"
              variant="light"
              url={buttonLink}
            >
              {buttonCaption}
            </Button>
          ) : null}
        </VStack>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};
