import { memo, useEffect, useState } from 'react';

import { Box, Flex } from '@chakra-ui/react';

import {
  StatisticCard,
  cardFrameCommonStyles,
  shuffleAnimation,
  shuffleCardsZIndices,
  statisticCardStyles,
} from '@/components/sections/home';

interface ShuffleCardProps {
  value: string;
  label: string;
  showBadge: boolean;
  interval: number;
}

const textChangeTiming = 0.28; // it reflects 28% in keyframes animation

export const ShuffleCard = memo(function ShuffleCard(props: ShuffleCardProps) {
  const [oldText, setOldText] = useState<ShuffleCardProps>();
  const [newText, setNewText] = useState<ShuffleCardProps>();
  const [initialRender, setInitialRender] = useState(true);
  // we need `shouldAnimate' for react to trigger animation when props change,
  // props change trigger rerender but do not trigger animation reset
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const { interval } = props;

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      setOldText(props);
      setNewText(props);
      return;
    }
    setShouldAnimate(true);
    if (!oldText) {
      setOldText(props);
    }
    setNewText(props);
    const animationResetTimeoutIndex = setTimeout(() => setShouldAnimate(false), interval);
    const staticCardTextChangeTimeoutIndex = setTimeout(() => setOldText(props), textChangeTiming * interval);

    return () => {
      clearTimeout(animationResetTimeoutIndex);
      clearTimeout(staticCardTextChangeTimeoutIndex);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  if (!oldText || !newText) return null;

  return (
    <Box sx={statisticCardStyles}>
      <Flex sx={cardFrameCommonStyles} position="relative">
        {/*cards pile in the background*/}
        <Flex
          bgColor="lime.500"
          sx={cardFrameCommonStyles}
          style={{
            position: 'absolute',
            zIndex: shuffleCardsZIndices.base,
            transform: 'perspective(1000px) translateY(-10px) rotateX(-180deg) rotateZ(5deg)',
          }}
        />

        {/*static card with text that stays visible during animation*/}
        <StatisticCard
          {...oldText}
          willShowBadge={newText.showBadge}
          style={{
            zIndex: shouldAnimate ? shuffleCardsZIndices.strong : shuffleCardsZIndices.low,
          }}
        />

        {/*front of the animated card*/}
        <StatisticCard
          {...newText}
          showBadge={false}
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
          }}
          animation={shouldAnimate ? `${shuffleAnimation('front')} ${interval}ms ease-in-out` : 'none'}
        />

        {/*back of the animated card */}
        <Flex
          bgColor="lime.500"
          sx={cardFrameCommonStyles}
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: shuffleCardsZIndices.base,
          }}
          animation={shouldAnimate ? `${shuffleAnimation('back')} ${interval}ms ease-in-out` : 'none'}
        />
      </Flex>
    </Box>
  );
});
