import { Flex, Heading, Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { CaseStudiesList } from '@/components/sections/common';
import { CaseStudyActionButton } from '@/components/sections/home';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { CaseStudiesFragment } from '@/graphql/generated';
import { LOCALES } from '@/utils/constants';

interface CaseStudiesProps {
  data: CaseStudiesFragment;
}

export const CaseStudies = ({ data }: CaseStudiesProps) => {
  const { caseStudies } = data;
  const { t } = useTranslation(LOCALES.HOME);

  return (
    <SectionWrapper
      zIndex={0}
      overflow={{
        base: 'visible',
        md: 'hidden',
      }}
      id="caseStudiesSection"
    >
      <SectionContentWrapper
        maxHeight={{
          base: 'unset',
          md: '1800px',
        }}
      >
        <Flex flexDirection={{ base: 'column' }} mb={10}>
          <Heading
            variant={{
              base: 'component-heading-large/xl',
              md: 'component-heading-large/3xl',
            }}
            color="purple.500"
          >
            {t('caseStudies.title')}
          </Heading>
          <Text
            variant={{
              base: 'text-md/lineHeight-6/font-normal',
              md: 'text-2xl/lineHeight-8/font-normal',
            }}
            color="purple.500"
            mt={5}
          >
            {t('caseStudies.subtitle')}
          </Text>
        </Flex>

        <CaseStudiesList caseStudies={caseStudies?.data || []} isHomepageSection={true} showTags={true} />
        <CaseStudyActionButton />
      </SectionContentWrapper>
    </SectionWrapper>
  );
};
