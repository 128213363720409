import { Box, Hide, Show } from '@chakra-ui/react';
import ChartBackgroundDesktop from 'images/backgrounds/ChartBackground.png';
import ChartBackgroundMobile from 'images/backgrounds/ChartBackgroundMobile.png';
import ChartDesktop from 'images/backgrounds/chartLine.svg';
import Image from 'next/image';

export const ChartBackground = () => {
  return (
    <>
      <Show below="xl">
        <Box position="absolute" as={Image} src={ChartBackgroundMobile} zIndex="-2" bottom={0} right={0} />
      </Show>
      <Hide below="xl">
        <Box
          position="absolute"
          left="0"
          bottom="0"
          right="0"
          top="0"
          sx={{
            '& > svg': {
              xl: {
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: '-160px',
                left: '-30px',
              },
              '2xl': {
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: '-180px',
                left: '-80px',
              },
              '3xl': {
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: '-200px',
                left: '150px',
              },
            },
          }}
        >
          <ChartDesktop />
          <Box
            position="absolute"
            as={Image}
            src={ChartBackgroundDesktop}
            zIndex="-2"
            top={{ base: '4%', '3xl': '60%' }}
            right={0}
          />
        </Box>
      </Hide>
    </>
  );
};
