import * as en from '@/components/sections/dynamic-blocks/DynamicConstantBlock/utils/copy-en';
import * as pl from '@/components/sections/dynamic-blocks/DynamicConstantBlock/utils/copy-pl';
import { AvailableLanguages } from '@/utils/constants';

export const useConstantData = (lang: AvailableLanguages) => {
  const {
    facesSection,
    gallerySection,
    scrollSliderSection,
    recommendedArticlesSection,
    technologySection,
    timelineSection,
  } = { en: { ...en }, pl: { ...pl } }[lang];

  return {
    technologySection,
    timelineSection,
    recommendedArticlesSection,
    facesSection,
    gallerySection,
    scrollSliderSection,
  };
};
