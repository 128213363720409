import { z } from 'zod';

export const MAX_NUMBER_OF_FILES = 3;
export const MAX_FILE_SIZE_MB = 25;
export const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024; // 25Mb
export const SUPPORTED_MIME_TYPES = [
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'text/plain',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/rtf',
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
  'image/svg+xml',
  'image/heic',
];

export const MAX_MESSAGE_CHARACTERS = 3000;

global.FileList = typeof window !== 'undefined' ? window.FileList : (class FileList {} as never);

const baseSchema = {
  name: z.string().min(3, 'At least 3 characters').max(250, 'Max 250 characters'),
  email: z.string().email().max(250, 'Max 250 characters'),
  phoneNumber: z
    .string()
    .min(9, { message: 'Invalid phone number' })
    .max(20, { message: 'Invalid phone number' })
    .optional()
    .or(z.literal('')),
  privacyPolicy: z.boolean().refine((value) => value === true),
  captchaToken: z.string(),
};

export const contactFormSchema = z.object({
  ...baseSchema,
  message: z.string().max(MAX_MESSAGE_CHARACTERS, `Max ${MAX_MESSAGE_CHARACTERS} characters`).optional(),
  purpose: z.string(),
  attachments: z
    .any()
    .optional()
    .refine((files) => files && files.length <= MAX_NUMBER_OF_FILES, {
      message: `Max ${MAX_NUMBER_OF_FILES} attachments`,
    })
    .refine((files) => files && files.every((file: File) => file.size <= MAX_FILE_SIZE), {
      message: `Max file size is ${MAX_FILE_SIZE_MB} MiB`,
    })
    .refine(
      (files) => {
        if (Array.isArray(files)) {
          const allFilesSize = files.reduce((accumulator: number, file: File) => accumulator + file.size, 0);
          return allFilesSize <= MAX_FILE_SIZE;
        }
        return true;
      },
      {
        message: `Max size of all files is ${MAX_FILE_SIZE_MB} MiB`,
      },
    )
    .refine((files) => files && files.every((file: File) => SUPPORTED_MIME_TYPES.includes(file.type)), {
      message:
        'Supported file types are .pdf .xls .xlsx .odt .ppt .pptx .txt .doc .docx .rtf .jpeg .png .gif .webp .svg .heic',
    }),
});

export const simpleContactFormSchema = z.object({
  ...baseSchema,
  message: z.string().max(200, 'Max 200 characters').optional(),
});
