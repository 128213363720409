import { ChildNode } from 'domhandler';
import { Element } from 'html-react-parser';

export function getInnerHtml(node: ChildNode): string {
  if (node.type === 'text') {
    return node.data;
  }

  if (node instanceof Element) {
    return node.children.map(getInnerHtml).join('');
  }
  return '';
}
