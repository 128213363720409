import { Box, Heading } from '@chakra-ui/react';

import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { LetsBuildBlockFragment } from '@/graphql/generated';

import { DynamicTextWithDecoration } from '../DynamicTextWithDecoration';
import { LetsBuildSomethingBackground } from './LetsBuildSometingBackground';

interface LetsBuildSomethingSectionProps extends LetsBuildBlockFragment {
  overflow?: boolean;
}

export const DynamicLetsBuildBlock = ({ overflow = true, ctaText }: LetsBuildSomethingSectionProps) => {
  return (
    <SectionWrapper
      backgroundColor="purple.500"
      zIndex={0}
      id="caseStudiesSection"
      h={{ base: '570px', sm: '440px', md: '400px' }}
      overflow={overflow ? 'visible' : 'hidden'}
    >
      <SectionContentWrapper
        py={20}
        px={{ base: 5, md: 20 }}
        position="relative"
        h="100%"
        display="flex"
        justifyContent={{ base: 'center', sm: 'start' }}
        alignItems={{ base: 'start', sm: 'center' }}
      >
        <LetsBuildSomethingBackground />

        <Box
          maxW={{ base: 'min(60%, 200px)', xs: '200px', md: '300px' }}
          ml={{
            base: '0',
            sm: '12%',
            md: '8%',
            xl: '12%',
          }}
        >
          <Heading
            variant={{
              base: 'component-heading-large/xl',
              md: 'component-heading-large/3xl',
            }}
            color="white"
            textAlign={{ base: 'center', sm: 'start' }}
          >
            <DynamicTextWithDecoration isDarkMode>{ctaText}</DynamicTextWithDecoration>
          </Heading>
        </Box>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};
