import React, { FC } from 'react';

import { Box, Flex, Heading, Show, Text } from '@chakra-ui/react';

import { Button } from '@/components/atoms';
import { ClutchBadge } from '@/components/molecules/ClutchBadge/ClutchBadge';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { DynamicHeroWithContactFragment } from '@/graphql/generated';

import { LLShapedBackground } from '../../services/sections/atoms/doodle-background-ll-shape/LLShapedBackground';
import { DynamicContactFormCompact } from '../DynamicContactFormCompact';
import { DynamicTextWithDecoration } from '../DynamicTextWithDecoration';

export const DynamicHeroWithContactBlock: FC<DynamicHeroWithContactFragment> = ({
  ctaTop,
  ctaBottom,
  backgroundImage,
  withDoodleInBackground,
  hideMobileButton,
  ...props
}) => {
  return (
    <SectionWrapper
      bgImage={backgroundImage?.data ? backgroundImage?.data?.attributes?.url : ''}
      bgRepeat="no-repeat"
      bgPos="center"
      bgSize="cover"
      bgColor={backgroundImage?.data ? 'transparent' : 'purple.600'}
      zIndex={0}
    >
      <SectionContentWrapper>
        {withDoodleInBackground ? <LLShapedBackground /> : null}
        <Flex p={{ base: '400px 24px 0', lg: '0 160px' }} justify="space-between">
          <Flex w={{ base: '100%', lg: '50%' }} flexDir="column" justify="space-between">
            <Box>
              <Heading
                variant={{
                  xs: 'component-heading-large/3xl',
                  sm: 'component-heading-large/5xl',
                  xl: 'component-heading-large/6xl',
                }}
                color="white"
                mt="110px"
                mb="16px"
              >
                <DynamicTextWithDecoration>{ctaTop ?? ''}</DynamicTextWithDecoration>
              </Heading>
              <Text color="white">{ctaBottom ?? ''}</Text>
              {!hideMobileButton && (
                <Show below="lg">
                  <Button url="/contact" mt="20px">
                    {props.contactCardTitle}
                  </Button>
                </Show>
              )}
            </Box>
            <Flex mb="12px" mt={{ base: 54, lg: 0 }} justify={{ base: 'center', lg: 'flex-start' }}>
              <ClutchBadge variant="light" />
            </Flex>
          </Flex>
          <Show above="lg">
            <Box>
              <DynamicContactFormCompact {...props} />
            </Box>
          </Show>
        </Flex>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};
