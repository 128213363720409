import { Box, Flex, Grid, GridItem, Heading, Hide, Show } from '@chakra-ui/react';
import ChartMobile from 'images/backgrounds/chartMobile.svg';

import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { DynamicChartSectionFragment } from '@/graphql/generated';

import { ChartBackground } from './components/ChartBackground';
import { ChartPoint } from './components/ChartPoint';

const ROW_INDEXES = [10, 8, 6, 4, 1];

export const DynamicChartSection = ({ header, items }: DynamicChartSectionFragment) => {
  return (
    <SectionWrapper backgroundColor="violet.800" id="chart-section">
      <SectionContentWrapper zIndex="0" overflow="hidden">
        <ChartBackground />
        <Box w="full">
          <Heading
            as="h2"
            fontFamily={'body'}
            fontWeight={400}
            color="white"
            fontSize={{ base: '36px', lg: '52px' }}
            marginBottom={{ base: 6, sm: 0 }}
            maxWidth={{ base: '300px', sm: '450px', xl: '650px' }}
            minHeight={{ xl: '187px' }}
          >
            {header}
          </Heading>
          <Show below="xl">
            <Flex position="relative" justifyContent="center" gap="10px">
              <Flex
                flexBasis={'10%'}
                sx={{
                  '& > svg': {
                    base: {
                      top: '0',
                      width: '100%',
                      height: '82.5%',
                    },
                  },
                }}
              >
                <ChartMobile />
              </Flex>
              <Flex flexDir="column" alignItems="flex-start">
                {items.map((el, index) => {
                  if (el && el.heading && el.list) {
                    return <ChartPoint key={index} title={el?.heading || ''} points={el?.list || []} />;
                  }
                  return null;
                })}
              </Flex>
            </Flex>
          </Show>
          <Hide below="xl">
            <Grid
              templateRows="repeat(10,30px)"
              templateColumns={{ base: 'repeat(5,260px)', '2xl': 'repeat(5,280px)' }}
              pb={4}
              position={{ xs: 'relative' }}
              top={'-50px'}
              left={'-10px'}
              columnGap="20px"
              rowGap="10px"
            >
              {items.map((el, index) => {
                if (el && el.heading && el.list) {
                  return (
                    <GridItem key={el?.heading} gridColumn={index + 1} gridRow={ROW_INDEXES[index]}>
                      <ChartPoint title={el?.heading || ''} points={el?.list || []} />
                    </GridItem>
                  );
                }
                return null;
              })}
            </Grid>
          </Hide>
        </Box>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};
