import React, { FC } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';
import ClutchLogoLight from 'icons/clutch-light.png';
import ClutchLogo from 'icons/clutch.png';
import StarYellow from 'images/icons/star-yellow.svg';
import Star from 'images/icons/star.svg';
import Image from 'next/image';

export const ClutchBadge: FC<{ variant?: 'dark' | 'light' }> = ({ variant = 'dark' }) => {
  const textColor = variant === 'dark' ? '#6a797e' : '#6B46C1';
  return (
    <Flex w="fit-content">
      <Flex flex={1} flexDir="column" mr={5}>
        <Text
          fontFamily={['Roboto', 'sans-serif']}
          whiteSpace="nowrap"
          color={textColor}
          fontSize="16px"
          letterSpacing="1px"
          fontWeight={500}
          mb={1.5}
          lineHeight="20px"
          as="span"
        >
          REVIEWED ON
        </Text>
        <Box
          as={Image}
          src={variant === 'dark' ? ClutchLogo : ClutchLogoLight}
          alt="clutch logo"
          height="auto"
          width={123}
        />
      </Flex>
      <Flex flex={1} flexDir="column" justifyContent="space-between">
        <Flex
          sx={{
            '& svg': {
              width: '26px',
              height: '26px',
            },
          }}
          gap="1px"
        >
          {Array.from({ length: 5 }).map((_, index) =>
            variant === 'dark' ? <Star key={`star-rating-${index}`} /> : <StarYellow key={`star-rating-${index}`} />,
          )}
        </Flex>
        <Text
          fontFamily={['Roboto', 'sans-serif']}
          whiteSpace="nowrap"
          color={textColor}
          fontSize="21px"
          letterSpacing="1px"
          fontWeight={500}
          as="span"
          lineHeight="15px"
        >
          23 REVIEWS
        </Text>
      </Flex>
    </Flex>
  );
};
