import { FC } from 'react';

import { Box, Flex, Grid, GridItem, Heading, Hide, Show, Text } from '@chakra-ui/react';
import Image from 'next/image';

import { Button } from '@/components/atoms';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { DynamicFeatureListFragment } from '@/graphql/generated';
import { getImageAlt } from '@/utils/images';

export const DynamicFeatureList: FC<DynamicFeatureListFragment> = ({
  cta,
  ctaImage,
  ctaImageMobile,
  items,
  largeImage,
  displayDigits,
  imagePosition,
  buttonLabel,
  buttonLink,
}) => {
  const imageSection = (
    <Box w="346px" maxW="100%" borderRadius="20px" pos="relative" h="186px" m={{ base: '0 auto', md: 0 }}>
      <Box
        w="100%"
        h="100%"
        pos="absolute"
        left={0}
        top={0}
        bg="lime.500"
        borderRadius="20px"
        transform="rotate(-3.29deg)"
      />
      <Image
        fill
        style={{ objectFit: 'cover', borderRadius: '20px' }}
        src={ctaImage?.data?.attributes?.url ?? ''}
        alt={getImageAlt(ctaImage?.data?.attributes, cta)}
      />
    </Box>
  );
  const gridSection = (
    <Grid
      templateColumns={{ base: '100%', md: '50% 50%' }}
      w={{ base: 'auto', md: largeImage ? '100%' : '60%' }}
      p={{ base: 0, md: displayDigits && imagePosition === 'right' ? '0 30px 0 0' : '0 30px' }}
      columnGap="20px"
      rowGap="26px"
      as="ul"
      listStyleType="none"
      maxW={largeImage ? { base: '100%', md: displayDigits ? undefined : '480px' } : undefined}
    >
      {items.map((item, index) => (
        <GridItem
          key={item?.id}
          as="li"
          pos="relative"
          minH={displayDigits ? { base: '220px', md: '250px' } : 'auto'}
          _last={{ h: 'auto' }}
        >
          {item?.image?.data?.attributes?.url && (
            <Box w="70px" maxW="100%" h="32px" pos="relative">
              <Image fill style={{ objectFit: 'contain' }} src={item?.image?.data?.attributes?.url ?? ''} alt="" />
            </Box>
          )}
          {displayDigits && (
            <Text
              pos="absolute"
              top={0}
              left={{ base: '50%', md: 0 }}
              fontSize="220px"
              lineHeight="250px"
              h="280px"
              fontWeight={900}
              background="linear-gradient(158.78deg, rgba(17, 10, 61, 0.48) 55.31%, rgba(217, 252, 89, 0.48) 164.19%)"
              color="transparent"
              backgroundClip="text"
              transform={{ base: 'translate(-50%, -25%)', md: 'translateY(-25%)' }}
              zIndex={0}
            >
              {index + 1}
            </Text>
          )}
          <Text
            color="beige.50"
            variant="component-paragraph-headline/xl"
            my="26px"
            fontWeight={700}
            as="h3"
            zIndex={1}
            pos="relative"
            textAlign={displayDigits ? { base: 'center', md: 'left' } : undefined}
          >
            {item?.text}
          </Text>
          <Text color="beige.50" variant="component-text/sm">
            {item?.paragraph}
          </Text>
        </GridItem>
      ))}
    </Grid>
  );

  const buttonSection =
    (buttonLabel && buttonLink && (
      <Button mt={4} url={buttonLink} variant={'light'} maxW="100%" justifySelf="flex-start">
        <Text as="span" maxW="100%" overflow="hidden" textOverflow="ellipsis">
          {buttonLabel}
        </Text>
      </Button>
    )) ||
    null;

  if (largeImage) {
    return (
      <SectionWrapper backgroundColor={largeImage ? 'purple.500' : 'violet.800'} id="features-section">
        <Show above="md">
          <Box
            pos="absolute"
            w="50%"
            top={0}
            left={imagePosition === 'left' ? 0 : undefined}
            right={imagePosition === 'right' ? 0 : undefined}
            h="100%"
          >
            <Image
              fill
              style={{ objectFit: 'cover' }}
              src={ctaImage?.data?.attributes?.url ?? ''}
              alt={getImageAlt(ctaImage?.data?.attributes, cta)}
            />
          </Box>
        </Show>
        <SectionContentWrapper zIndex="0" pos="static">
          <Flex justify={imagePosition === 'left' ? 'flex-end' : 'flex-start'}>
            <Flex
              w={{ base: '100%', md: '50%' }}
              flexDir="column"
              align={displayDigits ? { base: 'center', md: 'flex-start' } : 'center'}
            >
              <Heading
                as="h2"
                color="beige.50"
                variant={{
                  base: 'component-heading-large/xl',
                  sm: 'component-heading-large/2xl',
                  lg: 'component-heading-large/3xl',
                }}
                w={{ base: '300px', sm: '450px', xl: '650px' }}
                maxW="100%"
                mb="52px"
                pr={{ base: 0, md: imagePosition === 'left' ? 0 : '30px' }}
                pl={{ base: 0, md: imagePosition === 'right' ? 0 : '30px' }}
                textAlign={displayDigits ? { base: 'center', md: 'left' } : 'center'}
              >
                {cta}
              </Heading>
              {gridSection}
              <Flex justifyContent="center" w="100%">
                {buttonSection}
              </Flex>
            </Flex>
          </Flex>
          {ctaImageMobile?.data?.attributes?.url && (
            <Show below="md">
              <Box pos="relative" h="200px" mt="26px">
                <Image
                  fill
                  style={{ objectFit: 'cover' }}
                  src={ctaImageMobile.data.attributes.url}
                  alt={getImageAlt(ctaImageMobile?.data?.attributes)}
                />
              </Box>
            </Show>
          )}
        </SectionContentWrapper>
      </SectionWrapper>
    );
  }

  return (
    <SectionWrapper backgroundColor="violet.800" id="features-section">
      <SectionContentWrapper zIndex="0" pos="static">
        <Flex flexDir={{ base: 'column', md: 'row' }}>
          <Flex
            w={{ base: 'auto', md: '40%' }}
            flexDir="column"
            align="center"
            p={{ base: 0, md: '0 30px' }}
            justifyContent="flex-start"
          >
            <Heading
              as="h2"
              color="white"
              variant={{
                base: 'component-heading-large/xl',
                sm: 'component-heading-large/2xl',
                lg: 'component-heading-large/3xl',
              }}
              w={{ base: '300px', sm: '450px', xl: '650px' }}
              maxW="100%"
              mb="52px"
              textAlign="start"
            >
              {cta}
            </Heading>
            <Show above="md">
              <Box alignSelf="flex-start">{imageSection}</Box>
              <Flex justifyContent="center" w="100%">
                {buttonSection}
              </Flex>
            </Show>
          </Flex>
          {gridSection}
        </Flex>
        <Hide above="md">
          <Box transform="translateY(80px)" pos="relative" zIndex="10">
            {imageSection}
          </Box>
          <Flex justifyContent="center" paddingTop="80px">
            {buttonSection}
          </Flex>
        </Hide>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};
