import { SystemStyleObject } from '@chakra-ui/react';

export const membersGridContainerStyles: SystemStyleObject = {
  '.member:nth-of-type(odd)': {
    base: {
      gridColumn: '1/2',
      justifySelf: 'start',
    },
    xs: {
      mr: 14,
      justifySelf: 'end',
    },
  },
  '.member:nth-of-type(even)': {
    base: {
      gridColumn: '2/-1',
      justifySelf: 'end',
    },
    xs: {
      ml: 14,
      justifySelf: 'start!important',
    },
  },

  '.member:nth-of-type(1)': {
    base: {
      gridRow: '1/2',
    },
  },
  '.member:nth-of-type(2)': {
    base: {
      gridRow: '2/3',
    },
  },
  '.member:nth-of-type(3)': {
    base: {
      gridRow: '3/4',
    },
  },
  '.member:nth-of-type(4)': {
    base: {
      gridRow: '4/5',
    },
  },
  '.member:nth-of-type(5)': {
    base: {
      gridRow: '5/-1',
    },
  },
};
