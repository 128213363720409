import React, { FC, Fragment } from 'react';

import { Box, Flex, Grid, GridItem, Text, useBreakpointValue } from '@chakra-ui/react';
import { endsWith } from 'lodash';
import Image from 'next/image';
import Photo1 from 'services/product-design/gallery/1.png';
import Photo2 from 'services/product-design/gallery/2.png';
import Photo3 from 'services/product-design/gallery/3.png';
import Photo4 from 'services/product-design/gallery/4.png';
import Photo5 from 'services/product-design/gallery/5.png';
import Photo6 from 'services/product-design/gallery/6.png';
import Photo7 from 'services/product-design/gallery/7.png';
import Photo8 from 'services/product-design/gallery/8.png';
import Photo9 from 'services/product-design/gallery/9.png';
import Photo10 from 'services/product-design/gallery/10.png';
import Tada from 'services/product-design/tada.png';

import { AnimatedNumber } from '@/components/functional/AnimatedNumber';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { DynamicGallerySectionFragment } from '@/graphql/generated';

const DEFAULT_BASIC_UNIT = 8;

// For now, this is lifted almost straight from the gallery section in services/design. Might be tweaked in the future

export const DynamicGallerySection: FC<DynamicGallerySectionFragment> = ({ topText, middleText, bottomText }) => {
  const endsWithPlus = !!middleText && endsWith(middleText, '+');
  const parsedMiddleText = middleText && endsWithPlus ? middleText.substring(0, middleText.length - 1) : middleText;
  const shouldAnimate = !isNaN(Number(parsedMiddleText));
  const textInside = (
    <Text
      variant={{
        base: 'text-md/lineHeight-6/font-normal',
        sm: 'text-xl/lineHeight-7/font-normal',
        md: 'text-4xl/lineHeight-10/font-normal',
      }}
      color="pruple.500"
      as="div"
      minW="330px"
      w="330px"
      textAlign="center"
    >
      <Flex flexDir="column" alignItems="center" position="relative">
        <Box as={Image} src={Tada} alt="" position="absolute" left="-50%" top="10%" height="auto" width="55%" />
        <Box
          as={Image}
          src={Tada}
          alt=""
          position="absolute"
          right="-50%"
          top="10%"
          height="auto"
          width="55%"
          transform="scaleX(-1)"
        />
        <Box>{topText}</Box>
        <Text
          fontSize={{
            base: 32,
            sm: 48,
            md: 80,
          }}
          lineHeight="100%"
          color="violet.500"
          fontWeight={600}
          py={{
            base: 0,
            sm: 4,
            md: 8,
          }}
          as="span"
        >
          {shouldAnimate ? (
            <AnimatedNumber durationS={3} finalValue={Number(parsedMiddleText)} startingValue={0} />
          ) : (
            <>{parsedMiddleText}</>
          )}
          {endsWithPlus && (
            <Text display="inline" color="lime.500" as="span">
              +
            </Text>
          )}
        </Text>
        <Box>{bottomText}</Box>
      </Flex>
    </Text>
  );
  const photos = [Photo1, Photo2, Photo3, Photo4, Photo5, Photo6, Photo7, Photo8, Photo9, Photo10];

  // TODO: replace
  const BASIC_UNIT =
    useBreakpointValue({
      base: 2,
      xs: 3,
      sm: 4,
      md: 6,
      lg: 8,
    }) || DEFAULT_BASIC_UNIT;
  const BASIC_UNIT_PX = BASIC_UNIT * 4;

  return (
    <SectionWrapper>
      <SectionContentWrapper paddingX={0} display="flex" justifyContent="center">
        <Grid
          minWidth="440px"
          gridTemplateColumns="repeat(4, 1fr)"
          gridTemplateAreas={`
           "a1 a2 a3 a4"
           "a5 special special a8"
           "a9 a10 a11 a12"
          `}
          gap={BASIC_UNIT}
          sx={{
            '& .gallery-photo-2': {
              paddingBottom: BASIC_UNIT * 2,
              paddingRight: BASIC_UNIT * 2,
            },
            '& .gallery-photo-3': {
              marginLeft: -BASIC_UNIT * 2,
              paddingRight: BASIC_UNIT * 2,
              paddingBottom: BASIC_UNIT,
              maxWidth: `calc(100% + ${BASIC_UNIT_PX * 2}px)`,
            },
            '& .gallery-photo-4': {
              marginLeft: -BASIC_UNIT * 2,
              maxWidth: `calc(100% + ${BASIC_UNIT_PX * 2}px)`,
              marginBottom: -BASIC_UNIT * 2,
            },
            '& .gallery-photo-5': {
              paddingRight: BASIC_UNIT,
            },
            '& .gallery-photo-6': {
              paddingLeft: BASIC_UNIT,
              paddingTop: BASIC_UNIT * 2,
              paddingBottom: BASIC_UNIT * 2,
              maxHeight: '100%',
              maxWidth: 'auto',
            },
            '& .gallery-photo-7': {
              paddingRight: BASIC_UNIT,
              paddingLeft: BASIC_UNIT,
            },
            '& .gallery-photo-8': {
              marginLeft: -BASIC_UNIT,
              marginRight: -BASIC_UNIT,
              maxWidth: `calc(100% + ${BASIC_UNIT_PX}px)`,
              marginTop: -BASIC_UNIT * 1.5,
            },
            '& .gallery-photo-9': {
              marginTop: -BASIC_UNIT,
            },
            '& .gallery-photo-10': {
              paddingRight: BASIC_UNIT,
              marginTop: -BASIC_UNIT * 2,
            },
            '& [class^="gallery-photo-"]': {
              '3xl': {
                borderRadius: '16px',
              },
            },
          }}
        >
          {photos.map((photo, index) => (
            <Fragment key={`grid-item-${index}`}>
              <GridItem
                gridArea={index < 5 ? `a${index + 1}` : `a${index + 3}`}
                alignSelf={index < 4 ? 'end' : index === 5 ? 'center' : 'start'}
                position="relative"
                height={index === 7 ? '100%' : 'initial'}
              >
                <Box as={Image} src={photo} alt="" className={`gallery-photo-${index + 1}`} />
              </GridItem>
            </Fragment>
          ))}
          <GridItem gridRow={2} gridColumn="2 / span 2" display="flex" justifyContent="center" alignItems="center">
            <Flex flexDir="column" marginTop={-BASIC_UNIT * 3}>
              {textInside}
            </Flex>
          </GridItem>
        </Grid>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};
