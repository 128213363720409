import { useState } from 'react';

import { Box, Grid, useMediaQuery, useTheme } from '@chakra-ui/react';

import { ConditionalWrapper } from '@/components/wrappers';
import { LetsMeetTeamMemberFragment } from '@/graphql/generated';
import { RevealedItemStateType } from '@/utils/models/lets-meet-section-member-props';

import { MemberCircle } from '../MemberCircle';
import { MemberGrid } from '../MemberGrid/MemberGrid';
import { membersGridContainerStyles } from './members-styles';

interface MembersProps {
  membersData: Record<string, LetsMeetTeamMemberFragment>;
}

export const Members = ({ membersData }: MembersProps) => {
  const [revealedItem, setRevealedItem] = useState<RevealedItemStateType>(1);

  const theme = useTheme();
  const [isLargerThanSm] = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`);

  const membersAsArray = Object.values(membersData);
  const numberOfMembers = membersAsArray.length;
  const tangent = Math.tan(Math.PI / numberOfMembers);

  return (
    <ConditionalWrapper
      condition={!isLargerThanSm}
      wrapperIfConditionTrue={(children) => (
        <Grid
          mt={{ base: 56 }}
          gridTemplateColumns={{ base: 'repeat(2,1fr)' }}
          gridTemplateRows={{ base: 'repeat(5, 1fr)' }}
          sx={membersGridContainerStyles}
        >
          {children}
        </Grid>
      )}
      wrapperIfConditionFalse={(children) => (
        <Box
          mt={{ sm: 44, lg: 0 }}
          w={{ sm: '100%', lg: 'calc(100% - 410px - 7rem)' }}
          h="var(--containerSize)"
          position="relative"
          sx={{
            '--imageSize': '130px',
            '--spaceBetween': '2', // 1 is equal to imageSize
            '--images': `${numberOfMembers}`, // number of images to place
            '--tangent': `${+tangent.toFixed(2)}`,
            '--radius': `calc(0.5 * (1 + var(--spaceBetween)) * var(--imageSize) / var(--tangent))`, // circle radius
            '--containerSize': `calc(2*var(--radius) + var(--imageSize))`,
          }}
        >
          {children}
        </Box>
      )}
    >
      {membersAsArray.map((member, index) => {
        const memberProps = {
          ...member,
          index,
          setRevealedItem,
          revealedItem,
        };

        return !isLargerThanSm ? (
          <MemberGrid key={`member-grid-${index}`} {...memberProps} />
        ) : (
          <MemberCircle key={`member-circle-${index}`} {...memberProps} />
        );
      })}
    </ConditionalWrapper>
  );
};
