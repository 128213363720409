import React, { FC } from 'react';

import { ArticleContent } from '@/components/sections/blog';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { DynamicRichTextFragment } from '@/graphql/generated';

export const DynamicRichTextBlock: FC<DynamicRichTextFragment> = ({ text }) => {
  return (
    <SectionWrapper>
      <SectionContentWrapper>
        <ArticleContent maxW="container.lg" videoWidth={'80%'} content={text.richText} />
      </SectionContentWrapper>
    </SectionWrapper>
  );
};
