import { Children, cloneElement } from 'react';

import { Box, Flex, Heading } from '@chakra-ui/react';
import parse, { Element, HTMLReactParserOptions } from 'html-react-parser';
import Head from 'next/head';
import Script from 'next/script';

import { ScrollToTop } from '@/components/atoms';
import { parseOptions } from '@/components/functional';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { typographyVariants } from '@/theme/components/typography-variants';
import { WindowWithInterfaces } from '@/typings/window';
import { HIGHLIGHT_JS_STYLE_URL, HIGHLIGHT_JS_URL } from '@/utils/constants';
import { getInnerHtml } from '@/utils/helper-functions/getInnerHtml';
import { DOMElements } from '@/utils/models/parse-html-to-react';

import { getChapterId } from '../ArticleTableOfContent/use-content-chapters';

interface ArticleContentProps {
  content: string;
  maxW?: string;
  videoWidth?: string;
}

const { H1, H2, H3, H4, H5, H6 } = DOMElements;

const articleParseOptions: HTMLReactParserOptions = {
  ...parseOptions,
  replace(domNode) {
    const element = parseOptions.replace?.(domNode);
    if (!element) {
      return element;
    }

    if (domNode instanceof Element && ([H1, H2, H3, H4, H5, H6] as string[]).includes(domNode.name)) {
      const innerHtml = domNode.children.map(getInnerHtml).join('');

      return cloneElement(element as JSX.Element, {
        id: getChapterId(innerHtml),
        className: 'article-header',
      });
    }

    return element;
  },
};

export const ArticleContent = ({ content, maxW = '628px', videoWidth = '100%' }: ArticleContentProps) => {
  const parsedContent = Children.toArray(parse(content, articleParseOptions));
  let sectionElements = [] as typeof parsedContent;

  const sections = parsedContent.reduce<typeof parsedContent>((acc, element, index) => {
    const isLastElement = index === parsedContent.length - 1;

    const closeSection = () => {
      if (sectionElements.length === 0) {
        return;
      }
      const [firstElement] = sectionElements;

      const key = typeof firstElement === 'object' && 'key' in firstElement ? firstElement.key : undefined;

      acc.push(
        <SectionWrapper key={key}>
          <SectionContentWrapper maxW={{ md: maxW }} paddingX={{ base: 5, sm: 6, md: 0 }} paddingY={undefined}>
            {sectionElements}
          </SectionContentWrapper>
        </SectionWrapper>,
      );
      sectionElements = [];
    };
    if (typeof element === 'object' && 'type' in element) {
      if (element.type === Heading) {
        closeSection();
      } else if (element.type === 'figure' && element.props.className === 'media' && element.props.children.props.url) {
        closeSection();
        const url = element.props.children.props.url;
        acc.push(
          <SectionWrapper key={url}>
            <SectionContentWrapper maxW={{ md: maxW }} paddingX={{ base: 5, sm: 6, md: 0 }} paddingY={undefined}>
              <Flex justifyContent="center" alignItems="center" pb={10}>
                <iframe
                  width={videoWidth}
                  height="350"
                  src={url}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                />
              </Flex>
            </SectionContentWrapper>
          </SectionWrapper>,
        );
        return acc;
      }
    }

    sectionElements.push(element);

    if (isLastElement) {
      closeSection();
    }

    return acc;
  }, []);
  return (
    <>
      <Head>
        <link rel="stylesheet" href={HIGHLIGHT_JS_STYLE_URL} />
      </Head>
      <Script src={HIGHLIGHT_JS_URL} onLoad={() => (window as WindowWithInterfaces).hljs.highlightAll()} />
      <Box
        sx={{
          '.article-header': {
            base: {
              pt: { base: 8, lg: 12 },
              color: 'purple.500',
              pb: {
                base: 6,
                sm: 8,
              },
              ...typographyVariants['component-article-headline/xl'],
            },
            sm: {
              ...typographyVariants['component-article-headline/3xl'],
            },
          },
          strong: {
            fontWeight: 'semibold',
          },
          ul: {
            paddingBottom: 5,
          },
          li: {
            ml: 6,
          },
          p: {
            ...typographyVariants['text-md/lineHeight-6/font-normal'],
            paddingBottom: 5,
          },
          pre: {
            paddingBottom: 5,
          },
          'img:not(.image-highlight)': {
            pb: 5,
            display: 'flex',
            justifyContent: 'center',
          },
          'p > img': {
            pb: 0,
          },
          figcaption: {
            paddingBottom: 5,
          },
          blockquote: {
            paddingTop: 3,
          },
          a: {
            textDecoration: 'underline',
          },
        }}
        color="purple.400"
      >
        {sections}
      </Box>
      <ScrollToTop />
    </>
  );
};
