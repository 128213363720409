import React, { FC } from 'react';

import { DynamicSliderWithPointsFragment } from '@/graphql/generated';

import { SliderExtendedSection } from '../../services/sections';
import { SliderSectionCardItem } from '../../services/typings';
import { DynamicTextWithDecoration } from '../DynamicTextWithDecoration';

export const DynamicSliderWithPoints: FC<DynamicSliderWithPointsFragment> = ({ header, subheader, items }) => {
  const cards: SliderSectionCardItem[] = items.map((item) => ({
    header: item?.header || '',
    paragraphs: [
      {
        header: item?.paragraphOneTitle || '',
        text: item?.paragraphOneDescription || '',
      },
      {
        header: item?.paragraphTwoTitle || '',
        text: item?.paragraphTwoDescription || '',
      },
      {
        header: item?.paragraphThreeTitle || '',
        text: item?.paragraphThreeDescription || '',
      },
    ],
    buttonLabel: item?.buttonLabel || '',
    buttonLink: item?.buttonLink || '',
    image: item?.image?.data?.attributes?.url || null,
  }));
  return (
    <SliderExtendedSection
      stylesWrapper={{
        pt: {
          base: 12,
          sm: 24,
        },
        pb: { base: 16, sm: 20 },
      }}
      header={<DynamicTextWithDecoration>{header}</DynamicTextWithDecoration>}
      subheader={<DynamicTextWithDecoration>{subheader}</DynamicTextWithDecoration>}
      items={cards}
    />
  );
};
