import { Box, chakra } from '@chakra-ui/react';
import Image from 'next/image';

import { CommentBubble } from '@/components/atoms/CommentBubble/CommentBubble';
import { ChatBubbleFragment, ImageFragment } from '@/graphql/generated';

interface RevealedMemberProps {
  avatar: ImageFragment;
  chatBubble: ChatBubbleFragment;
  isRevealed: boolean;
}

const POP_ANIMATION_DURATION = '.3s';

const MemberImage = chakra(Image, {
  shouldForwardProp: (prop) => ['width', 'height', 'src', 'alt', 'loading'].includes(prop),
});

export const RevealedMember = ({ avatar, chatBubble, isRevealed }: RevealedMemberProps) => {
  const { memberMessage, memberName, memberRole } = chatBubble;

  return (
    <Box
      className="revealedMember"
      position="absolute"
      zIndex={10}
      aria-hidden={isRevealed}
      transform={`scale(${isRevealed ? 1 : 0})`}
      transition={`transform ${POP_ANIMATION_DURATION} ease`}
    >
      <Box
        className="revealedMemberAvatar"
        w={{ base: '120px', sm: '190px' }}
        h={{ base: '120px', sm: '190px' }}
        position="relative"
        bottom="20px"
        borderRadius="full"
        overflow="hidden"
      >
        <MemberImage
          src={avatar.data?.attributes?.url ?? ''}
          alt={avatar.data?.attributes?.alternativeText ?? ''}
          width={190}
          height={190}
          w={{ base: 120, sm: 190 }}
          h={{ base: 120, sm: 190 }}
          loading="eager"
        />
      </Box>
      <CommentBubble
        isRevealed={isRevealed}
        message={memberMessage}
        personRole={memberRole}
        name={memberName}
        animationDuration={POP_ANIMATION_DURATION}
        animationDelay={POP_ANIMATION_DURATION}
        minWidth="250px"
      />
    </Box>
  );
};
