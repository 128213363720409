import React, { FC, useMemo } from 'react';

import { DynamicLogosFragment } from '@/graphql/generated';
import { getImageSrcFromAttributes } from '@/utils/images';

import { LogosSection } from '../../services/sections';
import { DynamicTextWithDecoration } from '../DynamicTextWithDecoration';

export const DynamicLogosBlock: FC<DynamicLogosFragment> = ({ header, logos }) => {
  const items = useMemo(() => logos.data.map((el) => getImageSrcFromAttributes(el?.attributes, 'medium')), [logos]);

  return (
    <LogosSection header={<DynamicTextWithDecoration key="header">{header}</DynamicTextWithDecoration>} items={items} />
  );
};
