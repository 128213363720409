import React from 'react';

import { Box } from '@chakra-ui/react';
import BackgroundDoodle from 'images/backgrounds/bg-doodle-simple.png';
import Image from 'next/image';

export const LLShapedBackground = () => {
  return (
    <Box
      as={Image}
      src={BackgroundDoodle}
      alt=""
      position="absolute"
      zIndex={-1}
      h="100%"
      maxW="unset"
      w="auto"
      bottom={0}
      right={5}
      opacity={0.3}
    />
  );
};
