import { SystemStyleObject } from '@chakra-ui/react';

export const reviewsStyles: SystemStyleObject = {
  '.review-card:nth-of-type(1)': {
    sm: {
      order: 2,
      alignSelf: 'start',
    },
  },
  '.review-card:nth-of-type(2)': {
    sm: {
      order: 1,
      alignSelf: 'end',
    },
  },
  '.review-card:nth-of-type(3)': {
    sm: {
      order: 3,
      gridColumn: '1/-1',
      alignSelf: 'end',
    },
  },
};
