import React, { FC, FormEvent, useRef, useState } from 'react';

import { Box, Checkbox, Flex, FormControl, FormLabel, Input, Text, VisuallyHidden, useToast } from '@chakra-ui/react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { zodResolver } from '@hookform/resolvers/zod';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { Button } from '@/components/atoms/Button';
import { FormErrorMessage } from '@/components/atoms/FormErrorMessage/FormErrorMessage';
import { ButtonVariant, StandaloneContactFormFragment } from '@/graphql/generated';
import { useSendEmail } from '@/hooks/use-send-email';
import { LOCALES } from '@/utils/constants';
import { appendFormMessage } from '@/utils/helper-functions/user-form';
import { getParsedUTMParams } from '@/utils/marketing';
import { GeneralPath } from '@/utils/models';
import { simpleContactFormSchema } from '@/utils/validation-schemas';

import { DynamicTextWithDecoration } from '../DynamicTextWithDecoration';

type SimpleContactFormData = z.infer<typeof simpleContactFormSchema>;

type Props = Omit<StandaloneContactFormFragment, '__typename' | 'id'>;

export const DynamicContactFormCompact: FC<Props> = ({
  contactCardImage,

  contactCardLabel,
  contactCardTitle,
  contactCardAdditionalLabelText,

  formCompanyLabel,
  formEmailLabel,
  formNameLabel,
  formPhoneLabel,
  formPrivacyPolicyLabel,
  formSubmitLabel,
  formPrivacyPolicyLink,
  thanksInPolish,
}) => {
  const captchaRef = useRef<HCaptcha>(null);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const toast = useToast({ duration: 5000 });
  const { t } = useTranslation([LOCALES.CONTACT]);
  const { mutate, isLoading: submitContactFormLoading } = useSendEmail();
  const router = useRouter();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SimpleContactFormData>({
    resolver: zodResolver(simpleContactFormSchema),
    defaultValues: {
      captchaToken: '',
    },
  });

  const onSubmit = async (e: FormEvent<HTMLDivElement>) => {
    e.preventDefault();
    let token = captchaToken;
    if (!token) {
      const captchaResult = await captchaRef.current?.execute({ async: true });
      if (captchaResult?.response) {
        token = captchaResult?.response;
      }
    }
    handleSubmit(async (values) => {
      const formData = new FormData();
      const utmParams = getParsedUTMParams();
      const formValues = structuredClone(values);

      if (utmParams) {
        formValues.message = appendFormMessage(formValues.message, utmParams);
      }

      for (const key in formValues) {
        formData.append(key, (formValues[key as keyof typeof formValues] ?? '').toString());
      }

      formData.append('captchaToken', token ?? '');

      mutate(formData, {
        onSuccess: () => {
          router.push({ pathname: GeneralPath.THANKS, ...(thanksInPolish ? { query: { version: 'pl' } } : {}) });
        },
        onError: () => {
          resetCaptcha();
          toast({
            status: 'error',
            title: `${t('formError')}`,
          });
        },
      });
    })();
  };

  const resetCaptcha = (): void => {
    setCaptchaToken(null);
    captchaRef.current?.resetCaptcha();
  };

  const onTokenVerify = async (token: string) => setCaptchaToken(token);

  return (
    <Flex
      bg="white"
      borderRadius="32px"
      w={{ base: '100%', md: '420px' }}
      p="30px 24px"
      flexDir="column"
      align="center"
      sx={{ '& span.decorated-text': { zIndex: 0 } }}
    >
      <Box w="100%">
        <Text variant="component-paragraph-headline/2xl" mb="40px">
          <DynamicTextWithDecoration>{contactCardTitle}</DynamicTextWithDecoration>
        </Text>
      </Box>
      {contactCardImage?.data?.attributes?.url && (
        <Image
          width={96}
          height={96}
          style={{ objectFit: 'cover', overflow: 'hidden', borderRadius: '50%', width: '96px', height: '96px' }}
          alt={contactCardImage?.data?.attributes?.caption ?? 'Contact person'}
          src={contactCardImage?.data?.attributes?.url}
        />
      )}
      <Text mt="12px" variant="component-paragraph-headline/md" maxW="100%">
        {contactCardLabel}
      </Text>
      <Text variant="component-text/xs" mb="7px" maxW="100%">
        {contactCardAdditionalLabelText}
      </Text>

      <Box as="form" onSubmit={onSubmit} noValidate w="100%">
        <FormControl>
          <Input
            my="7px"
            type="text"
            disabled={submitContactFormLoading}
            aria-invalid={!!errors.name}
            width="100%"
            isInvalid={!!errors.name}
            errorBorderColor="red.300"
            placeholder={formNameLabel}
            {...register('name')}
          />
          <FormErrorMessage errorMessage={errors.name} bottom="-10px" />
          <VisuallyHidden>
            <FormLabel>{formNameLabel}</FormLabel>
          </VisuallyHidden>
        </FormControl>
        <FormControl>
          <Input
            my="7px"
            type="email"
            disabled={submitContactFormLoading}
            aria-invalid={!!errors.email}
            isInvalid={!!errors.email}
            flex={1}
            errorBorderColor="red.300"
            placeholder={formEmailLabel}
            {...register('email')}
          />
          <FormErrorMessage errorMessage={errors.email} bottom="-10px" />
          <VisuallyHidden>
            <FormLabel>{formEmailLabel}</FormLabel>
          </VisuallyHidden>
        </FormControl>
        <FormControl>
          <Input
            my="7px"
            type="tel"
            disabled={submitContactFormLoading}
            aria-invalid={!!errors.phoneNumber}
            isInvalid={!!errors.phoneNumber}
            errorBorderColor="red.300"
            flex={1}
            placeholder={formPhoneLabel}
            {...register('phoneNumber')}
          />
          <FormErrorMessage errorMessage={errors.phoneNumber} bottom="-10px" />
          <VisuallyHidden>
            <FormLabel>{formPhoneLabel}</FormLabel>
          </VisuallyHidden>
        </FormControl>
        <FormControl>
          <Input
            my="7px"
            type="text"
            disabled={submitContactFormLoading}
            aria-invalid={!!errors.message}
            width="100%"
            isInvalid={!!errors.message}
            errorBorderColor="red.300"
            placeholder={formCompanyLabel}
            {...register('message')}
          />
          <FormErrorMessage errorMessage={errors.message} bottom="-10px" />
          <VisuallyHidden>
            <FormLabel>{formCompanyLabel}</FormLabel>
          </VisuallyHidden>
        </FormControl>
        <FormControl>
          <Checkbox
            my="7px"
            {...register('privacyPolicy')}
            disabled={submitContactFormLoading}
            variant="lime"
            size="lg"
            colorScheme="lime"
            isInvalid={!!errors.privacyPolicy}
          >
            <Box as={Link} target="_blank" href={formPrivacyPolicyLink ?? ''} fontWeight="700" textDecor="underline">
              {formPrivacyPolicyLabel}
            </Box>
          </Checkbox>
          <FormErrorMessage errorMessage={errors.privacyPolicy} bottom="-10px" />
        </FormControl>
        <Button
          my="10px"
          type="submit"
          isLoading={submitContactFormLoading}
          variant={ButtonVariant.Light}
          fontSize="lg"
          size="lg"
          w="100%"
        >
          {formSubmitLabel}
        </Button>
      </Box>

      <HCaptcha
        onVerify={onTokenVerify}
        ref={captchaRef}
        size="invisible"
        sitekey={process.env.NEXT_PUBLIC_HCAPTCHA_SITEKEY}
      />
    </Flex>
  );
};
