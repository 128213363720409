import React from 'react';

import { Box, Flex, Icon, Show } from '@chakra-ui/react';
import Arrow from 'images/doodles/arrow.svg';
import ExclamationBottom from 'images/doodles/exclamation-doodle-lines-bottom.svg';
import Exclamation from 'images/doodles/exclamation-doodle-lines.svg';
import Star from 'images/doodles/star3.png';
import ArrowRight from 'images/icons/client-opinions/arrow-right.svg';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';

import { Button } from '@/components/atoms';
import { LOCALES } from '@/utils/constants';
import { GeneralPath } from '@/utils/models';

export const CaseStudyActionButton = () => {
  const { t } = useTranslation(LOCALES.HOME);

  return (
    <Flex
      flexDir="column"
      alignItems="center"
      justifyContent={{
        base: 'end',
        md: 'initial',
      }}
      zIndex={101}
      position="absolute"
      bottom={0}
      h="180px"
      maxW={{
        base: 'unset',
        '3xl': '1280px',
      }}
      w={{
        base: 'calc(100% - 40px)',
        sm: 'calc(100% - 48px)',
        lg: 'calc(100% - 160px)',
      }}
    >
      <Box position="relative">
        <Button
          label={t('caseStudies.button') || ''}
          rightIcon={<Icon as={ArrowRight} sx={{ path: { fill: 'white' } }} />}
          variant="light"
          mt={8}
          maxW="360px"
          size="lg"
          borderRadius="44px"
          url={GeneralPath.CASE_STUDIES}
          boxShadow="0px 16px 68px 6px rgba(95, 45, 211, 0.39)"
        />
        <Box
          position="absolute"
          top="0"
          left="-16%"
          h="60px"
          w="60px"
          sx={{
            '& svg': {
              width: '100%',
              height: '100%',
            },
          }}
          transform="rotate(-26deg)"
        >
          <Exclamation />
        </Box>
        <Box
          position="absolute"
          bottom="-30%"
          right="-10%"
          h="40px"
          w="40px"
          sx={{
            '& svg': {
              width: '100%',
              height: '100%',
            },
          }}
          transform="rotate(280deg)"
        >
          <ExclamationBottom />
        </Box>
        <Show above="xs">
          <Box
            position="absolute"
            bottom="-50%"
            left="-24%"
            h="35px"
            w="75px"
            sx={{
              '& svg': {
                width: '100%',
                height: '100%',
              },
            }}
          >
            <Arrow />
          </Box>
          <Box
            as={Image}
            src={Star}
            alt=""
            position="absolute"
            top="-8%"
            left="-50%"
            h="34px"
            w="auto"
            transform="rotate(-100deg)"
          />
          <Box as={Image} alt="" src={Star} position="absolute" bottom="0" right="-40%" h="18px" w="auto" />
        </Show>
      </Box>
    </Flex>
  );
};
