import { useRef } from 'react';

import { Box, Grid, GridItem, Heading, Text } from '@chakra-ui/react';
import { useInView } from 'framer-motion';
import HighlightImage from 'images/highlights/client-opinions-highlight.png';
import UnderlineImage from 'images/highlights/line-3-cross-2.svg';
import { useTranslation } from 'next-i18next';

import { TextDecorationPng, TextDecorationSvg } from '@/components/functional';
import { ClutchBadge } from '@/components/molecules/ClutchBadge/ClutchBadge';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { ClutchScoreFragment } from '@/graphql/generated';
import { LOCALES } from '@/utils/constants';

import { ClutchScoreBackground } from './ClutchScoreBackground';
import { Reviews } from './Reviews';

interface ClutchScoreProps {
  data: ClutchScoreFragment;
}

export const ClutchScore = ({ data }: ClutchScoreProps) => {
  const { firstReview, secondReview, thirdReview } = data;
  const { t } = useTranslation(LOCALES.HOME);
  const containerRef = useRef(null);
  const isInView = useInView(containerRef);

  return (
    <SectionWrapper>
      <SectionContentWrapper paddingY={{ base: 16, sm: 20, lg: 48 }} ref={containerRef}>
        <ClutchScoreBackground isInView={isInView} />
        <Grid
          templateColumns={{
            base: '1fr',
            lg: '411px 1fr',
          }}
          rowGap={{ sm: 5 }}
        >
          <GridItem display={{ md: 'flex' }} flexDirection={{ md: 'column' }} justifyContent={{ md: 'space-between' }}>
            <Box color="purple.500">
              <Heading
                variant={{
                  base: 'component-heading-large/xl',
                  md: 'component-heading-large/3xl',
                }}
              >
                {t('clutch.title')}
              </Heading>
              <Text
                variant={{
                  base: 'text-md/lineHeight-6/font-normal',
                  md: 'text-2xl/lineHeight-8/font-normal',
                }}
                pt={6}
                as="h2"
              >
                {t('clutch.description.0')}{' '}
                <TextDecorationPng image={HighlightImage} speed=".8s" delay=".3s">
                  {t('clutch.description.1')}
                </TextDecorationPng>
                {t('clutch.description.2')}{' '}
                <TextDecorationSvg line={UnderlineImage} speed="0.7s" top="60%" delay="1.3s">
                  {t('clutch.description.3')}
                </TextDecorationSvg>
              </Text>
            </Box>
            <Box mt={{ base: 14, lg: 56 }}>
              <ClutchBadge />
            </Box>
          </GridItem>
          <Reviews reviews={[firstReview, secondReview, thirdReview]} />
        </Grid>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};
