import { Flex, Heading, Text } from '@chakra-ui/react';

type ChartPointProps = {
  title: string;
  points: Array<{ id: string; textListItem: string } | null>;
};

export const ChartPoint = ({ title, points }: ChartPointProps) => {
  return (
    <>
      <Heading
        as="h3"
        color="beige.100"
        fontFamily={'body'}
        maxWidth="240px"
        fontWeight={700}
        fontSize="20px"
        mb={{ base: '16px', lg: '24px' }}
      >
        {title}
      </Heading>
      <Flex flexDir="column" mb={{ base: '46px', '2xl': 0 }}>
        {points.map((point) => (
          <Text
            key={point?.id}
            variant={{ base: 'component-paragraph-headline/xs', sm: 'component-paragraph-headline/md' }}
            color="beige.100"
          >
            {point?.textListItem}
          </Text>
        ))}
      </Flex>
    </>
  );
};
