import { ElementRef, useEffect, useRef, useState } from 'react';

import { Box, Heading, useMediaQuery, useQuery } from '@chakra-ui/react';
import Underline from 'images/highlights/zigzag-5.svg';
import { useTranslation } from 'next-i18next';

import { Slider, TextDecorationSvg } from '@/components/functional';
import { SliderControls } from '@/components/functional/SliderControls/SliderControls';
import { LeadershipBackground } from '@/components/sections/about-us/LeadershipSection/LeadershipBackground/LeadershipBackground';
import { AnimateWhenInViewWrapper, SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { LeadershipFragment } from '@/graphql/generated';
import { CONTENT_MAX_WIDTH, LOCALES, TILE_WIDTH } from '@/utils/constants';

import { DynamicTextWithDecoration } from '../../dynamic-blocks/DynamicTextWithDecoration';
import { Leader } from './Leader';

type LeadershipProps = {
  leaders: LeadershipFragment[];
  header?: string;
};

export const LeadershipSection = ({ leaders, header }: LeadershipProps) => {
  const sliderRef = useRef<ElementRef<typeof Slider>>(null);
  const [isAboveXS] = useMediaQuery(useQuery({ above: 'xs' }));
  const { t } = useTranslation(LOCALES.ABOUT_US);
  const [showSliderControls, setShowSliderControls] = useState(false);

  useEffect(() => {
    const tilesWidth = leaders.length * TILE_WIDTH;
    const widerThanContent = tilesWidth > CONTENT_MAX_WIDTH;
    const widerThanWindow = tilesWidth > window.innerWidth;
    setShowSliderControls(widerThanContent || widerThanWindow);
  }, [leaders.length]);

  return (
    <SectionWrapper backgroundColor="purple.500" zIndex={0} id="meetOurLeadership">
      <SectionContentWrapper>
        <LeadershipBackground />
        {/* TODO: remove heading after move migrate leadership from constant to dynamic block */}
        {header ? (
          <DynamicTextWithDecoration>{header}</DynamicTextWithDecoration>
        ) : (
          <Heading
            variant={{
              base: 'component-heading-large/xl',
              sm: 'component-heading-large/2xl',
              lg: 'component-heading-large/3xl',
            }}
            color="white"
            textAlign={{
              base: 'left',
              sm: 'center',
            }}
            mb={14}
          >
            {t('leadership.text')}{' '}
            <TextDecorationSvg color="lime" line={Underline} transform="translateY(23px)" scaleX={0.8}>
              {t('leadership.underlined')}
            </TextDecorationSvg>
          </Heading>
        )}
        {showSliderControls ? (
          <SliderControls
            onSlidePrev={() => sliderRef.current?.onPrev()}
            onSlideNext={() => sliderRef.current?.onNext()}
            alignSelf="end"
            variant="light"
          />
        ) : null}

        <AnimateWhenInViewWrapper>
          <Box mx={{ base: -5, xs: 0 }}>
            <Slider ref={sliderRef} autoScrollIntervalMs={3400} snapDirection={isAboveXS ? 'start' : 'center'}>
              {leaders?.map((leader, index) => leader && <Leader key={`leader-item-${index}`} {...leader} />)}
            </Slider>
          </Box>
        </AnimateWhenInViewWrapper>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};
