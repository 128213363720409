import { SystemStyleObject } from '@chakra-ui/react';

export const memberCircleStyles: SystemStyleObject = {
  '& .revealedMember': {
    top: '-20px',
    right: '-30px',
  },

  '& .revealedMemberText::before': {
    sm: {
      left: '-5px',
      top: '50px',
    },
  },

  '&:nth-of-type(3) .revealedMemberText': {
    sm: {
      right: 'calc(100% + 23px)',
      left: 'initial',
    },
  },

  '&:nth-of-type(3) .revealedMemberText::before': {
    sm: {
      right: '-5px',
      left: 'initial',
    },
  },
};
