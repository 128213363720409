import { useState } from 'react';

import { Flex, useInterval } from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';

import { ShuffleCard } from '@/components/sections/home';
import { CompanyStatisticsCardFragment } from '@/graphql/generated';

const INTERVAL_TIME = 4000;
const VISIBLE_ITEMS_LENGTH = 4;

interface StatisticProps {
  cardsData: Maybe<CompanyStatisticsCardFragment>[];
}

export const Statistics = ({ cardsData }: StatisticProps) => {
  const [visibleItems, setVisibleItems] = useState(cardsData.slice(0, VISIBLE_ITEMS_LENGTH));
  const [itemToShow, setItemToShow] = useState<number>(VISIBLE_ITEMS_LENGTH);
  const [itemToUpdatedInArray, setItemToUpdatedInArray] = useState<number>(0);

  useInterval(() => {
    const newSetOfCards = [...visibleItems];
    newSetOfCards[itemToUpdatedInArray] = cardsData[itemToShow];
    setVisibleItems(newSetOfCards);

    const nextCardToShow = itemToShow + 1;
    setItemToShow(nextCardToShow === cardsData.length ? 0 : nextCardToShow);

    const nextItemToUpdateInArray = itemToUpdatedInArray + 1;
    setItemToUpdatedInArray(nextItemToUpdateInArray === VISIBLE_ITEMS_LENGTH ? 0 : nextItemToUpdateInArray);
  }, INTERVAL_TIME);

  return (
    <Flex
      flexWrap={'wrap'}
      flexDirection={{ base: 'column', sm: 'row' }}
      justifyContent={{ sm: 'center' }}
      alignItems={{ xs: 'center' }}
      gap={10}
      minH={{ base: '504px' }}
      width={{ base: '100%', sm: '90%' }}
      bgImage="url('/assets/images/backgrounds/statistics-cards-background.svg')"
      bgRepeat="no-repeat"
      bgPos="center"
      zIndex="0"
      position="relative"
    >
      {visibleItems.map((card, index) => {
        if (card) {
          return <ShuffleCard key={`statistic-card-${index}`} interval={INTERVAL_TIME} {...card} />;
        }
      })}
    </Flex>
  );
};
