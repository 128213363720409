import React, { FC } from 'react';

import FFIcon from 'services/no-code-development/icons/ff.png';

import { DynamicPointsWithPhotosFragment } from '@/graphql/generated';
import { getImageSrcFromAttributes } from '@/utils/images';

import { GifSection } from '../../services/sections';
import { DynamicTextWithDecoration } from '../DynamicTextWithDecoration';

export const DynamicPointsWithPhotosBlock: FC<DynamicPointsWithPhotosFragment> = ({ header, elements }) => {
  return (
    <GifSection
      header={<DynamicTextWithDecoration>{header}</DynamicTextWithDecoration>}
      items={elements.map((el, index) => ({
        uuid: el?.id ? +el?.id : index,
        icon: FFIcon,
        text: el?.text || '',
        coverImageFallback: {
          url: getImageSrcFromAttributes(el?.photo.data?.attributes, 'medium'),
        },
        coverVideo: { url: '' },
      }))}
    />
  );
};
