import { Flex, Grid, GridItem, useMediaQuery, useTheme } from '@chakra-ui/react';

import { AnimateWhenInViewWrapper, ConditionalWrapper } from '@/components/wrappers';
import { Maybe, TechStackRowFragment } from '@/graphql/generated';

import { Technology } from '../../../../atoms/Technology';
import { technologiesStyles } from './technologies-styles';

interface TechnologiesProps {
  technologiesRows: Maybe<TechStackRowFragment>[];
}

export const Technologies = ({ technologiesRows }: TechnologiesProps) => {
  const theme = useTheme();
  const [isLargerThanSm] = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`);
  const desktopRowsValue = technologiesRows.length;

  return (
    <Grid
      gap={{ base: 4 }}
      listStyleType="none"
      gridTemplateColumns={{
        base: '1fr',
        '2xs': 'repeat(2, 1fr)',
        sm: '1fr',
      }}
      gridTemplateRows={{
        base: 'auto',
        xs: `repeat(${desktopRowsValue}, 0.5fr)`,
      }}
      justifyItems={{ base: 'center', xs: 'initial' }}
      mt={{ base: 48, sm: 36, md: 0 }}
      mb={{ base: 72, md: 0 }}
      sx={technologiesStyles}
    >
      {technologiesRows.map((row, index) => {
        if (row) {
          return (
            <ConditionalWrapper
              key={`row-${index}`}
              condition={isLargerThanSm}
              wrapperIfConditionTrue={(children) => (
                <GridItem key={`row-element-${index}`} display="flex" w="100%" justifyContent="flex-end">
                  <Flex listStyleType="none" gap={8} justifyContent="flex-end">
                    {children}
                  </Flex>
                </GridItem>
              )}
            >
              {row.pill.map((technology, index) => {
                if (technology) {
                  const { caption, image } = technology;
                  const _image = {
                    url: image.data?.attributes?.url,
                    caption: image.data?.attributes?.caption,
                    formats: image.data?.attributes?.formats,
                  };
                  return (
                    <AnimateWhenInViewWrapper key={`${caption}-${index}`}>
                      <GridItem w="fit-content">
                        <Technology technologyName={caption} technologyImage={_image} />
                      </GridItem>
                    </AnimateWhenInViewWrapper>
                  );
                }
              })}
            </ConditionalWrapper>
          );
        }
      })}
    </Grid>
  );
};
