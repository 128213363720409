import React, { FC } from 'react';

import Star2 from 'images/doodles/star2.png';
import Star1 from 'images/doodles/star.png';
import GridItemIcon from 'services/development/grid-item-icon.png';
import Bomb from 'services/development/timeline/bomb.png';
import Cat from 'services/development/timeline/cat.png';
import Idea from 'services/development/timeline/idea.png';
import Pen from 'services/development/timeline/pen.png';
import Robots from 'services/development/timeline/robots.png';
import BullsEye from 'services/mvp/bulls-eye.png';
import Chart from 'services/mvp/chart.png';
import Click from 'services/mvp/click.png';
import LightBulb from 'services/mvp/light-bulb.png';
import TwoWay from 'services/mvp/two-way.png';
import Award from 'services/product-design/icons/award.png';
import Dollar from 'services/product-design/icons/dollar.png';
import Eye from 'services/product-design/icons/eye.png';
import Mobile from 'services/product-design/icons/mobile.png';
import Paint from 'services/product-design/icons/paint.png';
import PencilD from 'services/product-design/icons/pencil.png';
import Processor from 'services/product-design/icons/processor.png';
import Puzzle from 'services/product-design/icons/puzzle.png';
import Smiley from 'services/product-design/icons/smiley.png';
import Star from 'services/product-design/icons/star.png';
import Arrow from 'services/product-discovery/slider-icons/arrow.png';
import Bulb1 from 'services/product-discovery/slider-icons/bulb1.png';
import Code from 'services/product-discovery/slider-icons/code.png';
import Pencil from 'services/product-discovery/slider-icons/pencil.png';
import Puzzles from 'services/product-discovery/slider-icons/puzzles.png';
import Rocket from 'services/rocket.png';

import {
  DecoratedTextImgFragment,
  DynamicInfoGridFragment,
  Enum_Componentdynamicblocksdynamicinfogridblock_Mode as ModeEnum,
} from '@/graphql/generated';

import {
  GridSection,
  GridSectionItem,
  SliderSection,
  TimelineExtendedSection,
  TimelineSection,
} from '../../services/sections';
import { DynamicTextWithDecoration } from '../DynamicTextWithDecoration';
import { getDecoratedTextImages } from '../utils/getDecoratedTextImages';

export const DynamicInfoGridBlock: FC<DynamicInfoGridFragment> = ({ header, items, mode, decoratedTextImages }) => {
  const parsedImages = getDecoratedTextImages(decoratedTextImages as DecoratedTextImgFragment);
  if (mode === ModeEnum.DarkWithUnderlines || mode === ModeEnum.DarkWithIcons) {
    const isDarkWithIconMode = mode === ModeEnum.DarkWithIcons;
    const ICONS = [LightBulb, BullsEye, TwoWay, Chart, Click, LightBulb];
    const gridItems = items.map((item, index) => {
      const imageFromStrapi = item?.image?.data?.attributes;
      return (
        <GridSectionItem
          heading={item?.heading || ''}
          description={item?.description || ''}
          buttonLabel={item?.buttonLabel || undefined}
          buttonLink={item?.buttonLink || undefined}
          icon={isDarkWithIconMode ? imageFromStrapi?.url || ICONS[index] : GridItemIcon}
          colorVariant="dark"
          iconHeight={isDarkWithIconMode ? (imageFromStrapi?.height ? `${imageFromStrapi?.height}px` : '80px') : '12px'}
          iconStyle={
            isDarkWithIconMode
              ? {
                  order: -1,
                }
              : {
                  marginBottom: {
                    base: 2,
                    sm: 4,
                  },
                }
          }
          key={`development-grid-item-${index}`}
        />
      );
    });

    return (
      <GridSection
        header={
          <DynamicTextWithDecoration {...parsedImages} isDarkMode>
            {header}
          </DynamicTextWithDecoration>
        }
        columnGap={{
          base: 8,
          lg: 28,
        }}
        rowGap={{
          base: 14,
          md: 20,
          lg: 24,
        }}
        items={gridItems}
      />
    );
  }

  if (mode === ModeEnum.LightWithLimeDots) {
    const LIME_DOT_ICONS = [Processor, Dollar, Smiley, Award, Star, Paint];
    return (
      <GridSection
        gridTemplateColumns={{
          base: '1',
          sm: 'repeat(2, minmax(260px, 1fr))',
          md: 'repeat(3, minmax(260px, 1fr))',
        }}
        columnGap={{
          base: 8,
          md: 10,
          lg: 24,
        }}
        rowGap={{
          base: 10,
          md: 12,
          lg: 8,
        }}
        colorVariant="light"
        header={<DynamicTextWithDecoration {...parsedImages}>{header}</DynamicTextWithDecoration>}
        headerStyle={{
          textAlign: 'center',
          marginBottom: {
            base: 10,
            sm: 12,
            lg: 16,
          },
        }}
        wrapperStyle={{
          paddingY: undefined,
          paddingTop: {
            base: 10,
            md: 20,
          },
          paddingBottom: {
            base: 16,
            sm: 20,
          },
        }}
        items={items.map((item, index) => (
          <GridSectionItem
            key={`product-design-${index}`}
            iconHeight="50px"
            iconStyle={{
              order: -1,
            }}
            styleVariant="alternative"
            heading={item?.heading || ''}
            description={item?.description || ''}
            buttonLabel={item?.buttonLabel || undefined}
            buttonLink={item?.buttonLink || undefined}
            icon={LIME_DOT_ICONS[index]}
          />
        ))}
      />
    );
  }

  if (mode === ModeEnum.DarkWithTimeline) {
    const TIMELINE_ICONS = [Idea, Pen, Bomb, Robots, Cat, Rocket];
    return (
      <TimelineSection
        header={
          <DynamicTextWithDecoration {...parsedImages} isDarkMode>
            {header}
          </DynamicTextWithDecoration>
        }
        gridColumns={3}
        itemStyle={{
          '& .timeline-img-5': {
            transform: {
              base: 'rotate(-60deg)',
            },
          },
        }}
        items={items.map((item, index) => ({
          number: `0${index + 1}`,
          icon: TIMELINE_ICONS[index],
          header: item?.heading || '',
          description: item?.description || '',
        }))}
      />
    );
  }

  if (mode === ModeEnum.DarkWithSlider) {
    const SLIDER_ICONS = [Code, Arrow, Pencil, Bulb1, Puzzles, Code];
    return (
      <SliderSection
        header={
          <DynamicTextWithDecoration {...parsedImages} isDarkMode>
            {header}
          </DynamicTextWithDecoration>
        }
        items={items.map((item, index) => ({
          icon: item?.image?.data?.attributes?.url ? item.image.data.attributes.url : SLIDER_ICONS[index],
          heading: item?.heading || '',
          description: item?.description || '',
          buttonLabel: item?.buttonLabel || undefined,
          buttonLink: item?.buttonLink || undefined,
        }))}
      />
    );
  }

  if (mode === ModeEnum.LightWithVerticalTimeline) {
    const VERTICAL_TIMELINE_ICONS = [Idea, Pen, Bomb, Robots, Cat, Rocket];
    const VERTICAL_TIMELINE_DECORATIONS = [Star1, Star1, Star2, Star1, Star1, Star2];
    return (
      <TimelineExtendedSection
        items={items.map((item, index) => ({
          number: `0${index + 1}`,
          icon: VERTICAL_TIMELINE_ICONS[index],
          header: item?.heading || '',
          description: item?.description || '',
          decoration: VERTICAL_TIMELINE_DECORATIONS[index],
        }))}
        header={<DynamicTextWithDecoration {...parsedImages}>{header}</DynamicTextWithDecoration>}
      />
    );
  }

  if (mode === ModeEnum.LightWithBigIcons) {
    const BIG_ICONS = [Eye, PencilD, Mobile, Puzzle, Eye, PencilD];
    const isDividedBy3 = items.length % 3 === 0;
    return (
      <GridSection
        gridTemplateColumns={{
          base: '1',
          sm: 'repeat(2, minmax(260px, 1fr))',
          lg: `repeat(${isDividedBy3 ? 3 : 4}, minmax(260px, 1fr))`,
        }}
        columnGap={{
          base: 8,
        }}
        rowGap={{
          base: 14,
          md: 16,
        }}
        colorVariant="light"
        header={<DynamicTextWithDecoration {...parsedImages}>{header}</DynamicTextWithDecoration>}
        headerStyle={{
          marginBottom: {
            base: 12,
            sm: 24,
            lg: 28,
          },
        }}
        items={items.map((item, index) => (
          <GridSectionItem
            heading={item?.heading || ''}
            description={item?.description || ''}
            buttonLabel={item?.buttonLabel || undefined}
            buttonLink={item?.buttonLink || undefined}
            icon={BIG_ICONS[index]}
            key={`expertise-${index}`}
            iconHeight={{
              base: '54px',
              sm: '80px',
            }}
            iconStyle={{
              order: -1,
              marginBottom: {
                base: 6,
                md: 12,
              },
            }}
          />
        ))}
      />
    );
  }

  return null;
};
