import { useRef } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';
import { useInView } from 'framer-motion';
import { useTranslation } from 'next-i18next';

import { StrapiImage } from '@/components/atoms';
import { ImageFragment } from '@/graphql/generated';
import { COMPANY_FOUNDATION_YEAR, LOCALES } from '@/utils/constants';

import { CakeDoodleBackground } from '../CakeDoodleBackground';

interface CakeDoodleProps {
  doodle: ImageFragment;
}

export const CakeDoodle = ({ doodle }: CakeDoodleProps) => {
  const { t } = useTranslation(LOCALES.HOME);
  const containerRef = useRef(null);
  const isInView = useInView(containerRef);
  const currentYear = new Date().getFullYear();
  const companyYearsOfExistence = currentYear - COMPANY_FOUNDATION_YEAR;

  return (
    <Flex
      mt={{ base: 7 }}
      gap={{ base: 4 }}
      px={{ base: 5, sm: 8, md: 0 }}
      pl={{ base: 0, sm: 16, md: 24 }}
      flexDirection={{ md: 'column' }}
      justifyContent={{ md: 'flex-end' }}
      position="relative"
      ref={containerRef}
    >
      <CakeDoodleBackground isInView={isInView} />
      <Box w={{ base: '155px', sm: '210px' }}>
        <StrapiImage image={doodle} />
      </Box>
      <Box color="violet.500" textTransform="uppercase" alignSelf={{ base: 'center', md: 'flex-start' }}>
        <Text
          variant="text-2xl/lineHeight-8/font-normal"
          fontFamily="grace"
          transform={{ base: 'rotate(17deg)', sm: 'rotate(7deg)' }}
        >
          {companyYearsOfExistence} {t('statistics.years')}
        </Text>
        <Text
          variant="text-2xl/lineHeight-8/font-normal"
          pl={{ base: 9 }}
          fontFamily="grace"
          transform={{ base: 'rotate(17deg)', sm: 'rotate(7deg)' }}
        >
          {t('statistics.inTheMarket')}
        </Text>
      </Box>
    </Flex>
  );
};
