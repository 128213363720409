import { useMemo } from 'react';

import { htmlToDOM } from 'html-react-parser';

import { getInnerHtml } from '@/utils/helper-functions/getInnerHtml';

export interface Chapter {
  id: string;
  label: string;
  level: number;
  children: Chapter[];
}

const chapterRegexp = /<h(\d)\s*?.*?>(.*?)<\/h\d>/g;

export const getChapterId = (label: string) =>
  label
    .toLocaleLowerCase()
    .replace(/\s/g, '-')
    .replace(/(?![A-Za-z-_:.0-9])/g, '');

export const getContentChapters = (content: string): Chapter[] => {
  const matches = Array.from(content.matchAll(chapterRegexp));

  const chapters = matches.map((match) => {
    const level = parseInt(match[1]);
    const label = htmlToDOM(match[2]).map(getInnerHtml).join('');
    const id = getChapterId(label);

    return { id, label, level, children: [] };
  });
  return chapters.reduce((acc, chapter) => {
    function addToChildren(chapters: Chapter[]) {
      const lastChapter = chapters.at(-1);

      if (!lastChapter || lastChapter.level >= chapter.level) {
        chapters.push(chapter);
      } else if (chapter.level < 3) {
        addToChildren(lastChapter.children);
      }
    }

    addToChildren(acc);

    return acc;
  }, [] as Chapter[]);
};

export const useContentChapters = (content: string) => {
  return useMemo(() => getContentChapters(content), [content]);
};
