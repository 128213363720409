import React from 'react';

import { Box } from '@chakra-ui/react';
import TornTop from 'public/assets/images/highlights/text-highlight.svg';

export const LeadershipBackground = () => {
  return (
    <Box
      position="absolute"
      top={{
        base: '-40px',
        md: '-60px',
        lg: '-80px',
      }}
      width="110vw"
      height="26vw"
      maxH="1016px"
      left="-7%"
      zIndex={-1}
      sx={{
        '& > svg': {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          '& path': {
            fill: 'purple.500',
          },
        },
      }}
    >
      <TornTop />
    </Box>
  );
};
