import { Box, Flex, GridItem, Text } from '@chakra-ui/react';

import { ParsedHTMLText } from '@/components/functional';
import { QuotationAuthor } from '@/components/functional/QuotationAuthor/QuotationAuthor';
import { StarsRating } from '@/components/molecules';
import { ClutchReviewFragment } from '@/graphql/generated';

interface ReviewCardProps {
  review: string;
  reviewerData: Omit<ClutchReviewFragment, 'id' | 'content'>;
}

export const ReviewCard = ({ review, reviewerData }: ReviewCardProps) => {
  const { reviewerName, reviewerDescription, reviewerAvatar, rating } = reviewerData;

  return (
    <GridItem as="article" h="fit-content" maxW="302px" backgroundColor="white" p={{ base: 5 }} borderRadius="3xl">
      <Text
        as="span"
        variant={{
          base: 'text-sm/lineHeight-5/font-normal',
          md: 'text-md/lineHeight-6/font-normal',
        }}
        lineHeight={{ md: 'lineHeight-5' }}
        color="purple.500"
      >
        <ParsedHTMLText content={review} />
      </Text>
      <Flex mt={4} flexBasis="100%" flexWrap="wrap">
        <QuotationAuthor
          role={reviewerDescription}
          avatar={reviewerAvatar.data?.attributes?.url || ''}
          name={reviewerName}
        />
        <Box mt={4} w="100%">
          <StarsRating rating={rating} />
        </Box>
      </Flex>
    </GridItem>
  );
};
