import { FC } from 'react';

import { ScrollSlider } from '@/components/sections/services/sections';
import { DynamicScrollSliderFragment } from '@/graphql/generated';
import { parseStrapiImage } from '@/utils/images';

export const DynamicScrollSlider: FC<DynamicScrollSliderFragment> = ({ badgeImage, badgeText, items }) => {
  const parsedItems = items.map((item) => ({
    slideImage: item?.slideImage.data?.attributes?.url ?? '',
    imageAlt: item?.imageAlt ?? '',
    url: item?.url ?? '',
  }));

  return <ScrollSlider items={parsedItems} badgeImage={parseStrapiImage(badgeImage)} badgeText={badgeText} />;
};
