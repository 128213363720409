import { SystemStyleObject } from '@chakra-ui/react';

export const memberGridStyles: SystemStyleObject = {
  '& .revealedMember': {
    top: '0',
  },
  '&:nth-of-type(odd) .revealedMember': {
    left: '0',
  },

  '&:nth-of-type(even) .revealedMember': {
    right: '0',
  },

  '&:nth-of-type(even) .revealedMemberAvatar': {
    marginLeft: 'auto',
  },

  '&:nth-of-type(odd) .revealedMemberText::before': {
    left: '20px',
  },

  '&:nth-of-type(even) .revealedMemberText::before': {
    right: '20px',
  },
};
