import { omitBy } from 'lodash';

import { DecoratedTextImgFragment } from '@/graphql/generated';

export const getDecoratedTextImages = (decoratedTextImages?: DecoratedTextImgFragment) => {
  const highlight = decoratedTextImages?.highlight;
  const exclaim = decoratedTextImages?.exclaim;
  const underline = decoratedTextImages?.underline;
  const circle = decoratedTextImages?.circle;
  const parsedImages = omitBy({ circle, highlight, exclaim, underline }, (image) => !image);
  return parsedImages;
};
