import React, { FC } from 'react';

import { Flex, Heading } from '@chakra-ui/react';

import { PaintbrushDoodle } from '@/components/sections/about-us/BannerSection/BannerBackground';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { BannerBlockFragment } from '@/graphql/generated';

import { DynamicTextWithDecoration } from '../DynamicTextWithDecoration';

export const DynamicBannerBlock: FC<BannerBlockFragment> = ({ bannerTitle, bannerDesc }) => {
  return (
    <SectionWrapper zIndex={0} id="aboutUsBanner">
      <SectionContentWrapper
        paddingX={0}
        paddingY={0}
        overflow="hidden"
        backgroundImage={{
          base: '/assets/images/about-us-header-mobile.jpg',
          sm: '/assets/images/about-us-header.jpg',
        }}
        backgroundPosition={{
          base: 'center top',
          sm: 'center',
          '2xl': 'center top',
        }}
        h={{
          base: '160vw',
          sm: '42vw',
        }}
        maxH={{
          base: 'unset',
          sm: '604px',
        }}
        minH="434px"
        backgroundRepeat="no-repeat"
        backgroundSize={{
          base: '100%',
          sm: 'cover',
        }}
      >
        <Flex
          position="absolute"
          bottom={{
            base: '6%',
            sm: '14%',
          }}
          left={{
            base: '14%',
            xs: '16%',
            sm: '6%',
          }}
          flexDir="column"
        >
          <Heading
            variant={{
              base: 'component-heading-large/xl',
              xs: 'component-heading-large/2xl',
              sm: 'component-heading-large/xl',
              md: 'component-heading-large/2xl',
              lg: 'component-heading-large/3xl',
            }}
            color="white"
            zIndex={2}
            mb={6}
          >
            <DynamicTextWithDecoration>{bannerTitle}</DynamicTextWithDecoration>
          </Heading>
          <Heading
            variant={{
              base: 'text-md/lineHeight-6/font-normal',
              sm: 'text-md/lineHeight-6/font-normal',
              md: 'text-xl/lineHeight-7/font-normal',
            }}
            color="white"
            zIndex={2}
          >
            {bannerDesc}
          </Heading>
        </Flex>

        <PaintbrushDoodle />
      </SectionContentWrapper>
    </SectionWrapper>
  );
};
