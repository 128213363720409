import { FC } from 'react';

import { Box, Grid, GridItem, Icon, Show, Text } from '@chakra-ui/react';
import Logo from 'images/icons/sdh-logo-mobile.svg';
import Image from 'next/image';

import { Button } from '@/components/atoms';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { ButtonVariant, DynamicCaseStudiesPreviewFragment } from '@/graphql/generated';

export const DynamicCaseStudiesPreview: FC<DynamicCaseStudiesPreviewFragment> = ({
  bigBoxHeader,
  bigBoxButtonLabel,
  bigBoxButtonLink,
  bigBoxBackgroundImage,
  middleBoxCaption,
  middleBoxBackgroundImage,
  rightBoxBackgroundImage,
  rightBoxCaption,
}) => {
  const mainImageSrc = bigBoxBackgroundImage?.data?.attributes?.url;
  const midImageSrc = middleBoxBackgroundImage?.data?.attributes?.url;
  const rightImageSrc = rightBoxBackgroundImage?.data?.attributes?.url;
  return (
    // The mobile version of this component doesn't exist,which kind of makes sense,
    // as I have no idea how it would look on mobile,
    // so for now I'm hiding it on smaller screens.
    <Show above="lg">
      <SectionWrapper backgroundColor="beige.50" id="case-study-preview-section">
        <SectionContentWrapper zIndex="0" pos="static">
          <Grid templateColumns="2fr 1fr 1fr" gap="28px" h="690px" mx="60px">
            <GridItem
              border="1px solid #d2d2d2"
              borderRadius="28px"
              p="32px"
              position="relative"
              overflow="hidden"
              display="flex"
              flexDir="column"
              justifyContent="space-between"
            >
              {mainImageSrc && (
                <Box pos="absolute" h="100%" right={0} bottom={0} width="70%" height="70%">
                  <Image src={mainImageSrc} alt="" fill style={{ objectFit: 'cover', right: 0, bottom: 0 }} />
                </Box>
              )}
              <Box>
                <Box width="34px" height="16px" pos="relative" mb="46px">
                  <Icon as={Logo} w="100%" h="100%" />
                </Box>
                <Text pos="relative" zIndex={2} variant="component-paragraph-headline/4xl" maxW="60%" fontWeight={500}>
                  {bigBoxHeader}
                </Text>
              </Box>
              <Button maxW="300px" url={bigBoxButtonLink} mb="60px" variant={ButtonVariant.Light}>
                {bigBoxButtonLabel}
              </Button>
            </GridItem>
            <GridItem
              border="1px solid #d2d2d2"
              borderRadius="28px"
              p="32px"
              position="relative"
              overflow="hidden"
              display="flex"
              flexDir="column"
              justifyContent="flex-end"
            >
              {midImageSrc && (
                <Box pos="absolute" h="60%" w="100%" top="32px" right={0}>
                  <Image src={midImageSrc} alt="" fill style={{ objectFit: 'cover', right: 0, bottom: 0 }} />
                </Box>
              )}
              <Text
                pos="relative"
                zIndex={2}
                variant="component-paragraph-headline/xl"
                ml="22px"
                fontWeight={700}
                lineHeight={1.7}
              >
                {middleBoxCaption}
              </Text>
            </GridItem>
            <GridItem
              border="1px solid #d2d2d2"
              borderRadius="28px"
              p="32px"
              position="relative"
              overflow="hidden"
              display="flex"
              flexDir="column"
            >
              <Text
                pos="relative"
                zIndex={2}
                variant="component-paragraph-headline/xl"
                fontWeight={700}
                lineHeight={1.7}
              >
                {rightBoxCaption}
              </Text>
              {rightImageSrc && (
                <Box pos="absolute" h="60%" w="100%" bottom="32px" right={0}>
                  <Image src={rightImageSrc} alt="" fill style={{ objectFit: 'cover' }} />
                </Box>
              )}
            </GridItem>
          </Grid>
        </SectionContentWrapper>
      </SectionWrapper>
    </Show>
  );
};
