import React, { FC } from 'react';

import { Divider } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { BannerSection } from '@/components/sections/about-us/BannerSection';
import { LeadershipSection } from '@/components/sections/about-us/LeadershipSection';
import { StatisticsSection } from '@/components/sections/about-us/StatisticsSection';
import { LetsBuildSomethingSection, Newsletter } from '@/components/sections/common';
import { RecommendedArticlesSection } from '@/components/sections/common/RecommendedArticlesSection';
import { useConstantData } from '@/components/sections/dynamic-blocks/DynamicConstantBlock/utils/useConstantData';
import { Partners } from '@/components/sections/home';
import { GallerySection, ScrollSlider, TeamFaces, TimelineSection } from '@/components/sections/services/sections';
import { DevelopmentTechnologySection } from '@/components/sections/services/sections';
import {
  Enum_Componentdynamicblocksdynamicconstantblock_Name as ConstantName,
  DynamicConstantFragment,
  useGetAboutUsQuery,
} from '@/graphql/generated';
import { AvailableLanguages } from '@/utils/constants';

export const DynamicConstantBlock: FC<DynamicConstantFragment> = ({ name }) => {
  const { statistics, leaderships, partners } =
    useGetAboutUsQuery(
      {},
      {
        enabled: [
          ConstantName.CompanyInNumbers,
          ConstantName.CompanyLeadershipTeam,
          ConstantName.CompanyPartnerships,
        ].includes(name),
      },
    ).data?.aboutUs?.data?.attributes! || {};
  const { i18n } = useTranslation();
  const {
    recommendedArticlesSection,
    technologySection,
    timelineSection,
    facesSection,
    gallerySection,
    scrollSliderSection,
  } = useConstantData(i18n.language as AvailableLanguages);

  switch (name) {
    case ConstantName.CtaLetsBuild:
      return <LetsBuildSomethingSection overflow={false} />;
    case ConstantName.FullPageDivider:
      return <Divider borderColor="purple.100" />;
    case ConstantName.CompanyDesignTeam: // Already done as dynamic component -> TO REPLACE EVERYWHERE AND THEN TO DELETE
      return <TeamFaces {...facesSection} />;
    case ConstantName.CompanyYearsOfExperience: // Already done as dynamic component -> TO REPLACE EVERYWHERE AND THEN TO DELETE
      return <GallerySection {...gallerySection} />;
    case ConstantName.ReadOurBlogs: // Already done as dynamic component -> TO REPLACE EVERYWHERE AND THEN TO DELETE
      return <RecommendedArticlesSection slug={''} {...recommendedArticlesSection} />;
    case ConstantName.LastDribbbleShots: // Already done as dynamic component -> TO REPLACE EVERYWHERE AND THEN TO DELETE
      return <ScrollSlider {...scrollSliderSection} />;
    case ConstantName.BuildGreatProducts:
      return <BannerSection />;
    //TODO: remove it from here after use DynamicStatistics on prod
    case ConstantName.CompanyInNumbers: // Already done as dynamic component -> TO REPLACE EVERYWHERE AND THEN TO DELETE
      if (statistics) {
        return <StatisticsSection statistics={statistics} />;
      }
      return null;
    //TODO: remove it from here after use DynamicLeaderSection on prod
    case ConstantName.CompanyLeadershipTeam: // Already done as dynamic component -> TO REPLACE EVERYWHERE AND THEN TO DELETE
      if (leaderships) {
        return <LeadershipSection leaders={leaderships?.data} />;
      }
      return null;
    //TODO: remove it from here after use DynamicPartnersSection on prod
    case ConstantName.CompanyPartnerships: // Already done as dynamic component -> TO REPLACE EVERYWHERE AND THEN TO DELETE
      if (partners) {
        return <Partners partners={partners?.data!} variant="light" />;
      }
      return null;
    case ConstantName.CompanyTechStack:
      return <DevelopmentTechnologySection {...technologySection} />;
    case ConstantName.HowWeBuildProducts:
      return (
        <TimelineSection
          {...timelineSection}
          gridColumns={3}
          itemStyle={{
            '& .timeline-img-5': {
              transform: {
                base: 'rotate(-60deg)',
              },
            },
          }}
        />
      );
    case ConstantName.SubscribeToNewsletter:
      return <Newsletter />;
    default:
      return null;
  }
};
