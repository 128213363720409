import React, { ReactNode } from 'react';

import { Heading } from '@chakra-ui/react';

import { ParsedHTMLText } from '@/components/functional';
import { SectionContentWrapper, SectionWrapper, SectionWrapperProps } from '@/components/wrappers';

export interface MinifiedArticleHeaderProps extends SectionWrapperProps {
  children?: ReactNode;
  title: string;
}

export const MinifiedArticleHeader = ({ children, title, ...props }: MinifiedArticleHeaderProps) => {
  return (
    <SectionWrapper>
      <SectionContentWrapper
        display="flex"
        flexDir="column"
        alignItems="center"
        paddingY={{
          base: 8,
          lg: 20,
        }}
        {...props}
      >
        <Heading
          as="h1"
          variant={{
            base: 'component-heading-large/xl',
            sm: 'component-heading-large/3xl',
          }}
          mb={{ base: 12 }}
          textAlign="center"
          maxW="1062px"
          color="purple.500"
        >
          <ParsedHTMLText content={title} />
        </Heading>
        {children}
      </SectionContentWrapper>
    </SectionWrapper>
  );
};
