import React from 'react';

import { Box, Grid, GridItem, Heading, Show, Text } from '@chakra-ui/react';
import Image from 'next/image';

import { StaticBackground } from '@/components/atoms/StaticBackground';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { DynamicTeamInvolvedFragment } from '@/graphql/generated';

import { DynamicTextWithDecoration } from '../DynamicTextWithDecoration';

export const DynamicTeamInvolvedSection = ({ header, subheader, items }: DynamicTeamInvolvedFragment) => {
  return (
    <SectionWrapper>
      <SectionContentWrapper>
        <StaticBackground
          maxW={{
            base: '400px',
            sm: '535px',
            md: '687px',
          }}
          maxH="fit-content"
          w="100%"
          h="100%"
          left={{
            base: '-10%',
            sm: '30%',
            md: '0',
          }}
          top={{
            base: '75%',
            sm: '50%',
          }}
        />
        <Show above="md">
          <StaticBackground
            right="-30%"
            top="50%"
            backgroundColor="violet.50"
            width="1288px"
            height="1288px"
            transform="translateY(-50%)"
          />
        </Show>

        <Heading
          variant={{
            base: 'component-heading-large/xl',
            md: 'component-heading-large/2xl',
            lg: 'component-heading-large/3xl',
          }}
          color="purple.500"
          textAlign="center"
          mb={{
            base: 8,
            md: 10,
          }}
        >
          <DynamicTextWithDecoration>{header}</DynamicTextWithDecoration>
        </Heading>
        {subheader && (
          <Text
            color="purple.500"
            variant={{
              base: 'text-md/lineHeight-6/font-normal',
              md: 'text-lg/lineHeight-7/font-normal',
              lg: 'text-xl/lineHeight-7/font-normal',
            }}
            textAlign="center"
            mb={{
              base: 12,
              md: 20,
              lg: 16,
            }}
          >
            <DynamicTextWithDecoration>{subheader}</DynamicTextWithDecoration>
          </Text>
        )}
        <Grid
          gap={{
            base: 6,
          }}
          gridTemplateColumns={{
            base: '1fr',
            xs: '1fr 1fr',
            md: 'repeat(4, minmax(0, 1fr))',
          }}
          mb={{
            base: 0,
            sm: 2,
            lg: 12,
          }}
        >
          {items.map((item) => (
            <GridItem display="flex" alignItems="center" flexDir="column" key={`avatar-${item?.id}`}>
              <Box
                mb={{
                  base: 0,
                  md: 6,
                }}
                width="192px"
                height="170px"
                pos="relative"
              >
                <Image
                  src={item?.image1.data?.attributes?.url || ''}
                  alt={item?.caption || ''}
                  fill
                  objectFit="contain"
                />
              </Box>
              <Text
                variant={{
                  base: 'text-md/lineHeight-6/font-medium',
                  lg: 'text-xl/lineHeight-7/font-medium',
                }}
                color="purple.500"
                textAlign="center"
                maxW="100%"
              >
                {item?.caption}
              </Text>
            </GridItem>
          ))}
        </Grid>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};
