import { FC } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';
import YellowCheckWithStars from 'images/doodles/yellow-check-with-stars.png';
import Image from 'next/image';

export const NewsletterSubscribeSuccess: FC<{ label: string }> = ({ label }) => {
  return (
    <Flex
      mx="auto"
      gap="8"
      align="center"
      maxW="694px"
      flexDirection={{
        base: 'column',
        sm: 'row',
      }}
      my="-4"
    >
      <Box
        sx={{
          '& .check-doodle': {
            w: '156px',
            h: '124px',
          },
        }}
      >
        <Image src={YellowCheckWithStars} alt="" className="check-doodle" />
      </Box>
      <Text
        as="span"
        flex="1"
        textAlign={{
          base: 'center',
          sm: 'start',
        }}
        color="white"
        variant="component-heading-large/xl"
      >
        {label}
      </Text>
    </Flex>
  );
};
