import { Icon, SystemStyleObject, defineStyle, keyframes } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const cardFrameCommonStyles = defineStyle({
  width: { base: '280px', sm: '324px', xl: '368px' },
  height: { base: '80px', sm: '104px' },
  borderRadius: '18px',
});

export const AnimatedBadge = styled(Icon)<{
  showBadge?: boolean;
  willShowBadge?: boolean;
}>`
  transition: all 0.2s ease;
  transform: ${({ showBadge }) => (!showBadge ? 'scale(1.5)' : 'scale(1)')};
  opacity: ${({ showBadge, willShowBadge }) => (!showBadge || !willShowBadge ? '0' : '1')};
`;

export const statisticCardStyles: SystemStyleObject = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  '&:nth-of-type(even) .statisticCard': {
    base: {
      alignSelf: 'flex-end',
      marginLeft: 'auto',
    },
  },
  '&:nth-of-type(1)': {
    sm: {
      width: '100%',
      order: 1,
    },
  },
  '&:nth-of-type(2)': {
    sm: {
      order: 3,
    },
  },
  '&:nth-of-type(3)': {
    sm: {
      width: '100%',
      order: 4,
    },
  },

  '&:nth-of-type(4)': {
    sm: {
      order: 2,
    },
  },
};

export const shuffleAnimation = (side: string) =>
  keyframes`
  0%, 100% {
    transform: perspective(1000px) translateY(-10%) rotateX(-180deg) rotateZ(5deg);
    z-index: ${shuffleCardsZIndices.medium};
    // visibility is used because opacity is animated and we don't want it
    visibility: hidden;
  }
  6%{
    transform: perspective(1000px) translateY(-140%) rotateX(-180deg) rotateZ(-4deg);
    visibility: ${side === 'front' ? 'hidden' : 'visible'};
  }
  10% {
    transform: perspective(1000px) translateY(-120%) rotateX(-180deg) rotateZ(2deg);
    visibility: ${side === 'front' ? 'hidden' : 'visible'};
  }
  16% {
    transform: perspective(1000px) translateY(-120%) rotateX(-90deg) rotateZ(0deg);
     visibility: ${side === 'front' ? 'visible' : 'hidden'};
  }
  20% {
    transform: perspective(1000px) translateY(-120%) rotateX(0deg);
     visibility: ${side === 'front' ? 'visible' : 'hidden'};
  }
  24% {
    z-index: ${shuffleCardsZIndices.ultra};
    visibility: ${side === 'front' ? 'visible' : 'hidden'};
  }
  28% {
    transform: perspective(1000px) translateY(0) rotateX(0deg);
    visibility: hidden;
  }
`;

export const shuffleCardsZIndices = {
  base: 0,
  low: 3,
  medium: 4,
  strong: 5,
  ultra: 6,
};
