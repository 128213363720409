import { Box, Hide } from '@chakra-ui/react';

import { MinifiedArticleHeader } from '@/components/sections/blog';
import { SectionWrapperProps } from '@/components/wrappers';
import { ArticleTagsFragment, Maybe, UserFragment } from '@/graphql/generated';

import { AboutArticle } from '../AboutArticle/AboutArticle';

export interface ArticleHeaderProps extends SectionWrapperProps {
  tags?: Maybe<ArticleTagsFragment>;
  title: string;
  authors: UserFragment[];
  minutesOfRead: number;
  createdAt: DateLike;
  content: string;
}

export const ArticleHeader = ({
  tags,
  title,
  content,
  authors,
  minutesOfRead,
  createdAt,
  ...props
}: ArticleHeaderProps) => {
  return (
    <MinifiedArticleHeader title={title} {...props}>
      <Hide above="lg">
        <Box mb={12}>
          <AboutArticle
            authors={authors}
            minutesOfRead={minutesOfRead || 0}
            createdAt={createdAt}
            content={content}
            tags={tags}
          />
        </Box>
      </Hide>
    </MinifiedArticleHeader>
  );
};
