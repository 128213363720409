import React, { useEffect, useRef } from 'react';

import { animate, useInView } from 'framer-motion';

export const AnimatedNumber = ({
  startingValue,
  finalValue,
  durationS,
}: {
  durationS: number;
  finalValue: number;
  startingValue: number;
}) => {
  const nodeRef = useRef<HTMLSpanElement>(null);
  const isInView = useInView(nodeRef);

  useEffect(() => {
    const node = nodeRef.current;

    if (!isInView) {
      return;
    }

    const controls = animate(startingValue, finalValue, {
      duration: durationS,
      onUpdate(value) {
        if (node?.innerText) {
          node.innerText = value.toFixed(finalValue % 1 === 0 ? 0 : 1);
        }
      },
    });

    return () => controls.stop();
  }, [startingValue, finalValue, isInView, durationS]);

  return <span ref={nodeRef}>{startingValue}</span>;
};
