import React from 'react';

import { Box, Flex, Heading, Hide, useMediaQuery, useQuery } from '@chakra-ui/react';
import Image from 'next/image';
import SeeHiddenMobile from 'services/mvp/see-whats-hidden-mobile.png';

import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { DynamicTeamInvolvedWithHoverFragment } from '@/graphql/generated';

import { DynamicTextWithDecoration } from '../DynamicTextWithDecoration';
import { FacesItem, FacesItemProps } from './FaceItem';

export const DynamicTeamInvolvedHover = ({ header, items }: DynamicTeamInvolvedWithHoverFragment) => {
  const [isLargerThanSm] = useMediaQuery(useQuery({ above: 'sm' }));

  return (
    <SectionWrapper>
      <SectionContentWrapper>
        <Heading
          variant={{
            base: 'component-heading-large/xl',
            lg: 'component-heading-large/2xl',
          }}
          color="purple.500"
          textAlign="center"
          mt={{
            base: 0,
            sm: 3,
            md: 14,
          }}
          mb={{
            base: 12,
            sm: 20,
          }}
        >
          <DynamicTextWithDecoration>{header}</DynamicTextWithDecoration>
        </Heading>
        <Hide above="sm">
          <Box w="167px" as={Image} src={SeeHiddenMobile} alt="see whats hidden" ml="10%" />
        </Hide>
        <Flex
          flexDir={{
            base: 'column',
            sm: 'row',
          }}
          justifyContent="space-evenly"
          flexWrap="wrap"
          gap={{
            base: 16,
            md: 32,
            lg: 16,
            xl: 24,
          }}
          mb={{
            sm: 40,
            lg: 0,
          }}
          paddingX={{
            md: 10,
            lg: 0,
          }}
        >
          {items.map((item, index) => (
            <FacesItem
              {...(item as FacesItemProps)}
              isMobile={!isLargerThanSm}
              isLast={index === items.length - 1}
              key={index}
            />
          ))}
        </Flex>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};
