import { FC } from 'react';

import { Text } from '@chakra-ui/react';

import { DynamicTextWithDecoration } from '@/components/sections/dynamic-blocks/DynamicTextWithDecoration';
import { VideoBannerSection } from '@/components/sections/services/sections';
import { DynamicVideoBannerFragment } from '@/graphql/generated';
import { parseTextDecoration } from '@/utils/text-decorations';

export const DynamicVideoBanner: FC<DynamicVideoBannerFragment> = ({
  header,
  coverVideoUrl,
  coverImageFallback,
  decoratedTextImages,
  buttonLabel,
  buttonLink,
}) => {
  const parsedHeader = header.replaceAll('\\n', '\n');
  const bannerHeader = (
    <Text whiteSpace="pre-line">
      <DynamicTextWithDecoration
        isDarkMode
        highlightTextColor="violet.500"
        {...parseTextDecoration(decoratedTextImages)}
      >
        {parsedHeader}
      </DynamicTextWithDecoration>
    </Text>
  );
  return (
    <VideoBannerSection
      header={bannerHeader}
      coverImageFallback={{ url: coverImageFallback.data?.attributes?.url }}
      coverVideo={{ url: coverVideoUrl.data?.attributes?.url }}
      buttonLabel={buttonLabel}
      buttonLink={buttonLink}
    />
  );
};
