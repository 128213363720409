import { Box, useMediaQuery, useTheme } from '@chakra-ui/react';
import Brain from 'images/backgrounds/brain.png';
import CrewelDesktop from 'images/backgrounds/crewel-desktop.svg';
import CrewelMobile from 'images/backgrounds/crewel-mobile.svg';
import CrewelTablet from 'images/backgrounds/crewel-tablet.svg';
import HeartPuzzles from 'images/backgrounds/heart-puzzles.png';
import Puzzle from 'images/backgrounds/puzzle.png';
import Image from 'next/image';

import { GradientBackground } from '@/components/atoms';
import { GradientBackgroundProps } from '@/utils/models/background-props';

export const LetsMeetBackground = ({ isInView }: GradientBackgroundProps) => {
  const theme = useTheme();
  const [isLargerThanSm, isLargerThanLg] = useMediaQuery([
    `(min-width: ${theme.breakpoints.sm})`,
    `(min-width: ${theme.breakpoints.lg})`,
  ]);

  const showCrewel = () => {
    if (!isLargerThanSm) {
      return (
        <Box
          position="absolute"
          left="0"
          bottom="0"
          right="0"
          top="0"
          sx={{
            '& > svg': {
              base: {
                position: 'absolute',
                width: '100%',
                height: '70%',
                bottom: '-20px',
                left: '40px',
              },
            },
          }}
        >
          <CrewelMobile />
        </Box>
      );
    } else if (isLargerThanSm && !isLargerThanLg) {
      return (
        <Box
          position="absolute"
          left="0"
          bottom="0"
          right="0"
          top="0"
          sx={{
            '& > svg': {
              sm: {
                position: 'absolute',
                width: '400px',
                height: '540px',
                bottom: '25%',
                right: '30%',
              },
              md: {
                right: '35%',
              },
            },
          }}
        >
          <CrewelTablet />
        </Box>
      );
    } else if (isLargerThanLg) {
      return (
        <Box
          position="absolute"
          left="0"
          bottom="0"
          right="0"
          top="0"
          sx={{
            '& > svg': {
              lg: {
                position: 'absolute',
                width: '554px',
                height: '280px',
                top: '40%',
                left: '25%',
              },
              xl: {
                width: '654px',
                height: '300px',
                top: '38%',
                left: '25%',
              },
              '2xl': {
                left: '29%',
              },
              '3xl': {
                left: '30%',
              },
            },
          }}
        >
          <CrewelDesktop />
        </Box>
      );
    }
  };

  return (
    <>
      {showCrewel()}
      <Box
        position="absolute"
        as={Image}
        alt="puzzle"
        src={Puzzle}
        sx={{
          '&': {
            base: {
              width: '49px',
              top: '38%',
              left: '55%',
            },
            '2xs': {
              left: '45%',
            },
            sm: {
              width: '60px',
              left: 'initial',
              top: '48%',
              right: '14%',
            },
            md: {
              right: '20%',
            },
            lg: {
              right: 'initial',
              top: '20%',
              left: '46%',
            },
          },
        }}
      />
      <Box
        position="absolute"
        as={Image}
        src={Brain}
        alt="brain"
        sx={{
          '&': {
            base: {
              position: 'absolute',
              width: 'auto',
              height: '39px',
              bottom: '45%',
              right: '5%',
            },
            xs: {
              right: '25%',
            },
            sm: {
              width: 'auto',
              height: '66px',
              bottom: '13%',
              right: '10%',
            },
            md: {
              right: '18%',
            },
            lg: {
              right: 'initial',
              bottom: '32%',
              left: '52%',
            },
          },
        }}
      />
      {isLargerThanSm && (
        <Box
          position="absolute"
          as={Image}
          src={HeartPuzzles}
          alt="Heart Puzzles"
          sx={{
            '&': {
              sm: {
                position: 'absolute',
                width: 'auto',
                height: '70px',
                bottom: '28%',
                left: 8,
              },
              md: {
                left: '15%',
              },
              lg: {
                bottom: '43%',
                left: 20,
              },
              xl: {
                left: 32,
              },
              '2xl': {
                left: 56,
              },
              '3xl': {
                left: 96,
              },
            },
          }}
        />
      )}
      <GradientBackground
        isInView={isInView}
        w={{ base: '100%', sm: '1067px', lg: '1014px' }}
        h={{ base: '509px', sm: '1067px', lg: '500px' }}
        position="absolute"
        left={{ base: '0', sm: '-216px', lg: '503px' }}
        bottom={{ base: '0' }}
        top={{ sm: '52px' }}
        filter="blur(104px)"
        zIndex="-3"
      />
    </>
  );
};
