import React, { FC } from 'react';

import { DynamicDividerWithPhotosFragment } from '@/graphql/generated';
import { extendArrayByCopying } from '@/utils/helper-functions/arrays';
import { isUrl } from '@/utils/urls';

import { ScrollSlider } from '../../services/sections';

export const DynamicDividerWithPhotos: FC<DynamicDividerWithPhotosFragment> = ({ multipleImages }) => {
  const images = multipleImages.data?.map((img) => {
    const url = isUrl(img.attributes?.alternativeText) ? img.attributes?.alternativeText : null;
    return {
      slideImage: img.attributes?.url || '',
      imageAlt: img.attributes?.caption || '',
      url: url || '',
    };
  });
  return (
    <ScrollSlider
      imageWrapperStyle={{
        w: {
          base: '165px',
          md: '220px',
        },
        h: {
          base: '165px',
          md: '220px',
        },
      }}
      style={{
        gap: 6,
        mt: 0,
      }}
      items={extendArrayByCopying(images, 12)}
    />
  );
};
