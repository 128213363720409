import { FC } from 'react';

import { Grid, GridItem, Heading, Text } from '@chakra-ui/react';
import Image from 'next/image';

import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { HowWeWorkFragment } from '@/graphql/generated';

export const HowWeWork: FC<HowWeWorkFragment> = ({ heading, howWeWorkItem }) => {
  return (
    <SectionWrapper backgroundColor="beige.50" id="case-study-preview-section">
      <SectionContentWrapper zIndex="0" pos="static">
        <Heading
          as="h2"
          color="black"
          variant={{
            base: 'component-heading-large/xl',
            sm: 'component-heading-large/2xl',
            lg: 'component-heading-large/3xl',
          }}
          mb="80px"
          textAlign="center"
        >
          {heading}
        </Heading>
        <Grid
          templateColumns={{ base: '100%', md: '50% 50%' }}
          columnGap="20px"
          rowGap="62px"
          as="ul"
          listStyleType="none"
        >
          {howWeWorkItem.map((item) => (
            <GridItem key={item?.id} as="li" display="flex" flexDir="column" alignItems="center">
              {item?.image?.data?.attributes?.url && (
                <Image
                  width={70}
                  height={70}
                  style={{ objectFit: 'contain' }}
                  src={item?.image?.data?.attributes?.url ?? ''}
                  alt=""
                />
              )}

              <Text
                variant="component-paragraph-headline/xl"
                mt="32px"
                as="h3"
                zIndex={1}
                pos="relative"
                maxW="280px"
                fontWeight="700"
                textAlign="center"
              >
                {item?.text}
              </Text>
            </GridItem>
          ))}
        </Grid>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};
