import { FlexProps, keyframes } from '@chakra-ui/react';

export const bubbleStyle: FlexProps = {
  alignItems: 'flex-start',
  position: 'absolute',
  zIndex: 2,
  pointerEvents: 'none',
  sx: {
    '& p': {
      textAlign: 'left',
    },
  },
  py: 2,
  px: 4,
};

export const bubbleAnimation = keyframes`
  0%, 100% {
    opacity: 0;
    transform: translateY(-20px);
  }
  20%, 80% {
    opacity: 1;
    transform: translateY(0px);
  }
`;
