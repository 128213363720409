import { StatisticsSection } from '@/components/sections/about-us/StatisticsSection';
import { DynamicStatisticsFragment } from '@/graphql/generated';

export const DynamicStatisticsBlock = ({
  statistics,
  darkBackground,
  underlinedValues,
  statHeader,
}: DynamicStatisticsFragment) => {
  return (
    <StatisticsSection
      statistics={statistics}
      darkBackground={darkBackground}
      underlinedValues={underlinedValues}
      heading={statHeader}
    />
  );
};
