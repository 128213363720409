import { ElementRef, useRef } from 'react';

import { Box, Flex, Grid, Heading, Text, useMediaQuery, useQuery } from '@chakra-ui/react';
import { useInView } from 'framer-motion';
import PuddingDoodle from 'images/doodles/pudding.png';
import Image from 'next/image';

import { GradientBackground, StrapiImage } from '@/components/atoms';
import { Slider } from '@/components/functional';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { DecoratedTextImgFragment, DynamicClientOpinionsFragment } from '@/graphql/generated';

import { SliderControls } from '../../../functional/SliderControls/SliderControls';
import { DynamicTextWithDecoration } from '../DynamicTextWithDecoration';
import { getDecoratedTextImages } from '../utils/getDecoratedTextImages';
import { Card } from './Card/Card';

export const DynamicClientOpinions = ({
  title,
  subtitle,
  opinions,
  decoratedTextImages,
}: DynamicClientOpinionsFragment) => {
  const parsedImages = getDecoratedTextImages(decoratedTextImages as DecoratedTextImgFragment);
  const [isAboveXS] = useMediaQuery(useQuery({ above: 'xs' }));
  const containerRef = useRef(null);
  const sliderRef = useRef<ElementRef<typeof Slider>>(null);
  const isInView = useInView(containerRef);

  return (
    <SectionWrapper>
      <SectionContentWrapper color="purple.500" ref={containerRef}>
        <GradientBackground
          isInView={isInView}
          position="absolute"
          top={{ base: '300px', sm: '131px' }}
          left={{ base: '0', sm: '491px' }}
          right="0"
          bottom="0"
          zIndex="-2"
        />
        <Grid
          gridTemplateColumns={{
            base: '[column1-start column-start] minmax(0, 100%) [column1-end column2-start] 0 [column2-end column-end]',
            lg: '[column1-start column-start] minmax(0, 0.3fr) [column1-end column2-start] minmax(0, 1fr) [column2-end column-end]',
          }}
          gridTemplateRows={{
            base: '[row1-start row-start] min-content [row1-end row2-start] min-content [row2-end row3-start] auto [row3-end row4-start] auto [row4-end row5-start] auto [row5-end row-end]',
            lg: '[row1-start row-start] min-content [row1-end row2-start] min-content [row2-end row3-start] auto [row3-end row-end]',
          }}
          columnGap={{ lg: 9 }}
        >
          <Heading
            /* GRID ITEM 1 */
            variant={{
              base: 'component-heading-large/xl',
              sm: 'component-heading-large/3xl',
            }}
            gridColumn="1 / span 2"
            gridRowEnd="row1-end"
            display="flex"
            flexDir="column"
          >
            <DynamicTextWithDecoration {...parsedImages} isDarkMode>
              {title}
            </DynamicTextWithDecoration>
          </Heading>
          <Text
            variant={{
              base: 'text-md/lineHeight-6/font-semibold',
              sm: 'component-paragraph-headline/lg',
            }}
            pt={{ base: 6, sm: 8 }}
            /* GRID ITEM 2 */
            gridColumnEnd="column1-end"
            gridRowStart="row2-start"
            gridRowEnd="row2-end"
          >
            <DynamicTextWithDecoration>{subtitle}</DynamicTextWithDecoration>
          </Text>
          <SliderControls
            pt={{ base: 8 }}
            onSlidePrev={() => sliderRef.current?.onPrev()}
            onSlideNext={() => sliderRef.current?.onNext()}
            /* GRID ITEM 3 */
            gridColumnStart={{ base: 'column1-start', lg: 'column2-start' }}
            gridColumnEnd={{ base: 'column1-end', lg: 'column2-end' }}
            gridRowStart={{ base: 'row3-start', lg: 'row1-start' }}
            gridRowEnd={{ base: 'row3-end', lg: 'row1-end' }}
            alignSelf="end"
            variant="light"
          />
          <Flex
            flexDir={{ base: 'column', sm: 'row', lg: 'column' }}
            alignItems={{ base: 'center', sm: 'flex-end', lg: 'flex-start' }}
            pt={{ base: 9, sm: 10, md: 20, lg: 0 }}
            /* GRID ITEM 4 */
            gridColumnEnd="column1-end"
            gridRowStart={{ base: 'row5-start', lg: 'row3-start' }}
            justifySelf={{ base: 'center', lg: 'start' }}
            alignSelf="end"
          >
            <Box
              width={{ base: '140px', sm: '220px' }}
              height={{ base: '140px', sm: 'auto' }}
              mb={{ base: 8, sm: 0, lg: 16 }}
              mr={{ sm: 8, lg: 0 }}
              position="relative"
              overflow="hidden"
            >
              <Box
                as={Image}
                src={PuddingDoodle}
                alt="pudding"
                maxW="unset"
                width={{
                  base: '260px',
                  sm: '100%',
                }}
                height="auto"
                left="-66px"
                top="-94px"
                position={{
                  base: 'absolute',
                  sm: 'initial',
                }}
              />
            </Box>
            <Flex flexDir="column" alignItems="center">
              {opinions.awardLogo?.data?.attributes && <StrapiImage image={opinions.awardLogo} />}
            </Flex>
          </Flex>
          <Grid
            mx={{ base: -5, xs: 0 }}
            pt={{ base: 8, sm: 12 }}
            /* GRID ITEM 4 */
            gridColumnStart={{ base: 'column1-start', lg: 'column2-start' }}
            gridColumnEnd={{ base: 'column1-end', lg: 'column2-end' }}
            gridRowStart={{ base: 'row4-start', lg: 'row2-start' }}
            gridRowEnd={{ base: 'row4-end', lg: 'row3-end' }}
          >
            <Slider ref={sliderRef} snapDirection={isAboveXS ? 'start' : 'center'}>
              {opinions.opinions.map(
                (card, index) => card && <Card key={`${card?.__typename}_${index}`} card={card} />,
              )}
            </Slider>
          </Grid>
        </Grid>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};
