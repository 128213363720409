import { Box, Flex, Text } from '@chakra-ui/react';

import { StrapiImage } from '@/components/atoms';
import { PartnerFragment } from '@/graphql/generated';

interface CardProps {
  card: PartnerFragment;
  variant?: 'dark' | 'light';
}

export const Card = ({ card, variant }: CardProps) => {
  const { clientLogo, content, partnerTags } = card?.attributes!;
  const isDarkVariant = variant === 'dark';
  const tags = partnerTags?.data;

  return (
    <Flex
      as="article"
      direction="column"
      justifyContent="space-between"
      color="black"
      bgColor={isDarkVariant ? 'beige.50' : 'white'}
      borderRadius="3xl"
      minW="300px"
      h="auto"
      px={6}
      pt={{ base: 9, sm: 12 }}
      pb={5}
      gap={{ base: 8, sm: 16 }}
    >
      <Flex direction="column" gap={9}>
        <Box>
          <StrapiImage image={clientLogo} loading="eager" />
        </Box>
        <Text variant="text-xs/lineHeight-4/font-normal/0.75rem">{content}</Text>
      </Flex>

      {Array.isArray(tags) ? (
        <Flex width="100%" gap={2}>
          {tags.map((tag) => (
            <Box
              key={`tag-${tag.id}`}
              px={2}
              py={0.5}
              color="purple.500"
              borderRadius="3xl"
              border="1px solid"
              borderColor="violet.200"
            >
              <Text variant="text-xs/lineHeight-4/font-medium/0.75rem" whiteSpace="nowrap">
                {tag.attributes?.name}
              </Text>
            </Box>
          ))}
        </Flex>
      ) : null}
    </Flex>
  );
};
