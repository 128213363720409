import { FC } from 'react';

import { Text } from '@chakra-ui/react';

import { RecommendedArticlesSection } from '@/components/sections/common/RecommendedArticlesSection';
import { DynamicTextWithDecoration } from '@/components/sections/dynamic-blocks/DynamicTextWithDecoration';
import { DynamicRecommendedArticlesFragment } from '@/graphql/generated';
import { parseTextDecoration } from '@/utils/text-decorations';

export const RecommendedArticles: FC<DynamicRecommendedArticlesFragment> = ({ header, tags, decoratedTextImages }) => {
  const recommendedArticlesHeader = (
    <Text whiteSpace="pre-line">
      <DynamicTextWithDecoration {...parseTextDecoration(decoratedTextImages)}>{header}</DynamicTextWithDecoration>
    </Text>
  );

  const parsedTags = tags.data.map(({ attributes }) => attributes?.name ?? '').filter((item) => !!item);
  return <RecommendedArticlesSection tags={parsedTags} slug={''} header={recommendedArticlesHeader} />;
};
