import { FC } from 'react';

import { Flex, Heading, Text } from '@chakra-ui/react';

import { CaseStudiesList } from '@/components/sections/common';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { CaseStudiesShowcaseFragment, useGetCaseStudiesBySlugArrayQuery } from '@/graphql/generated';
import { useLocale } from '@/hooks';

import { CaseStudyActionButton } from './CaseStudyActionButton/CaseStudyActionButton';

export const CaseStudiesShowcase: FC<CaseStudiesShowcaseFragment> = ({
  title,
  subtitle,
  buttonText,
  caseStudySlugs,
}) => {
  const locale = useLocale();
  const slugs = caseStudySlugs.map((item) => item?.caseStudySlug ?? '') ?? [];

  const caseStudies = useGetCaseStudiesBySlugArrayQuery({ locale, slugs }, {}).data?.caseStudies?.data!;

  return (
    <SectionWrapper
      zIndex={0}
      overflow={{
        base: 'visible',
        md: 'hidden',
      }}
      id="caseStudiesSection"
    >
      <SectionContentWrapper
        maxHeight={{
          base: 'unset',
          md: '1800px',
        }}
      >
        <Flex flexDirection={{ base: 'column' }} mb={10}>
          <Heading
            variant={{
              base: 'component-heading-large/xl',
              md: 'component-heading-large/3xl',
            }}
            color="purple.500"
          >
            {title}
          </Heading>
          <Text
            variant={{
              base: 'text-md/lineHeight-6/font-normal',
              md: 'text-2xl/lineHeight-8/font-normal',
            }}
            color="purple.500"
            mt={5}
          >
            {subtitle}
          </Text>
        </Flex>

        <CaseStudiesList caseStudies={caseStudies || []} isHomepageSection={true} showTags={true} />
        <CaseStudyActionButton buttonLabel={buttonText} />
      </SectionContentWrapper>
    </SectionWrapper>
  );
};
