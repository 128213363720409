import styled from '@emotion/styled';
import Dust1Img from 'images/doodles/rocket/dust1.png';
import Dust2Img from 'images/doodles/rocket/dust2.png';
import Dust3Img from 'images/doodles/rocket/dust3.png';
import Dust4Img from 'images/doodles/rocket/dust4.png';
import FireImg from 'images/doodles/rocket/fire.png';
import RocketImg from 'images/doodles/rocket/rocket.png';
import Image from 'next/image';

type ImgType = {
  className: string;
};

const Dust1Png = ({ className }: ImgType) => <Absolute src={Dust1Img} alt="" className={className} />;

const Dust2Png = ({ className }: ImgType) => <Absolute src={Dust2Img} alt="" className={className} />;

const Dust3Png = ({ className }: ImgType) => <Absolute src={Dust3Img} alt="" className={className} />;

const Dust4Png = ({ className }: ImgType) => <Absolute src={Dust4Img} alt="" className={className} />;

export const AnimatedRocket = ({ shouldAnimate = true }: { shouldAnimate: boolean }) => (
  <RocketWrapper id="rocketWrapper">
    <Rocket animate={shouldAnimate}>
      <Dust1S className="dust1" />
      <Dust2S className="dust2" />
      <Dust3S className="dust3" />
      <Dust4S className="dust4" />
      <Hull src={RocketImg} alt="rocket" className="hull" />
      <Fire src={FireImg} alt="" className="fire" />
      <Dust5S className="dust5" />
      <Dust6S className="dust6" />
      <Dust7S className="dust7" />
      <Dust8S className="dust8" />
    </Rocket>
  </RocketWrapper>
);

const RocketWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px;
  width: 300px;
  height: 300px;
  --animation-type: ease-out;
  --dust1-distance-x: 32.21%;
  --dust1-distance-y: 14.02%;
  --dust2-distance-x: 20.36%;
  --dust2-distance-y: 5.3%;
  --dust3-distance-x: 12.67%;
  --dust3-distance-y: 15.33%;
  --dust4-distance-x: 0.15%;
  --dust4-distance-y: 1.23%;
`;

const getKeyframes = (translate: string, rotateStart?: string) => {
  return `
   0% {
      transform: translate(0, 0) rotateZ(${rotateStart || '0deg'});
      opacity: 1;
    }
    70% {
      transform: translate(${translate});
      opacity: 0;
    }
    95% {
      opacity: 0;
      transform: translate(0, 0) rotateZ(${rotateStart || '0deg'});
    }
    97% {
      transform: rotateZ(${rotateStart || '0deg'});
      opacity: 0.5;
    }
    100% {
      transform: rotateZ(${rotateStart || '0deg'});
      opacity: 1;
    }
`;
};

const Rocket = styled.div<{ animate?: boolean }>`
  width: 200px;
  height: 216px;
  position: relative;

  @keyframes hull {
    0% {
      transform: translateY(0);
    }
    40%,
    70% {
      transform: translateY(-50px);
    }
    95% {
      transform: translateY(0);
    }
  }

  @keyframes dust1 {
    ${getKeyframes('50px, -20px')}
  }
  @keyframes dust5 {
    ${getKeyframes('-50px, -20px', '200deg')}
  }
  @keyframes dust2 {
    ${getKeyframes('55px, -15px')}
  }
  @keyframes dust6 {
    ${getKeyframes('-55px, -15px', '-90deg')}
  }
  @keyframes dust3 {
    ${getKeyframes('60px, 0')}
  }
  @keyframes dust7 {
    ${getKeyframes('-60px, 0')}
  }
  @keyframes dust4 {
    ${getKeyframes('30px, 15px')}
  }
  @keyframes dust8 {
    ${getKeyframes('-30px, 15px', '-90deg')}
  }

  .hull,
  .fire {
    animation-name: ${({ animate }) => (animate ? 'hull' : 'none')};
  }

  .hull,
  .fire,
  .dust1,
  .dust2,
  .dust3,
  .dust4,
  .dust5,
  .dust6,
  .dust7,
  .dust8 {
    animation-duration: ${({ animate }) => (animate ? '4s' : 0)};
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
  }

  .dust5,
  .dust6,
  .dust7,
  .dust8 {
    transform: scaleX(-1);
  }

  ${Array.from({ length: 8 }).map(
    (_, index) => `
      .dust${index + 1} {
        animation-name: dust${index + 1};
      };
  `,
  )}
`;

const Absolute = styled(Image)`
  position: absolute;
`;

const Hull = styled(Absolute)`
  height: 160px;
  width: auto;
  left: 18.46%;
  top: 0;
`;

const Fire = styled(Absolute)`
  height: 42px;
  width: auto;
  left: 41.32%;
  bottom: 10.52%;
`;

const Dust1S = styled(Dust1Png)`
  width: 13px;
  right: var(--dust1-distance-x);
  bottom: var(--dust1-distance-y);
`;
const Dust2S = styled(Dust2Png)`
  width: 29px;
  right: var(--dust2-distance-x);
  bottom: var(--dust2-distance-y);
`;
const Dust3S = styled(Dust3Png)`
  width: 24px;
  right: var(--dust3-distance-x);
  bottom: var(--dust3-distance-y);
`;
const Dust4S = styled(Dust4Png)`
  width: 36px;
  right: var(--dust4-distance-x);
  bottom: var(--dust4-distance-y);
`;

const Dust5S = styled(Dust1S)`
  left: var(--dust1-distance-x);
  bottom: var(--dust1-distance-y);
`;
const Dust6S = styled(Dust2S)`
  left: var(--dust2-distance-x);
  bottom: var(--dust2-distance-y);
`;
const Dust7S = styled(Dust3S)`
  left: var(--dust3-distance-x);
  bottom: var(--dust3-distance-y);
`;
const Dust8S = styled(Dust4S)`
  left: var(--dust4-distance-x);
  bottom: var(--dust4-distance-y);
`;
