import { FC } from 'react';

import { Box, Flex, Heading, Link, Text } from '@chakra-ui/react';
import Image from 'next/image';

import { Button } from '@/components/atoms';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { DynamicSeeMoreBlockFragment } from '@/graphql/generated';
import { getImageAlt } from '@/utils/images';

export const DynamicSeeMoreBlock: FC<DynamicSeeMoreBlockFragment> = ({ header, image, buttonLink, buttonLabel }) => {
  const imageHeight = image?.data?.attributes?.height ?? 0;
  return (
    <SectionWrapper backgroundColor="lime.300" id="see-more-section">
      <SectionContentWrapper zIndex="0" pos="static">
        <Flex flexDir="column" align="center">
          <Heading
            as="h2"
            color="black"
            variant={{
              base: 'component-heading-large/xl',
              sm: 'component-heading-large/2xl',
            }}
            maxWidth={{ base: '300px', sm: '450px', xl: '650px' }}
            mb="52px"
            textAlign="center"
            margin="0 auto"
          >
            {header}
          </Heading>
          <Box pos="relative" h={`${Math.min(imageHeight, 430)}px`} my="26px" w={{ base: '125%', md: '100%' }}>
            <Image
              fill
              style={{ objectFit: 'contain' }}
              src={image?.data?.attributes?.url ?? ''}
              alt={getImageAlt(image?.data?.attributes, header)}
            />
          </Box>

          <Button as={Link} href={buttonLink} w="680px" maxW="100%" margin="0 auto">
            <Text as="span" overflow="hidden" textOverflow="ellipsis" textAlign="left">
              {buttonLabel}
            </Text>
          </Button>
        </Flex>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};
