import { Box, Flex } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';
import Circle from 'images/highlights/circle-1.svg';
import Highlight from 'images/highlights/client-opinions-highlight.png';
import Underline from 'images/highlights/line-3-cross.svg';
import Highlight2 from 'images/highlights/violet-text-highlight.png';
import Image from 'next/image';
import Bomb from 'services/development/timeline/bomb.png';
import Cat from 'services/development/timeline/cat.png';
import Idea from 'services/development/timeline/idea.png';
import Pen from 'services/development/timeline/pen.png';
import Robots from 'services/development/timeline/robots.png';
import Audrey1 from 'services/product-design/faces/audrey1.png';
import Audrey2 from 'services/product-design/faces/audrey2.png';
import Bartosz1 from 'services/product-design/faces/bartosz1.png';
import Bartosz2 from 'services/product-design/faces/bartosz2.png';
import Karolina1 from 'services/product-design/faces/karo1.png';
import Karolina2 from 'services/product-design/faces/karo2.png';
import Klaudia1 from 'services/product-design/faces/klaudia1.png';
import Klaudia2 from 'services/product-design/faces/klaudia2.png';
import Marta1 from 'services/product-design/faces/marta1.png';
import Marta2 from 'services/product-design/faces/marta2.png';
import Natalia1 from 'services/product-design/faces/natalia1.png';
import Natalia2 from 'services/product-design/faces/natalia2.png';
import Seweryn1 from 'services/product-design/faces/seweryn1.png';
import Seweryn2 from 'services/product-design/faces/seweryn2.png';
import Photo1 from 'services/product-design/gallery/1.png';
import Photo2 from 'services/product-design/gallery/2.png';
import Photo3 from 'services/product-design/gallery/3.png';
import Photo4 from 'services/product-design/gallery/4.png';
import Photo5 from 'services/product-design/gallery/5.png';
import Photo6 from 'services/product-design/gallery/6.png';
import Photo7 from 'services/product-design/gallery/7.png';
import Photo8 from 'services/product-design/gallery/8.png';
import Photo9 from 'services/product-design/gallery/9.png';
import Photo10 from 'services/product-design/gallery/10.png';
import DribbbleShot1 from 'services/product-design/scroll-slider/1.png';
import DribbbleShot2 from 'services/product-design/scroll-slider/2.png';
import DribbbleShot3 from 'services/product-design/scroll-slider/3.png';
import DribbbleShot4 from 'services/product-design/scroll-slider/4.png';
import DribbbleShot5 from 'services/product-design/scroll-slider/5.png';
import LatestDribbbleShots from 'services/product-design/slider-badge.png';
import Tada from 'services/product-design/tada.png';
import Rocket from 'services/rocket.png';

import { TextDecorationPng, TextDecorationSvg } from '@/components/functional';
import { AnimatedNumber } from '@/components/functional/AnimatedNumber';
import { RecommendedArticlesSectionProps } from '@/components/sections/common/RecommendedArticlesSection';
import { ScrollSliderProps } from '@/components/sections/services/sections';
import { TimelineSectionProps } from '@/components/sections/services/sections';
import { DevelopmentTechnologyProps } from '@/components/sections/services/sections/development-technology/development-technology-section';

export const technologySection: DevelopmentTechnologyProps = {
  header: (
    <>
      Wybór{' '}
      <TextDecorationSvg
        variant="circle"
        color="lime"
        line={Circle}
        h="150%"
        top="-21%"
        left="-7%"
        scaleX={1.075}
        scaleY={1.2}
        once={true}
        strokeWidth={2}
        zIndex={-1}
      >
        odpowiedniej
      </TextDecorationSvg>{' '}
      technologii tworzenia
      <br /> stron{' '}
      <TextDecorationSvg variant="line" color="violet" line={Underline} top="60%" zIndex={-1}>
        internetowych
      </TextDecorationSvg>
    </>
  ),
  subheader:
    'Deweloperzy SDH codziennie poruszają się po szybko zmieniającej się branży technologicznej i są gotowi zagłębić się w potrzeby każdego produktu i zasugerować odpowiednie narzędzia programistyczne\n' +
    ' potrzeby każdego produktu i zasugerować odpowiednie narzędzia programistyczne',
};

export const timelineSection: Omit<TimelineSectionProps, 'gridColumns'> = {
  items: [
    {
      number: '01',
      icon: Idea,
      header: 'Warsztaty i Koncepcja',
      description:
        'Poświęcenie czasu na udoskonalenie propozycji wartości i zrozumienie użytkownika jest konieczne, aby zapewnić solidne fundamenty dla koncepcji biznesowej.',
    },
    {
      number: '02',
      icon: Pen,
      header: 'Projektowanie Produktu',
      description:
        'Kilka intensywnych dni pracy skutkuje zdefiniowaniem persony, ulepszonym przekazem wiedzy w zespole i co najważniejsze - cyklem życia produktu w skrócie z planami jego rozwoju.',
    },
    {
      number: '03',
      icon: Bomb,
      header: 'Testy Użyteczności',
      description:
        'Koncepcja i prototyp są testowane na odpowiednich personach, zbierając opinie do dostosowań UX i UI.',
    },
    {
      number: '04',
      icon: Robots,
      header: 'Rozwój',
      description:
        'Łączymy podejście rozwoju sterowanego testami (TDD) z podejściem zwinnym, aby zapewnić płynny proces rozwoju, który szybko reaguje na potrzeby rynku.',
    },
    {
      number: '05',
      icon: Cat,
      header: 'Utrzymanie',
      description:
        'Zawsze obserwujemy wydajność produktu - technologie się starzeją, zagrożenia bezpieczeństwa trzeba łagodzić, a Twoje rozwiązanie musi być gotowe na radzenie sobie z ogromną liczbą użytkowników.',
    },
    {
      number: '06',
      icon: Rocket,
      header: 'Dalszy Rozwój',
      description:
        'Obserwacja interakcji rzeczywistych użytkowników z produktem ułatwia planowanie kolejnych kroków rozwoju biznesu, priorytetyzację nowych funkcji i budowę długoterminowej mapy drogowej.',
    },
  ],
  header: (
    <>
      Jak wubudujemy Twój{' '}
      <TextDecorationPng image={Highlight2} scaleX={1.04}>
        produkt
      </TextDecorationPng>
    </>
  ),
};

export const recommendedArticlesSection: Omit<RecommendedArticlesSectionProps, 'slug'> = {
  tags: [
    'Agile',
    'Scrum',
    'Product management',
    'Software architecture',
    'Product roadmap',
    'Quality Assurance',
    'Quality control',
    'Digital products',
    'Digital transformation',
    'MVP',
    'Green tech',
    'Cloud computing',
    'Software development',
    'Software testing',
    'Computer programming',
    'Computer security',
    'Data science',
    'Artificial intelligence',
    'Information security',
    'Infrastructure',
    'Front-end development',
    'Back-end development',
    'React',
    'Node.js',
    'Next.js',
    'Nest.js',
    'Strapi',
    'Team',
    'Mobile',
    'React Native',
  ],
  header: (
    <>
      Więcej{' '}
      <TextDecorationPng image={Highlight} scaleX={1.06}>
        na blogu
      </TextDecorationPng>{' '}
      tutaj...
    </>
  ),
};

export const facesSection = {
  items: [
    {
      name: 'Bartosz Storożak',
      position: 'Design Team Leader',
      image1: Bartosz1,
      image2: Bartosz2,
      comment: 'In his free time, Bartek enjoys traveling and, for example, bathing elephants 🙃',
    },
    {
      name: 'Karolina Diłaj',
      position: 'Senior Product Designer',
      image1: Karolina1,
      image2: Karolina2,
      comment: 'On ice Karolina transforms into Clumsy Champion of Hockey Skating 😵‍💫',
    },
    {
      name: 'Klaudia Koriat',
      position: 'Senior Product Designer',
      image1: Klaudia1,
      image2: Klaudia2,
      comment:
        'After work Klaudia is awkwardly wobbling on the saddle or feeding chickens while sipping on a glass of fine wine 🍷',
    },
    {
      name: 'Natalia Machnicka',
      position: 'Senior Product Designer',
      image1: Natalia1,
      image2: Natalia2,
      comment:
        'Natalia enjoys travelling, working with dogs (especially retrievers) and caring about her home garden 🌺',
    },
    {
      name: 'Audrey Alves-Cunka',
      position: 'Product Team Leader',
      image1: Audrey1,
      image2: Audrey2,
      comment:
        'Audrey loves outdoor activities, discovering new places and meet new people. Recently her free time is a lot around swimming, cycling and running as she trains triathlon. 🏃‍♀️🏊🏻‍♀️🚴🏻‍♀️',
    },
    {
      name: 'Seweryn Olek',
      position: 'Product Manager',
      image1: Seweryn1,
      image2: Seweryn2,
      comment: 'In his free time, he likes to imagine that Sons of Anarchy didn’t end after the 7 seasons. 🏍️',
    },
    {
      name: 'Marta Przyłęcka',
      position: 'Product Manager',
      image1: Marta1,
      image2: Marta2,
      comment:
        'Marta spends her free time appreciating good food, reconnecting with nature, and hitting the tennis court. 🤸‍♀️',
    },
  ],
  header: (
    <>
      Meet our <TextDecorationPng image={Highlight}>Design Team</TextDecorationPng>
    </>
  ),
};

export const gallerySection = {
  textInside: (
    <Text
      variant={{
        base: 'text-md/lineHeight-6/font-normal',
        sm: 'text-xl/lineHeight-7/font-normal',
        md: 'text-4xl/lineHeight-10/font-normal',
      }}
      color="pruple.500"
      as="div"
    >
      <Flex flexDir="column" alignItems="center" position="relative">
        <Box as={Image} src={Tada} alt="" position="absolute" left="-50%" top="10%" height="auto" width="55%" />
        <Box
          as={Image}
          src={Tada}
          alt=""
          position="absolute"
          right="-50%"
          top="10%"
          height="auto"
          width="55%"
          transform="scaleX(-1)"
        />
        <Box>Together We Have</Box>
        <Text
          fontSize={{
            base: 32,
            sm: 48,
            md: 80,
          }}
          lineHeight="100%"
          color="violet.500"
          fontWeight={600}
          py={{
            base: 0,
            sm: 4,
            md: 8,
          }}
          as="span"
        >
          <AnimatedNumber durationS={3} finalValue={73} startingValue={0} />
          <Text display="inline" color="lime.500" as="span">
            +
          </Text>
        </Text>
        <Box>Years of Experience</Box>
      </Flex>
    </Text>
  ),
  photos: [Photo1, Photo2, Photo3, Photo4, Photo5, Photo6, Photo7, Photo8, Photo9, Photo10],
};

export const scrollSliderSection: ScrollSliderProps = {
  badgeImage: LatestDribbbleShots,
  items: [
    {
      slideImage: DribbbleShot1,
      url: 'https://dribbble.com/shots/11666372-Medical-appointments-Booking-App',
      imageAlt: 'Medical appointments Booking App',
    },
    {
      slideImage: DribbbleShot2,
      url: 'https://dribbble.com/shots/6948477-Relevant-News-App-UI',
      imageAlt: 'Relevant News App UI',
    },
    {
      slideImage: DribbbleShot3,
      url: 'https://dribbble.com/shots/6938200-Courso-Dashboard',
      imageAlt: 'Courso Dashboard',
    },
    {
      slideImage: DribbbleShot4,
      url: 'https://dribbble.com/shots/6194991-Sport-App',
      imageAlt: 'Sport App',
    },
    {
      slideImage: DribbbleShot5,
      url: 'https://dribbble.com/shots/6344386-N-FFE-Banking',
      imageAlt: 'N-FFE Banking',
    },
  ],
  badgeText: 'Last Dribbble Shots',
};
