import { Avatar, Box, Flex, Text } from '@chakra-ui/react';
import Quote from 'images/icons/quote.svg';

import { StrapiImage } from '@/components/atoms';
import { ParsedHTMLText, TextHighlightSvgStatic } from '@/components/functional';
import { DynamicClientOpinionsFragment } from '@/graphql/generated';

interface CardProps {
  card: DynamicClientOpinionsFragment['opinions']['opinions'][0];
}

export const Card = ({ card }: CardProps) => {
  const { clientAvatar, clientName, clientDescription, clientDescriptionSecondLine, clientLogo, content } = card!;

  return (
    <Flex
      as="article"
      flexDir="column"
      alignItems="center"
      h="100%"
      maxW={{ base: '280px', xs: '350px', sm: '410px' }}
      minW={{ base: '280px', xs: '350px', sm: '410px' }}
      px="30px"
      pt="80px"
      pb="86px"
      borderRadius="3xl"
      color="purple.500"
      bgColor="white"
      justify="space-between"
    >
      <Flex flexDir="column" alignItems="center" height="100%" maxW="100%">
        <Flex mb={{ base: 5, md: 8 }} h="65px" pos="relative" alignItems="center">
          <StrapiImage image={clientLogo} style={{ objectFit: 'contain', maxHeight: '100%' }} />
        </Flex>

        <Box width="23px" mb={{ base: 4, md: 6 }}>
          <Quote width="100%" height="100%" />
        </Box>
        <Flex flex={1} mb={{ base: 6, sm: 12 }} w="100%" sx={{ '& span': { maxWidth: '100%' } }}>
          <TextHighlightSvgStatic
            backgroundImage="url('/assets/images/highlights/client-opinions-card-highlight.svg')"
            enlargmentX="2px"
          >
            <Text
              display="block"
              as="span"
              variant={{
                base: 'text-sm/lineHeight-5/font-normal',
                sm: 'text-md/lineHeight-6/font-normal',
              }}
              color="purple.400"
              textAlign="center"
            >
              <ParsedHTMLText content={content} />
            </Text>
          </TextHighlightSvgStatic>
        </Flex>
      </Flex>

      <Flex flexDir="column" alignItems="center" maxW="100%">
        <Avatar
          name={clientAvatar?.data?.attributes?.alternativeText || 'client avatar'}
          src={clientAvatar?.data?.attributes?.url as string}
          width={{ base: 16, md: 20 }}
          height={{ base: 16, md: 20 }}
          mb={{ base: 3.5, md: 5 }}
        />
        <Text variant={'text-lg/lineHeight-7/font-semibold'} maxW="100%">
          {clientName}
        </Text>
        <Text variant="text-xs/lineHeight-4/font-semibold" color="purple.400" maxW="100%">
          {clientDescription}
        </Text>

        <Text
          variant="text-xs/lineHeight-4/font-semibold"
          color="purple.400"
          maxW="100%"
          visibility={clientDescriptionSecondLine ? 'visible' : 'hidden'}
          aria-hidden={clientDescriptionSecondLine ? 'false' : 'true'}
        >
          {clientDescriptionSecondLine ?? clientDescription}
        </Text>
      </Flex>
    </Flex>
  );
};
