import { FC } from 'react';

import { DynamicTextWithDecoration } from '@/components/sections/dynamic-blocks/DynamicTextWithDecoration';
import { TeamFaces } from '@/components/sections/services/sections';
import { DynamicFacesFragment } from '@/graphql/generated';
import { parseStrapiImage } from '@/utils/images';
import { parseTextDecoration } from '@/utils/text-decorations';

import { FacesSectionItem } from '../../services/typings';

export const DynamicFaces: FC<DynamicFacesFragment> = ({ header, items, decoratedTextImages }) => {
  const parsedHeader = (
    <DynamicTextWithDecoration {...parseTextDecoration(decoratedTextImages)}>{header}</DynamicTextWithDecoration>
  );

  let parsedItems = [] as FacesSectionItem[];
  items?.forEach((item) => {
    if (item) {
      const { name, comment, position } = item;
      parsedItems.push({
        name,
        comment,
        position,
        image1: parseStrapiImage(item.image1),
        image2: parseStrapiImage(item.image2),
      });
    }
  });

  return parsedItems.length ? <TeamFaces header={parsedHeader} items={parsedItems} /> : null;
};
