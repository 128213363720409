import { Box } from '@chakra-ui/react';
import Image from 'next/image';

import { LetsMeetDoodle } from '@/components/sections/home/LetsMeet/Doodle/Doodle';
import { getImageAlt, getImageSrcWithBlurhash } from '@/utils/images';
import { MemberProps } from '@/utils/models/lets-meet-section-member-props';

import { RevealedMember } from '../RevealedMember';
import { memberCircleStyles } from './member-circle-styles';

const IMAGE_OFFSET = 3; // to change the order of images on the circle

export const MemberCircle = ({ avatar, doodle, chatBubble, index, setRevealedItem, revealedItem }: MemberProps) => {
  const isItemRevealed = revealedItem === index;

  return (
    <Box
      m="calc(-0.5 * var(--imageSize))"
      maxW="var(--imageSize)"
      maxH="var(--imageSize)"
      position="absolute"
      top="50%"
      left="50%"
      transform={`rotate(var(--rotation))
      translate(var(--radius))
      rotate(calc(-1 * var(--rotation)))`}
      cursor="pointer"
      zIndex={isItemRevealed ? '2' : 'auto'}
      onClick={() => setRevealedItem(isItemRevealed ? null : index)}
      onMouseEnter={() => setRevealedItem(index)}
      onMouseLeave={() => setRevealedItem(null)}
      sx={{
        '--rotation': `calc(${index + IMAGE_OFFSET}*1turn/var(--images))`, // images rotation value
        ...memberCircleStyles,
      }}
    >
      <Image
        {...getImageSrcWithBlurhash(doodle.data?.attributes)}
        alt={getImageAlt(doodle.data?.attributes)}
        width={130}
        height={130}
        placeholder="empty"
        loading="eager"
        style={{ maxWidth: 'fit-content', width: '130px', height: '130px' }}
      />
      <RevealedMember avatar={avatar} chatBubble={chatBubble} isRevealed={isItemRevealed} />
      {index === 1 && <LetsMeetDoodle />}
    </Box>
  );
};
