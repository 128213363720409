import { Flex, Tag } from '@chakra-ui/react';

import { ArticleAuthors } from '@/components/molecules/ArticleAuthors/ArticleAuthors';
import { UserFragment } from '@/graphql/generated';
import { ArticleTagsFragment, Maybe } from '@/graphql/generated';

import { ArticleTableOfContent } from '../ArticleTableOfContent';

type AboutArticleProps = {
  authors: UserFragment[];
  minutesOfRead: number;
  createdAt: DateLike;
  content: string;
  tags?: Maybe<ArticleTagsFragment>;
};

export const AboutArticle = ({ authors, minutesOfRead, tags, createdAt, content }: AboutArticleProps) => {
  return (
    <Flex flexDir="column" gap="4">
      <ArticleAuthors authors={authors} minutesOfRead={minutesOfRead} createdAt={createdAt} />
      <Flex mb="1" gap="2" wrap="wrap">
        {tags?.data?.map((tag) => (
          <Tag size="xl" variant="article" key={tag.id}>
            {tag.attributes?.name}
          </Tag>
        ))}
      </Flex>
      <ArticleTableOfContent content={content} />
    </Flex>
  );
};
