import { useMutation } from '@tanstack/react-query';

import { MAIL_PATH } from '@/utils/constants';

export const useSendEmail = () => {
  return useMutation({
    mutationFn: (formData: FormData) => {
      return fetch(MAIL_PATH, {
        method: 'POST',
        body: formData,
      });
    },
  });
};
