import { useRef } from 'react';

import { Box, Heading, Text } from '@chakra-ui/react';
import { useInView } from 'framer-motion';
import ArrowIcon from 'images/icons/arrow-right.svg';
import { useTranslation } from 'next-i18next';

import { Button } from '@/components/atoms';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { LetsMeetFragment } from '@/graphql/generated';
import { LOCALES } from '@/utils/constants';
import { GeneralPath } from '@/utils/models';

import { LetsMeetBackground } from './LetsMeetBackground';
import { Members } from './Members';

interface LetsMeetProps {
  data: LetsMeetFragment;
}
export const LetsMeet = ({ data }: LetsMeetProps) => {
  const containerRef = useRef(null);
  const isInView = useInView(containerRef);
  const { t } = useTranslation(LOCALES.HOME);

  const { __typename, ...teamMembers } = data;

  return (
    <SectionWrapper id="lets-meet-section">
      <SectionContentWrapper
        display={{ lg: 'flex' }}
        gap={{ lg: 28 }}
        paddingY={undefined}
        pt={{ base: 16, lg: 24 }}
        pb={{ base: 16, sm: 20 }}
        position="relative"
        ref={containerRef}
      >
        <LetsMeetBackground isInView={isInView} />
        <Box w={{ sm: '410px' }} order={{ lg: 2 }}>
          <Heading
            as="h2"
            variant={{
              base: 'component-heading-large/xl',
              sm: 'component-heading-large/3xl',
            }}
            color="purple.500"
          >
            {t('letsMeet.title')}
          </Heading>
          <Text
            variant={{
              base: 'text-md/lineHeight-6/font-normal',
              sm: 'text-2xl/lineHeight-8/font-normal',
            }}
            pt={{ base: 6 }}
            color="purple.500"
          >
            {t('letsMeet.description')}
          </Text>
          <Button
            variant="light"
            label={t('letsMeet.button') || ''}
            rightIcon={<ArrowIcon width="12px" />}
            mt={{ base: 8 }}
            w={{ sm: '178px' }}
            url={GeneralPath.CONTACT}
            size="lg"
          />
        </Box>
        <Members membersData={teamMembers} />
      </SectionContentWrapper>
    </SectionWrapper>
  );
};
