import { CSSProperties } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';
import * as CSS from 'csstype';
import BadgeIcon from 'images/icons/badge.png';
import Image from 'next/image';

import { AnimatedBadge, cardFrameCommonStyles } from '@/components/sections/home';

interface StatisticCardProps {
  value: string;
  label: string;
  showBadge: boolean;
  willShowBadge?: boolean;
  style?: CSSProperties | undefined;
  animation?: CSS.Property.Animation | undefined;
}

export const StatisticCard = ({ value, label, showBadge, style, animation, willShowBadge }: StatisticCardProps) => {
  return (
    <Box as="article" className="staticCardContainer" w="fit-content" style={style} animation={animation}>
      <Flex
        className="statisticCard"
        position="relative"
        alignItems={{ base: 'flex-end', sm: 'center' }}
        py={{ base: 5, sm: 6 }}
        pl={{ base: 3, sm: 5 }}
        pr={{ base: 3 }}
        m={{ sm: '0 auto' }}
        sx={cardFrameCommonStyles}
        backgroundColor="white"
      >
        <Box>
          <Text
            fontWeight="600"
            fontSize={{
              base: '36px',
              sm: '46px',
            }}
            lineHeight={{
              base: '38px',
              sm: '48px',
            }}
            color="violet.500"
          >
            {value}
          </Text>
        </Box>
        <Flex pl={{ base: 5 }} alignSelf="center">
          <Text
            variant={{
              base: 'text-sm/lineHeight-5/font-normal',
              xl: 'text-md/lineHeight-6/font-normal',
            }}
            color="purple.400"
            textTransform="capitalize"
          >
            {label}
          </Text>
        </Flex>
        <AnimatedBadge
          as={Image}
          src={BadgeIcon}
          alt="award badge"
          w="auto"
          h={{ base: '93px', sm: '169px' }}
          position="absolute"
          right={{ base: 5, sm: 0 }}
          top={{ base: 2 }}
          showBadge={showBadge}
          willShowBadge={willShowBadge}
        />
      </Flex>
    </Box>
  );
};
