import React, { FC } from 'react';

import { DynamicInfoWithPhotoFragment } from '@/graphql/generated';
import { getImageSrcFromAttributes } from '@/utils/images';

import { ContactPersonSection } from '../../services/sections';
import { DynamicTextWithDecoration } from '../DynamicTextWithDecoration';

export const DynamicInfoWithPhotoBlock: FC<DynamicInfoWithPhotoFragment> = ({
  header,
  message,
  photo,
  buttonLink,
  buttonCaption,
}) => {
  return (
    <ContactPersonSection
      header={<DynamicTextWithDecoration key="header">{header}</DynamicTextWithDecoration>}
      image={getImageSrcFromAttributes(photo.data?.attributes, 'medium')}
      paragraphs={[<DynamicTextWithDecoration key="msg">{message}</DynamicTextWithDecoration>]}
      buttonUrl={buttonLink}
      buttonText={buttonCaption}
    />
  );
};
