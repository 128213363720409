import { useRef } from 'react';

import { Text } from '@chakra-ui/react';
import { useInView } from 'framer-motion';
import { useTranslation } from 'next-i18next';

import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { TechStackFragment } from '@/graphql/generated';
import { LOCALES } from '@/utils/constants';

import { TechStackBackground } from './TechStackBackground';
import { Technologies } from './Technologies';

interface TechStackProps {
  data: TechStackFragment;
}
export const TechStack = ({ data }: TechStackProps) => {
  const containerRef = useRef(null);
  const isInView = useInView(containerRef);
  const { t } = useTranslation(LOCALES.HOME);

  return (
    <SectionWrapper pt={{ md: 48 }} pb={{ md: 72 }}>
      <SectionContentWrapper
        display={{ md: 'flex' }}
        justifyContent={{ md: 'space-between' }}
        alignItems={{ md: 'center' }}
        ref={containerRef}
      >
        <TechStackBackground isInView={isInView} />
        <Text
          color="purple.500"
          variant={{
            base: 'component-heading-large/xl',
            sm: 'component-heading-large/2xl',
            lg: 'component-heading-large/3xl',
          }}
          maxW="343px"
        >
          {t('techStack.title')}
        </Text>
        <Technologies technologiesRows={data.row} />
      </SectionContentWrapper>
    </SectionWrapper>
  );
};
