import React, { FC } from 'react';

import {
  BannerBlockFragment,
  CaseStudiesShowcaseFragment,
  DynamicCaseStudiesPreviewFragment,
  DynamicChartSectionFragment,
  DynamicClientOpinionsFragment,
  DynamicConstantFragment,
  DynamicDarkInfoDividerFragment,
  DynamicDividerWithPhotosFragment,
  DynamicFacesFragment,
  DynamicFaqFragment,
  DynamicFeatureListFragment,
  DynamicGallerySectionFragment,
  DynamicHeroFragment,
  DynamicHeroWithContactFragment,
  DynamicHistorySectionFragment,
  DynamicInfoGridFragment,
  DynamicInfoWithPhotoFragment,
  DynamicLeadershipFragment,
  DynamicLogosFragment,
  DynamicMediaFragment,
  DynamicPartnersBlockFragment,
  DynamicPointsWithPhotosFragment,
  DynamicRecommendedArticlesFragment,
  DynamicRichTextFragment,
  DynamicScrollSliderFragment,
  DynamicSeeMoreBlockFragment,
  DynamicSliderWithPointsFragment,
  DynamicStatisticsFragment,
  DynamicTeamInvolvedFragment,
  DynamicTeamInvolvedWithHoverFragment,
  DynamicTestimonialFragment,
  DynamicTextWithPhotosFragment,
  DynamicVideoBannerFragment,
  HowWeWorkFragment,
  LetsBuildBlockFragment,
  NewsletterBlockFragment,
  StandaloneContactFormFragment,
} from '@/graphql/generated';

import { DynamicChartSection } from '../DyamicChartSection/DynamicChartSection';
import { DynamicBannerBlock } from '../DynamicBannerSection';
import { DynamicCaseStudiesPreview } from '../DynamicCaseStudiesPreview';
import { CaseStudiesShowcase } from '../DynamicCaseStudiesShowcase/CaseStudiesShowcase';
import { DynamicClientOpinions } from '../DynamicClientOpinions/DynamicClientOpinions';
import { DynamicConstantBlock } from '../DynamicConstantBlock';
import { DynamicDarkInfoDividerBlock } from '../DynamicDarkInfoDividerBlock';
import { DynamicDividerWithPhotos } from '../DynamicDividerWithPhotos';
import { DynamicFaces } from '../DynamicFaces';
import { DynamicFaqBlock } from '../DynamicFaqBlock';
import { DynamicFeatureList } from '../DynamicFeatureList';
import { DynamicGallerySection } from '../DynamicGallerySection';
import { DynamicHeroBlock } from '../DynamicHeroBlock';
import { DynamicHeroWithContactBlock } from '../DynamicHeroWithContactBlock';
import { DynamicHistorySection } from '../DynamicHistorySection/DynamicHistorySection';
import { DynamicInfoGridBlock } from '../DynamicInfoGridBlock';
import { DynamicInfoWithPhotoBlock } from '../DynamicInfoWithPhotoBlock';
import { DynamicLeadershipBlock } from '../DynamicLeadershipBlock/DynamicLeadershipBlock';
import { DynamicLogosBlock } from '../DynamicLogosBlock';
import { DynamicMedia } from '../DynamicMedia';
import { DynamicNewsletterBlock } from '../DynamicNewsletterBlock';
import { DynamicPartnersSection } from '../DynamicPartnersSection/DynamicPartnersSection';
import { DynamicPointsWithPhotosBlock } from '../DynamicPointsWithPhotosBlock';
import { DynamicRichTextBlock } from '../DynamicRichTextBlock';
import { DynamicScrollSlider } from '../DynamicScrollSlider';
import { DynamicSeeMoreBlock } from '../DynamicSeeMoreBlock';
import { DynamicSliderWithPoints } from '../DynamicSliderWithPoints';
import { DynamicStatisticsBlock } from '../DynamicStatistics/DynamicStatistics';
import { DynamicTeamInvolvedHover } from '../DynamicTeamInvolvedHover/DynamicTeamInvolvedHover';
import { DynamicTeamInvolvedSection } from '../DynamicTeamInvolvedSection/DynamicTeamInvolvedSection';
import { DynamicTestimonialBlock } from '../DynamicTestimonialBlock';
import { DynamicTextWithPhotos } from '../DynamicTextWithPhotos';
import { DynamicVideoBanner } from '../DynamicVideoBanner';
import { HowWeWork } from '../HowWeWork';
import { DynamicLetsBuildBlock } from '../LetsBuildSomething';
import { RecommendedArticles } from '../RecommendedArticles';
import { StandaloneContactForm } from '../StandaloneContactForm';

type DynamicBlockSelectorProps = {
  typename?: string;
};

export const DynamicBlockSelector: FC<DynamicBlockSelectorProps> = ({ typename, ...data }) => {
  switch (typename) {
    case 'ComponentDynamicBlocksDynamicHeroBlock':
      return <DynamicHeroBlock {...(data as DynamicHeroFragment)} />;
    case 'ComponentDynamicBlocksDynamicTestimonialBlock':
      return <DynamicTestimonialBlock {...(data as DynamicTestimonialFragment)} />;
    case 'ComponentDynamicBlocksDynamicConstantBlock':
      return <DynamicConstantBlock {...(data as DynamicConstantFragment)} />;
    case 'ComponentDynamicBlocksDynamicFaqBlock':
      return <DynamicFaqBlock {...(data as DynamicFaqFragment)} />;
    case 'ComponentDynamicBlocksDynamicPointsWithPhotosBlock':
      return <DynamicPointsWithPhotosBlock {...(data as DynamicPointsWithPhotosFragment)} />;
    case 'ComponentDynamicBlocksDynamicDarkInfoDividerBlock':
      return <DynamicDarkInfoDividerBlock {...(data as DynamicDarkInfoDividerFragment)} />;
    case 'ComponentDynamicBlocksDynamicInfoWithPhotoBlock':
      return <DynamicInfoWithPhotoBlock {...(data as DynamicInfoWithPhotoFragment)} />;
    case 'ComponentDynamicBlocksDynamicLogosBlock':
      return <DynamicLogosBlock {...(data as DynamicLogosFragment)} />;
    case 'ComponentDynamicBlocksDynamicRichTextBlock':
      return <DynamicRichTextBlock {...(data as DynamicRichTextFragment)} />;
    case 'ComponentDynamicBlocksDynamicInfoGridBlock':
      return <DynamicInfoGridBlock {...(data as DynamicInfoGridFragment)} />;
    case 'ComponentDynamicBlocksDynamicSliderPointsBlock':
      return <DynamicSliderWithPoints {...(data as DynamicSliderWithPointsFragment)} />;
    case 'ComponentDynamicBlocksDynamicDividerWithPhotosBlock':
      return <DynamicDividerWithPhotos {...(data as DynamicDividerWithPhotosFragment)} />;
    case 'ComponentDynamicBlocksDynamicGallerySection':
      return <DynamicGallerySection {...(data as DynamicGallerySectionFragment)} />;
    case 'ComponentDynamicBlocksDynamicTextWithPhotos':
      return <DynamicTextWithPhotos {...(data as DynamicTextWithPhotosFragment)} />;
    case 'ComponentDynamicBlocksDynamicChartSection':
      return <DynamicChartSection {...(data as DynamicChartSectionFragment)} />;
    case 'ComponentDynamicBlocksDynamicHistorySection':
      return <DynamicHistorySection {...(data as DynamicHistorySectionFragment)} />;
    case 'ComponentDynamicBlocksDynamicTeamInvolved':
      return <DynamicTeamInvolvedSection {...(data as DynamicTeamInvolvedFragment)} />;
    case 'ComponentDynamicBlocksDynamicFeatureListBlock':
      return <DynamicFeatureList {...(data as DynamicFeatureListFragment)} />;
    case 'ComponentDynamicBlocksDynamicHeroWithContactBlock':
      return <DynamicHeroWithContactBlock {...(data as DynamicHeroWithContactFragment)} />;
    case 'ComponentDynamicBlocksDynamicClientOpinions':
      return <DynamicClientOpinions {...(data as DynamicClientOpinionsFragment)} />;
    case 'ComponentDynamicBlocksDynamicSeeMoreBlock':
      return <DynamicSeeMoreBlock {...(data as DynamicSeeMoreBlockFragment)} />;
    case 'ComponentDynamicBlocksDynamicPartners':
      return <DynamicPartnersSection {...(data as DynamicPartnersBlockFragment)} />;
    case 'ComponentDynamicBlocksDynamicLeadership':
      return <DynamicLeadershipBlock {...(data as DynamicLeadershipFragment)} />;
    case 'ComponentDynamicBlocksDynamicStatistics':
      return <DynamicStatisticsBlock {...(data as DynamicStatisticsFragment)} />;
    case 'ComponentDynamicBlocksDynamicCaseStudiesPreview':
      return <DynamicCaseStudiesPreview {...(data as DynamicCaseStudiesPreviewFragment)} />;
    case 'ComponentDynamicBlocksDynamicVideoBanner':
      return <DynamicVideoBanner {...(data as DynamicVideoBannerFragment)} />;
    case 'ComponentDynamicBlocksDynamicTeamInvolvedWithHover':
      return <DynamicTeamInvolvedHover {...(data as DynamicTeamInvolvedWithHoverFragment)} />;
    case 'ComponentDynamicBlocksHowWeWork':
      return <HowWeWork {...(data as HowWeWorkFragment)} />;
    case 'ComponentDynamicBlocksDynamicScrollSlider':
      return <DynamicScrollSlider {...(data as DynamicScrollSliderFragment)} />;
    case 'ComponentDynamicBlocksDynamicMedia':
      return <DynamicMedia {...(data as DynamicMediaFragment)} />;
    case 'ComponentDynamicBlocksStandaloneContactForm':
      return <StandaloneContactForm {...(data as StandaloneContactFormFragment)} />;
    case 'ComponentDynamicBlocksDynamicFaces':
      return <DynamicFaces {...(data as DynamicFacesFragment)} />;
    case 'ComponentDynamicBlocksRecommendedArticles':
      return <RecommendedArticles {...(data as DynamicRecommendedArticlesFragment)} />;
    case 'ComponentDynamicBlocksCaseStudiesShowcase':
      return <CaseStudiesShowcase {...(data as CaseStudiesShowcaseFragment)} />;
    case 'ComponentDynamicBlocksNewsletterBlock':
      return <DynamicNewsletterBlock {...(data as NewsletterBlockFragment)} />;
    case 'ComponentDynamicBlocksLetsBuildBlock':
      return <DynamicLetsBuildBlock {...(data as LetsBuildBlockFragment)} />;
    case 'ComponentDynamicBlocksBannerSection':
      return <DynamicBannerBlock {...(data as BannerBlockFragment)} />;

    default:
      return null;
  }
};
