import { SystemStyleObject } from '@chakra-ui/react';

export const technologiesStyles: SystemStyleObject = {
  maskImage: 'linear-gradient(270deg, rgba(251, 248, 240, 0.00) 0%, #FBF8F0 35%)',

  '> div:nth-of-type(1)': {
    lg: {
      transform: 'translateX(50px)',
    },
  },
  '> div:nth-of-type(2)': {
    lg: {
      transform: 'translateX(90px)',
    },
  },
  '> div:nth-of-type(4)': {
    lg: {
      transform: 'translateX(130px)',
    },
  },
  '> div:nth-of-type(5)': {
    lg: {
      transform: 'translateX(20px)',
    },
  },
  '> div:nth-of-type(7)': {
    lg: {
      transform: 'translateX(70px)',
    },
  },
};
