import React, { useState } from 'react';

import { Box, ChakraStyledOptions, Flex, Text, useMediaQuery, useQuery } from '@chakra-ui/react';
import Image from 'next/image';
import SeeHiddenDesktop from 'services/mvp/see-whats-hidden-desktop.png';
import SeeHiddenTablet from 'services/mvp/see-whats-hidden-tablet.png';

import { CommentBubble } from '@/components/atoms/CommentBubble/CommentBubble';
import { DynamicTeamInvolvedWithHoverFragment } from '@/graphql/generated';

export type FacesItemProps = {
  isLast: boolean;
  isMobile?: boolean;
  showExtraBubbles?: boolean;
  style?: ChakraStyledOptions;
} & DynamicTeamInvolvedWithHoverFragment['items'][0];

export const FacesItem = ({
  afterImage,
  hoverImage,
  imageCaptionFirstLine,
  imageCaptionSecondLine,
  isLast,
  isMobile,
  showExtraBubbles = true,
  comment,
  style,
}: FacesItemProps) => {
  const [isAboveSM, isAboveLG] = useMediaQuery([useQuery({ above: 'sm' }), useQuery({ above: 'lg' })]);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Flex
      flexDir="column"
      alignItems="center"
      position="relative"
      onMouseEnter={() => (isMobile ? null : setIsHovered(true))}
      onMouseLeave={() => (isMobile ? null : setIsHovered(false))}
      onClick={() => setIsHovered(!isHovered)}
      sx={style}
    >
      <Box
        height="170px"
        width="170px"
        transform={`scale(${isHovered ? 0 : 1})`}
        transition="transform .2s"
        mb={6}
        className="initial-image"
      >
        <Image src={hoverImage.data?.attributes?.url || ''} alt={imageCaptionFirstLine} fill objectFit="contain" />
      </Box>
      <Box
        left="50%"
        top="0"
        transform={`translateX(-50%) scale(${isHovered ? 1 : 0})`}
        transition="transform .2s"
        height="180px"
        width="170px"
        borderRadius="100%"
        className="hover-image"
        position="absolute"
      >
        <Image
          src={afterImage.data?.attributes?.url || ''}
          alt={imageCaptionSecondLine || ''}
          fill
          objectFit="contain"
        />
      </Box>
      <Text color="purple.500" variant="text-md/lineHeight-6/font-semibold" mb={1}>
        {imageCaptionFirstLine}
      </Text>
      <Text color="purple.400" variant="text-md/lineHeight-6/font-normal">
        {imageCaptionSecondLine}
      </Text>

      <CommentBubble
        personRole={imageCaptionSecondLine || ''}
        message={comment}
        name={imageCaptionFirstLine}
        isRevealed={isHovered}
        animationDuration=".3s"
        zIndex={99}
        top={0}
        left="50%"
        transform="translate(-50%, -120%)"
        transformOrigin="left bottom"
        position="absolute"
        pointerEvents="none"
        minWidth="250px"
      />

      {showExtraBubbles && (
        <>
          {isLast && isAboveSM && !isAboveLG && (
            <Box
              w="146px"
              as={Image}
              src={SeeHiddenTablet}
              alt="see whats hidden"
              position="absolute"
              bottom="-64%"
              right="-16%"
            />
          )}

          {isLast && isAboveLG && (
            <Box
              w="146px"
              as={Image}
              src={SeeHiddenDesktop}
              alt="see whats hidden"
              position="absolute"
              top="-64%"
              right="0"
            />
          )}
        </>
      )}
    </Flex>
  );
};
