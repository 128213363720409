import React from 'react';

import { Box, Text } from '@chakra-ui/react';
import TextBubble from 'images/highlights/text-bubble-with-arrow.svg';
import { useTranslation } from 'next-i18next';

import { LOCALES } from '@/utils/constants';

export const LetsMeetDoodle = () => {
  const { t } = useTranslation(LOCALES.HOME);

  return (
    <Box
      position="absolute"
      sx={{
        '& svg': {
          width: '100%',
          height: '100%',
          transform: {
            base: 'scaleX(-1)',
            sm: 'none',
            lg: 'scaleX(-1)',
          },
          zIndex: -1,
        },
      }}
      w={{
        base: '130px',
        sm: '200px',
      }}
      h={{
        base: '130px',
        sm: '170px',
      }}
      top={{
        base: '-160px',
        sm: '-120px',
        lg: '-60px',
      }}
      left={{
        base: '-10%',
        xs: '-60%',
        sm: '120%',
        lg: '-170%',
      }}
    >
      <TextBubble />
      <Text
        fontFamily="grace"
        fontSize={{
          base: '18px',
          sm: '24px',
        }}
        position="absolute"
        width={{
          base: '80%',
          sm: '70%',
        }}
        textAlign="center"
        top={{
          base: '26%',
        }}
        left={{
          base: '6%',
          sm: '20%',
          lg: '6%',
        }}
        transform="rotate(-8deg)"
        color="violet.500"
      >
        {t('letsMeet.doodle')}
      </Text>
    </Box>
  );
};
