import React from 'react';

import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import Image from 'next/image';
import LinkedInImage from 'public/assets/icons/linkedin.png';

import { StrapiImage } from '@/components/atoms';
import { LeadershipFragment } from '@/graphql/generated';
import { TILE_WIDTH_PX } from '@/utils/constants';
import { normalizeUrl } from '@/utils/urls';

export const Leader = ({ attributes }: LeadershipFragment) => {
  const { avatar, name, url, position } = attributes!;

  const goToLinkedIn = () => {
    window.open(normalizeUrl(url), '_blank', 'noopener,noreferrer');
  };

  return (
    <Flex w={TILE_WIDTH_PX} flexDir="column" color="white">
      <Box w={TILE_WIDTH_PX} borderRadius="24px" overflow="hidden">
        <StrapiImage image={avatar} alt={name} />
      </Box>
      <Heading as="h3" variant="component-heading-large/lg" mt={6}>
        {name}
      </Heading>
      <Text variant="text-md/lineHeight-6/font-normal" mt={2}>
        {position}
      </Text>
      <Box mt={6} onClick={goToLinkedIn} cursor="pointer">
        <Image src={LinkedInImage} width={24} height={24} alt="LinkedIn logo" />
      </Box>
    </Flex>
  );
};
