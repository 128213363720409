import { ElementRef, useRef } from 'react';

import { Box, Flex, Heading, useMediaQuery, useQuery } from '@chakra-ui/react';
import Underline from 'images/highlights/line-3-cross.svg';
import { useTranslation } from 'next-i18next';

import { StaticBackground } from '@/components/atoms/StaticBackground';
import { Slider, TextDecorationSvg } from '@/components/functional';
import { SliderControls } from '@/components/functional/SliderControls/SliderControls';
import { AnimateWhenInViewWrapper, SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { PartnerFragment } from '@/graphql/generated';
import { LOCALES } from '@/utils/constants';

import { DynamicTextWithDecoration } from '../../dynamic-blocks/DynamicTextWithDecoration';
import { Card } from './Card';

interface PartnersProps {
  partners: PartnerFragment[];
  header?: string;
  variant?: 'dark' | 'light';
}

export const Partners = ({ partners, header, variant = 'dark' }: PartnersProps) => {
  const sliderRef = useRef<ElementRef<typeof Slider>>(null);
  const [isAboveXS] = useMediaQuery(useQuery({ above: 'xs' }));
  const isDarkVariant = variant === 'dark';
  const { t } = useTranslation(LOCALES.HOME);

  return (
    <SectionWrapper color={isDarkVariant ? 'white' : 'purple.500'}>
      <SectionContentWrapper>
        {!isDarkVariant && (
          <>
            <StaticBackground left={0} />
            <StaticBackground right="-50%" bottom="-50%" backgroundColor="violet.50" width="100%" height="100%" />
          </>
        )}
        <Flex direction={{ base: 'column', md: 'row' }}>
          <Heading
            as="h2"
            variant={{
              base: 'component-heading-large/xl',
              sm: 'component-heading-large/2xl',
              lg: 'component-heading-large/3xl',
            }}
            width="fit-content"
            maxW={{
              xs: '60%',
              md: '50%',
            }}
            flex={{
              base: 1,
              xs: 3,
              xl: 1,
            }}
          >
            {header ? (
              <DynamicTextWithDecoration>{header ?? ''}</DynamicTextWithDecoration>
            ) : (
              <span>
                {t('partners.title.0')}
                <br />
                <TextDecorationSvg line={Underline} color={isDarkVariant ? 'lime' : 'violet'} top="70%">
                  {t('partners.title.1')}
                </TextDecorationSvg>{' '}
                {t('partners.title.2')}
              </span>
            )}
          </Heading>
          <SliderControls
            onSlidePrev={() => sliderRef.current?.onPrev()}
            onSlideNext={() => sliderRef.current?.onNext()}
            variant={variant}
            pl={{ base: 14, sm: 4, lg: 10 }}
            flex={{
              base: 1,
              xs: 2,
              xl: 1,
            }}
          />
        </Flex>

        <Box pt={{ base: 12, sm: 12, md: 20 }} mx={{ base: -5, xs: 0 }}>
          <AnimateWhenInViewWrapper>
            <Slider ref={sliderRef} snapDirection={isAboveXS ? 'start' : 'center'} pl={{ base: 4, xs: 0 }}>
              {partners.map(
                (partner, index) =>
                  partner && <Card key={`${partner?.__typename}_${index}`} card={partner} variant={variant} />,
              )}
            </Slider>
          </AnimateWhenInViewWrapper>
        </Box>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};
