import React from 'react';

import { Box, BoxProps, Text } from '@chakra-ui/react';
import { FieldError } from 'react-hook-form';

type FormErrorMessageProps = {
  errorMessage?: FieldError | undefined;
} & BoxProps;

export const FormErrorMessage = ({ errorMessage, ...style }: FormErrorMessageProps) => {
  return (
    <>
      {errorMessage && (
        <Box position="absolute" bottom="-22px" sx={style}>
          <Text variant="text-xs/lineHeight-4/font-book/0.75rem" color="red.300">
            {errorMessage.message}
          </Text>
        </Box>
      )}
    </>
  );
};
