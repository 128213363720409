import React, { ReactElement, useMemo } from 'react';

import { Box, Flex, Grid, GridItem, Heading, Text, useMediaQuery, useQuery } from '@chakra-ui/react';
import Image from 'next/image';
import HistoryPoint from 'public/assets/icons/lime-circle.png';

import { AnimateWhenInViewWrapper, SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { DynamicHistorySectionFragment } from '@/graphql/generated';

import { DynamicTextWithDecoration } from '../DynamicTextWithDecoration';

const TABLET_VIEW_POINT_HEIGHT_SM_PX = 220;
const TABLET_VIEW_POINT_HEIGHT_XS_PX = TABLET_VIEW_POINT_HEIGHT_SM_PX * 1.2;

export const DynamicHistorySection = ({ header, items }: DynamicHistorySectionFragment) => {
  const [isAboveXS, isAboveLG, isAboveMD] = useMediaQuery([
    useQuery({ above: 'xs' }),
    useQuery({ above: 'lg' }),
    useQuery({ above: 'md' }),
  ]);
  const numberOfColumns = items.length + 1;

  const renderPointContent = ({ year, name, description }: any) => {
    return (
      <AnimateWhenInViewWrapper>
        <Text
          as="h3"
          variant="text-md/lineHeight-6/font-semibold"
          color="violet.500"
          mb={{
            base: 2,
            md: 4,
          }}
        >
          {year}
        </Text>
        <Text as="h2" variant="text-xl/lineHeight-7/font-semibold" mb={4}>
          {name}
        </Text>
        <Text variant="text-xs/lineHeight-4/font-normal/0.75rem" mb={4}>
          {description}
        </Text>
      </AnimateWhenInViewWrapper>
    );
  };

  const renderHorizontalTimeline = useMemo(() => {
    const numberOfPoints = items.length;
    const numberOfPointsIsOdd = numberOfPoints % 2 !== 0;
    return (
      <Grid
        gridTemplateColumns={`repeat(${numberOfColumns}, 1fr)`}
        gridTemplateRows="repeat(2, min-content)"
        gap={4}
        px={8}
      >
        {items.map((point, index) => {
          const gridNumber = index + 1;
          const isLastPoint = gridNumber === numberOfPoints;
          const isLastPointInTopRow = numberOfPointsIsOdd
            ? gridNumber === numberOfPoints
            : gridNumber === numberOfPoints - 1;
          const isPointEven = gridNumber % 2 === 0;
          const gridColumn = `${gridNumber} / span 2`;
          return (
            <GridItem
              gridColumn={gridColumn}
              gridRow={isPointEven ? 2 : 1}
              pb={isPointEven ? 10 : 28}
              pt={isPointEven ? 16 : 0}
              pl={6}
              key={`timeline-point-${index}`}
              position="relative"
            >
              <Flex width={isAboveLG ? '70%' : '90%'} flexDir="column">
                {renderPointContent(point)}
                <Flex
                  w="1px"
                  h="calc(100% - 25px)"
                  bgColor="purple.200"
                  position="absolute"
                  left={0}
                  top={isPointEven ? '5%' : 0}
                />
                {!isPointEven && !isLastPoint && (
                  <>
                    <Flex
                      w="calc(50% - 50px)"
                      h={1}
                      bgColor="purple.100"
                      position="absolute"
                      left={7}
                      top={isPointEven ? 0 : '100%'}
                    />
                    {!(numberOfPointsIsOdd && isLastPointInTopRow) && (
                      <Flex
                        w="calc(50% - 50px)"
                        h={1}
                        bgColor="purple.100"
                        position="absolute"
                        left="calc(50% + 37.5px)"
                        top={isPointEven ? 0 : '100%'}
                      />
                    )}
                  </>
                )}
                <Box position="absolute" left={-3} top={isPointEven ? -7 : 'calc(100% - 12px)'}>
                  <Image src={HistoryPoint} width={24} height={24} alt="history point dot" />
                </Box>
              </Flex>
            </GridItem>
          );
        })}
      </Grid>
    );
  }, [items, isAboveLG, numberOfColumns]);

  const renderVerticalTwoSidedTimeline = useMemo(() => {
    const leftCol: ReactElement[] = [];
    const rightCol: ReactElement[] = [];

    items.map((point, index) => {
      const isEven = index % 2 === 0;
      const isLastPoint = index === items.length - 1;

      const pointTemplate = (
        <Flex
          key={`timeline-point-${index}`}
          flexDir="column"
          alignItems={isEven ? 'end' : 'start'}
          sx={{
            'h2, h3, p': {
              textAlign: isEven ? 'right' : 'left',
            },
          }}
        >
          <Flex
            flexDir="column"
            pb={6}
            pt={2}
            borderTop="1px solid"
            borderColor="purple.200"
            position="relative"
            h={{
              xs: `${TABLET_VIEW_POINT_HEIGHT_XS_PX}px`,
              sm: `${TABLET_VIEW_POINT_HEIGHT_SM_PX}px`,
            }}
          >
            {renderPointContent(point)}
            <Box position="absolute" left={isEven ? 'calc(100% + 32px)' : -12} top="-14px" w={6} h={6}>
              <Image src={HistoryPoint} fill alt="history point dot" />
            </Box>
            {!isLastPoint && (
              <Flex
                w={1}
                h={{
                  xs: '84px',
                  sm: 16,
                }}
                bgColor="purple.100"
                position="absolute"
                left={isEven ? 'calc(100% + 42px)' : '-38px'}
                top="22px"
              />
            )}
          </Flex>
        </Flex>
      );

      if (isEven) {
        leftCol.push(pointTemplate);
      } else {
        rightCol.push(pointTemplate);
      }
    });

    return (
      <Flex
        mb={-14}
        px={{
          base: 4,
          sm: 12,
        }}
      >
        <Flex flex={1} flexDir="column" alignItems="end" p={6} pr={10}>
          {leftCol.map((point) => point)}
        </Flex>
        <Flex
          mt={{
            xs: `${TABLET_VIEW_POINT_HEIGHT_XS_PX / 2}px`,
            sm: `${TABLET_VIEW_POINT_HEIGHT_SM_PX / 2}px`,
          }}
          flex={1}
          flexDir="column"
          p={6}
          pl={10}
        >
          {rightCol.map((point) => point)}
        </Flex>
      </Flex>
    );
  }, [items]);

  const renderSimpleVerticalTimeline = useMemo(() => {
    return (
      <Flex flexDir="column" p={6} m={{ base: 2, sm: 6, md: 8 }} pl={9}>
        {items.map((point, index) => {
          const isLastPoint = index === items.length - 1;

          return (
            <Flex key={`timeline-point-${index}`} flexDir="column">
              <Flex flexDir="column" py={6} borderTop="1px solid" borderColor="purple.200" position="relative" pl={2}>
                {renderPointContent(point)}
                <Box position="absolute" left="-38px" top="-14px" w={6} h={6}>
                  <Image src={HistoryPoint} fill alt="history point dot" />
                </Box>
                {!isLastPoint && (
                  <Flex w={1} h="calc(100% - 46px)" bgColor="purple.100" position="absolute" left="-28px" top="22px" />
                )}
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    );
  }, [items]);

  return (
    <SectionWrapper zIndex={0} id="aBitOfHistory">
      <SectionContentWrapper>
        <Heading
          variant={{
            base: 'component-heading-large/xl',
            xs: 'component-heading-large/2xl',
            sm: 'component-heading-large/3xl',
          }}
          textAlign={{
            base: 'left',
            xs: 'center',
            md: 'left',
          }}
          mb={{
            base: 12,
            sm: 16,
            lg: 24,
          }}
        >
          <DynamicTextWithDecoration>{header}</DynamicTextWithDecoration>
        </Heading>

        {!isAboveXS
          ? renderSimpleVerticalTimeline
          : !isAboveMD
          ? renderVerticalTwoSidedTimeline
          : renderHorizontalTimeline}
      </SectionContentWrapper>
    </SectionWrapper>
  );
};
