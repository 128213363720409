import { Avatar, Divider, Flex, Grid, Hide, Show, Text } from '@chakra-ui/react';
import Facebook from 'images/icons/brand/facebook.svg';
import LinkedIn from 'images/icons/brand/linked-in.svg';
import Twitter from 'images/icons/brand/twitter.svg';
import { useTranslation } from 'next-i18next';

import { ShareButton } from '@/components/atoms';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { UserFragment } from '@/graphql/generated';
import { useLongDateFormatter, useShareUrls } from '@/hooks';
import { LOCALES } from '@/utils/constants';

export interface ArticleFooterProps {
  authors: UserFragment[];
  createdAt: DateLike;
}

export const ArticleFooter = ({ authors, createdAt }: ArticleFooterProps) => {
  const formatDate = useLongDateFormatter();
  const shareUrls = useShareUrls();
  const { t } = useTranslation([LOCALES.COMMON]);

  return (
    <SectionWrapper>
      <SectionContentWrapper maxW={{ md: '628px' }} paddingX={{ base: 5, sm: 8, md: 0 }} paddingY={undefined} pt={14}>
        <Hide above="xs">
          <Text variant="text-sm/lineHeight-5/font-normal" color="purple.300" mb="4">
            {t('publishedOn')} {formatDate(createdAt)}
          </Text>
        </Hide>
        <Flex mb="4" align="center">
          <Text variant="text-sm/lineHeight-5/font-normal" color="purple.300" mr="2">
            {t('share')}
          </Text>

          <Flex gap="2">
            <ShareButton aria-label="share article via facebook" icon={<Facebook />} href={shareUrls.facebook} />
            <ShareButton aria-label="share article via twitter" icon={<Twitter />} href={shareUrls.twitter} />
            <ShareButton aria-label="share article via linked in" icon={<LinkedIn />} href={shareUrls.linkedIn} />
          </Flex>

          <Show above="xs">
            <Text variant="text-sm/lineHeight-5/font-normal" color="purple.300" alignSelf="flex-end" ml="auto">
              {t('publishedOn')} {formatDate(createdAt)}
            </Text>
          </Show>
        </Flex>

        <Divider borderColor="purple.100" />

        <Grid pt="10" gap="10">
          {authors.map((author) => (
            <Flex key={author.id} gap="6" align="center">
              <Avatar
                size="lg"
                name={author.attributes?.username}
                src={author.attributes?.avatar?.data?.attributes?.url}
                alignSelf="flex-start"
              />
              <Text color="purple.400" variant="text-sm/lineHeight-5/font-normal">
                <Text as="span" fontWeight="semibold">
                  {author.attributes?.username}
                </Text>{' '}
                {author.attributes?.description}
              </Text>
            </Flex>
          ))}
        </Grid>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};
