import { FC } from 'react';

import { MediaSection } from '@/components/sections/services/sections';
import { DynamicMediaFragment } from '@/graphql/generated';

export const DynamicMedia: FC<DynamicMediaFragment> = ({ items }) => {
  const parsedItems =
    items?.map((item) => ({
      id: item?.id ?? '',
      name: item?.name ?? '',
      coverVideo: item?.coverVideo.data?.attributes ?? {},
      coverImageFallback: item?.coverImageFallback.data?.attributes ?? {},
    })) ?? [];
  return <MediaSection items={parsedItems} />;
};
