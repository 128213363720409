import { Box, Flex, Heading, Show, Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { CompanyStatisticsBackground } from '@/components/sections/home';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { CompanyStatisticsFragment } from '@/graphql/generated';
import { LOCALES } from '@/utils/constants';

import { DynamicTextWithDecoration } from '../../dynamic-blocks/DynamicTextWithDecoration';
import { CakeDoodle } from './CakeDoodle';
import { Statistics } from './Statistics';

interface CompanyStatisticsProps {
  data: CompanyStatisticsFragment;
}
export const CompanyStatistics = ({ data }: CompanyStatisticsProps) => {
  const { cakeDoodle, companyStatisticsCard } = data;
  const { t } = useTranslation(LOCALES.HOME);

  return (
    <SectionWrapper>
      <SectionContentWrapper>
        <Show above="md">
          <CompanyStatisticsBackground />
        </Show>
        <Flex
          gap={{ md: 7 }}
          pt={{
            lg: 16,
          }}
          justifyContent={{ md: 'space-between' }}
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <Box>
            <Heading
              variant={{
                base: 'component-heading-large/xl',
                sm: 'component-heading-large/3xl',
              }}
              maxW="400px"
              color="purple.500"
            >
              {t('statistics.title.0')}{' '}
              <DynamicTextWithDecoration isDarkMode={true}>{t('statistics.title.1')}</DynamicTextWithDecoration>
            </Heading>
            <Text
              variant={{
                base: 'text-sm/lineHeight-5/font-normal',
                sm: 'text-md/lineHeight-6/font-normal',
              }}
              pt={{ base: 6 }}
              maxW="410px"
              color="purple.500"
            >
              {t('statistics.description')}
            </Text>
            <CakeDoodle doodle={cakeDoodle} />
          </Box>
          <Show above="md">
            <Flex pt={'180px'} justifyContent={'flex-end'} alignItems="center">
              <Statistics cardsData={companyStatisticsCard} />
            </Flex>
          </Show>
        </Flex>
      </SectionContentWrapper>
      <Show below="md">
        <Flex pt={10} justifyContent={'center'} alignItems="center">
          <Statistics cardsData={companyStatisticsCard} />
        </Flex>
      </Show>
    </SectionWrapper>
  );
};
