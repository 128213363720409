import React, { FC } from 'react';

import { DynamicFaqFragment } from '@/graphql/generated';
import { parseTextDecoration } from '@/utils/text-decorations';

import { QASection } from '../../services/sections';

export const DynamicFaqBlock: FC<DynamicFaqFragment> = ({ header, items: faqItems, decoratedTextImages }) => {
  const parsedHeader = header.replaceAll('\\n', '\n');
  return (
    <QASection
      header={parsedHeader}
      items={faqItems.map((el) => ({ header: el?.question || '', text: el?.answer }))}
      isDarkMode
      textDecoration={parseTextDecoration(decoratedTextImages)}
    />
  );
};
