import { GradientBackground } from '@/components/atoms';
import { GradientBackgroundProps } from '@/utils/models/background-props';

export const CakeDoodleBackground = ({ isInView }: GradientBackgroundProps) => {
  return (
    <>
      <GradientBackground
        isInView={isInView}
        filter="blur(104px)"
        position="absolute"
        top={{ base: '-100px', md: '-70%' }}
        left={{ base: '-311px', md: '-107px' }}
        w={{ base: '690px' }}
        h={{ base: '690px' }}
        zIndex="-2"
      />
    </>
  );
};
