import { Box, Heading, List, ListItem, Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { useScrollOnClick } from '@/hooks';
import { LOCALES } from '@/utils/constants';

import { Chapter, useContentChapters } from './use-content-chapters';

export interface ArticleTableOfContentProps {
  content: string;
}

export const ArticleTableOfContent = ({ content }: ArticleTableOfContentProps) => {
  const chapters = useContentChapters(content);
  const { t } = useTranslation([LOCALES.COMMON]);

  if (!chapters || chapters.length === 0) {
    return null;
  }
  return (
    <Box>
      <Heading
        as="h2"
        color="purple.500"
        variant={{
          base: 'component-text/xl',
          md: 'component-text/2xl',
        }}
        mb="4"
      >
        {t('tableOfContent')}
      </Heading>
      <ChaptersList chapters={chapters} />
    </Box>
  );
};

interface ChaptersListProps {
  chapters: Chapter[];
  level?: number;
}

const ChaptersList = ({ chapters, level = 0 }: ChaptersListProps) => {
  const handleScrollToElement = useScrollOnClick();

  if (!chapters.length) {
    return null;
  }

  return (
    <List textDecor="underline" color="purple.500">
      {chapters.map((chapter) => (
        <ListItem {...(level > 0 && { pl: 6 })} key={chapter.id} cursor="pointer">
          <Text key="chapter-name" data-element={chapter.id} onClick={handleScrollToElement}>
            {chapter.label}
          </Text>
          <ChaptersList key="subchapters" chapters={chapter.children} level={level + 1} />
        </ListItem>
      ))}
    </List>
  );
};
