import { Flex, Heading, Text, VStack } from '@chakra-ui/react';

import { Button } from '@/components/atoms';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { DynamicTextWithPhotosFragment } from '@/graphql/generated';

import { DynamicTextWithDecoration } from '../DynamicTextWithDecoration';
import { PhotoWithHover } from './components/PhotoWithHover';

export const DynamicTextWithPhotos = ({
  headerStartText,
  headerMiddleText,
  headerEndText,
  desc,
  firstAvatar,
  secondAvatar,
  button,
}: DynamicTextWithPhotosFragment) => {
  return (
    <SectionWrapper>
      <SectionContentWrapper>
        <Flex flexDir="column" gap="32px">
          <VStack alignItems="center" justifyContent="center" flexDir="row" flexWrap="wrap">
            {firstAvatar ? (
              <PhotoWithHover
                src={firstAvatar.image.data?.attributes?.url || ''}
                bubble={{
                  name: firstAvatar.name || '',
                  role: firstAvatar.role || '',
                  hoverText: firstAvatar.hoverText || '',
                  bubblePosition: 'bottom',
                }}
              />
            ) : null}
            <Heading
              variant={{
                base: 'component-heading-large/xl',
                xs: 'component-heading-large/2xl',
                sm: 'component-heading-large/3xl',
              }}
              textAlign="center"
              color="purple.500"
            >
              {headerStartText ? <DynamicTextWithDecoration>{headerStartText}</DynamicTextWithDecoration> : null}
            </Heading>
          </VStack>
          <VStack alignItems="center" justifyContent="center" flexDir="row" flexWrap="wrap">
            <Heading
              variant={{
                base: 'component-heading-large/xl',
                xs: 'component-heading-large/2xl',
                sm: 'component-heading-large/3xl',
              }}
              textAlign="center"
              color="purple.500"
            >
              {headerMiddleText ? <DynamicTextWithDecoration>{headerMiddleText}</DynamicTextWithDecoration> : null}
            </Heading>

            {secondAvatar ? (
              <PhotoWithHover
                src={secondAvatar.image.data?.attributes?.url || ''}
                bubble={{
                  name: secondAvatar.name || '',
                  role: secondAvatar.role || '',
                  hoverText: secondAvatar.hoverText || '',
                  bubblePosition: 'bottom',
                }}
              />
            ) : null}

            <Heading
              variant={{
                base: 'component-heading-large/xl',
                xs: 'component-heading-large/2xl',
                sm: 'component-heading-large/3xl',
              }}
              textAlign="center"
              color="purple.500"
            >
              {headerEndText ? <DynamicTextWithDecoration>{headerEndText}</DynamicTextWithDecoration> : null}
            </Heading>
          </VStack>
          {desc ? (
            <VStack alignItems="center" justifyContent="center" flexDir="row" mb={6}>
              <Text
                variant={{
                  base: 'text-md/lineHeight-6/font-normal',
                  sm: 'text-xl/lineHeight-7/font-normal',
                }}
                textAlign="center"
                color="purple.400"
              >
                <DynamicTextWithDecoration>{desc}</DynamicTextWithDecoration>
              </Text>
            </VStack>
          ) : null}
        </Flex>
        {button?.url && button?.label ? (
          <VStack alignItems="center" justifyContent="center">
            <Button
              w={{
                base: '100%',
                xs: 'auto',
              }}
              size="lg"
              variant={button?.variant}
              url={button?.url}
            >
              {button?.label}
            </Button>
          </VStack>
        ) : null}
      </SectionContentWrapper>
    </SectionWrapper>
  );
};
