import { Box, useMediaQuery, useQuery } from '@chakra-ui/react';
import Satellite from 'images/backgrounds/satellite.png';
import Image from 'next/image';

import { GradientBackground } from '@/components/atoms';
import { GradientBackgroundProps } from '@/utils/models/background-props';

import { AnimatedRocket } from './AnimatedRocket';

export const TechStackBackground = ({ isInView }: GradientBackgroundProps) => {
  const [isAboveSM] = useMediaQuery([useQuery({ above: 'sm' })]);

  return (
    <>
      <Box
        position="absolute"
        zIndex="-2"
        as={Image}
        src={Satellite}
        alt="satellite"
        width={{
          base: '90px',
          sm: '154px',
        }}
        top={{
          base: '12%',
          sm: '0',
          md: '15%',
        }}
        left={{
          base: 5,
          sm: '50%',
          md: '10%',
          xl: '9%',
          '3xl': 96,
        }}
      />
      <Box
        pl={{ base: '0', lg: '18%', '2xl': '24%' }}
        display={{ base: 'flex', md: 'block' }}
        justifyContent="center"
        mt={{ base: 12, md: 0 }}
        position="absolute"
        bottom={{ base: '0', md: -72 }}
        left={{
          base: '0',
          '2xs': '5%',
          xs: '20%',
          sm: '27%',
          md: '25%',
          lg: '8%',
          xl: '10%',
          '2xl': '5%',
          '3xl': '10%',
        }}
      >
        <AnimatedRocket shouldAnimate={isAboveSM} />
      </Box>
      <GradientBackground
        isInView={isInView}
        w={{ base: '828px', sm: '100%', md: '1014px' }}
        h={{ base: '800px', md: '500px' }}
        position="absolute"
        left={{
          base: '-60%',
          xs: '-30%',
          sm: '0',
          md: '250px',
          lg: '450px',
        }}
        bottom={{ base: '-220px', md: '0' }}
        top={{ md: '0' }}
        filter="blur(104px)"
        zIndex="-3"
      />
    </>
  );
};
