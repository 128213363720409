import { Box, useMediaQuery, useTheme } from '@chakra-ui/react';
import Line from 'images/backgrounds/statistics-line.svg';
import Star from 'images/doodles/star3.png';
import Image from 'next/image';

export const CompanyStatisticsBackground = () => {
  const theme = useTheme();
  const [isLargerThanSm, isLargerThanMd] = useMediaQuery([
    `(min-width: ${theme.breakpoints.sm})`,
    `(min-width: ${theme.breakpoints.md})`,
  ]);

  return (
    <>
      {isLargerThanSm && (
        <Box
          as={Image}
          src={Star}
          alt=""
          position="absolute"
          zIndex="-2"
          right={{
            base: '12%',
            md: '4%',
            lg: '8%',
          }}
          top={{
            base: '155%',
            md: '80%',
          }}
          width="auto"
          height="50px"
          transform="rotate(150deg)"
        />
      )}
      {isLargerThanMd && (
        <Box
          position="absolute"
          as={Image}
          src={Star}
          alt=""
          right={{
            base: '42%',
          }}
          top="30%"
          width="auto"
          height="58px"
          transform="rotate(77.55deg)"
          zIndex="-2"
        />
      )}
      <Box
        position="absolute"
        right="0"
        left="0"
        top="0"
        bottom="0"
        zIndex="-2"
        sx={{
          '& > svg': {
            base: {
              position: 'absolute',
              left: 5,
              bottom: '-215%',
              width: '107px',
              height: '89px',
            },
            xs: {
              left: '35%',
              bottom: 'initial',
              top: '35%',
            },
            sm: {
              bottom: 'initial',
              left: 'initial',
              top: '45%',
              right: '40%',
            },
            md: {
              top: '20%',
              right: '8%',
            },
            lg: {
              right: '15%',
            },
            '2xl': {
              right: '15%',
            },
          },
        }}
      >
        <Line />
      </Box>
    </>
  );
};
