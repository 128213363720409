import React, { FC } from 'react';

import { DynamicDarkInfoDividerFragment } from '@/graphql/generated';

import { TailorMadeSection } from '../../services/sections';

export const DynamicDarkInfoDividerBlock: FC<DynamicDarkInfoDividerFragment> = ({
  header,
  description,
  buttonCaption,
  buttonLink,
}) => {
  return (
    <TailorMadeSection
      header={header}
      description={description}
      buttonCaption={buttonCaption}
      buttonLink={buttonLink}
    />
  );
};
