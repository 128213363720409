import { Box } from '@chakra-ui/react';
import Twirl from 'images/backgrounds/twirl2.png';
import Image from 'next/image';

import { GradientBackground } from '@/components/atoms';
import { GradientBackgroundProps } from '@/utils/models/background-props';

export const ClutchScoreBackground = ({ isInView }: GradientBackgroundProps) => {
  return (
    <>
      <Box
        position="absolute"
        as={Image}
        alt=""
        src={Twirl}
        zIndex="-1"
        sx={{
          '&': {
            base: {
              position: 'absolute',
              left: ' -134.49%',
              bottom: '0',
              width: '972px',
              height: '732px',
              transform: 'matrix(1, 0.05, -0.05, 1, 0, 0)',
            },
            '2xs': {
              left: '-100%',
            },
            xs: {
              left: ' initial',
              right: '-30%',
            },
            sm: {
              width: '975px',
              height: '750px',
              right: '-10%',
              top: 'initial',
              bottom: '-20%',
            },
            md: {
              left: 'initial',
              bottom: '-20%',
              right: '0',
            },
            lg: {
              width: '1133px',
              height: '872px',
              right: '-10.62%',
              bottom: '0',
            },
            xl: {
              right: '-5%',
            },
            '2xl': {
              right: '0',
            },
            '3xl': {
              right: 52,
            },
          },
        }}
      />
      <GradientBackground
        isInView={isInView}
        position="absolute"
        top={{ base: '300px', sm: '231px' }}
        left={{ base: '0', sm: '491px' }}
        right="0"
        bottom="0"
        zIndex="-2"
      />
    </>
  );
};
