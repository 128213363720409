import React, { FC } from 'react';

import { DynamicTestimonialFragment } from '@/graphql/generated';
import { getImageSrcFromAttributes } from '@/utils/images';
import { parseTextDecoration } from '@/utils/text-decorations';

import { QuotationSection } from '../../services/sections';
import { DynamicTextWithDecoration } from '../DynamicTextWithDecoration';

export const DynamicTestimonialBlock: FC<DynamicTestimonialFragment> = ({
  quote,
  person,
  position,
  photo,
  decoratedTextImages,
}) => {
  return (
    <QuotationSection
      text={
        <DynamicTextWithDecoration
          isCircleDarkMode={!!decoratedTextImages?.isCircleDarkMode}
          {...parseTextDecoration(decoratedTextImages)}
        >{`“${quote}”`}</DynamicTextWithDecoration>
      }
      author={{
        name: person,
        role: position,
        avatar: getImageSrcFromAttributes(photo?.data?.attributes, 'small'),
      }}
    />
  );
};
