import React, { FC } from 'react';

import { Flex, Show } from '@chakra-ui/react';

import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { StandaloneContactFormFragment } from '@/graphql/generated';

import { DynamicContactFormCompact } from '../DynamicContactFormCompact';

export const StandaloneContactForm: FC<StandaloneContactFormFragment> = (props) => (
  <Show below="lg">
    <SectionWrapper bg="violet.500">
      <SectionContentWrapper>
        <Flex justify="center">
          <DynamicContactFormCompact {...props} />
        </Flex>
      </SectionContentWrapper>
    </SectionWrapper>
  </Show>
);
