import React from 'react';

import { Box, useMediaQuery, useQuery } from '@chakra-ui/react';
import BluePaintbrushDoodleMobile from 'images/doodles/blue-paintbrush-square-mobile.png';
import BluePaintbrushDoodle from 'images/doodles/blue-paintbrush-square.png';
import Image from 'next/image';

export const PaintbrushDoodle = () => {
  const [isAboveSM] = useMediaQuery([useQuery({ above: 'sm' })]);

  return (
    <Box
      position="absolute"
      width={{
        base: '100vw',
        sm: '42vw',
      }}
      height={{
        base: '90vw',
        sm: '35vw',
      }}
      maxW={{
        base: 'unset',
        sm: '602px',
      }}
      maxH={{
        base: 'unset',
        sm: '500px',
      }}
      minW="353px"
      minH="294px"
      left={{
        base: '0',
        sm: '-3%',
        md: '0',
      }}
      right="15%"
      bottom={{
        base: '-6%',
        xs: '-10%',
        sm: '0',
      }}
      zIndex={1}
    >
      <Image src={isAboveSM ? BluePaintbrushDoodle : BluePaintbrushDoodleMobile} alt="doodle" fill sizes="33vw" />
    </Box>
  );
};
