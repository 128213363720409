import React, { useState } from 'react';

import { Box } from '@chakra-ui/react';
import Image from 'next/image';

import { CommentBubble } from '@/components/atoms/CommentBubble/CommentBubble';

import { bubbleAnimation, bubbleStyle } from './styles';

type PhotWithProps = {
  src: string;
  bubble: {
    name: string;
    hoverText: string;
    role: string;
    bubblePosition: 'top' | 'bottom';
  };
};

const DEFAULT_WIDTH = 140;
const DEFAULT_HEIGHT = 72;

export const PhotoWithHover = ({ src, bubble }: PhotWithProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const onMouseEnter = () => {
    setIsHovered(true);
  };
  const onMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Box position="relative" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} cursor="pointer">
      <Image src={src} alt={bubble.name} loading="eager" width={DEFAULT_WIDTH} height={DEFAULT_HEIGHT} />
      <CommentBubble
        personRole={bubble.role}
        message={bubble.hoverText}
        name={bubble.name}
        isRevealed={isHovered}
        top={bubble.bubblePosition === 'top' ? '-160px' : '90px'}
        left={'-40px'}
        animation={isHovered ? `${bubbleAnimation} 4s` : 'none'}
        {...bubbleStyle}
        minW={'240px'}
        maxW={'300px'}
      />
    </Box>
  );
};
