import { Box, GridItem } from '@chakra-ui/react';
import Image from 'next/image';

import { LetsMeetDoodle } from '@/components/sections/home/LetsMeet/Doodle/Doodle';
import { getImageAlt } from '@/utils/images';
import { MemberProps } from '@/utils/models/lets-meet-section-member-props';

import { RevealedMember } from '../RevealedMember';
import { memberGridStyles } from './member-grid-styles';

export const MemberGrid = ({ avatar, doodle, chatBubble, index, setRevealedItem, revealedItem }: MemberProps) => {
  const isItemRevealed = revealedItem === index;

  return (
    <GridItem
      className="member"
      position="relative"
      onClick={() => setRevealedItem(isItemRevealed ? null : index)}
      cursor="pointer"
      zIndex={isItemRevealed ? '2' : 'auto'}
      sx={{
        ...memberGridStyles,
        '&:nth-of-type(2) .doodle': {
          transform: isItemRevealed ? 'translateX(-20px)' : 'translateY(0)',
        },
      }}
    >
      <Box
        className="doodle"
        w="85px"
        h="85px"
        zIndex="1"
        visibility={!isItemRevealed ? 'visible' : 'hidden'}
        aria-hidden={isItemRevealed}
      >
        <Image
          src={doodle.data?.attributes?.url ?? ''}
          alt={getImageAlt(doodle.data?.attributes)}
          width={85}
          height={77}
          placeholder="empty"
          loading="eager"
          style={{ maxWidth: 'fit-content', width: '85px', height: '85px' }}
        />
      </Box>
      {isItemRevealed && <RevealedMember avatar={avatar} chatBubble={chatBubble} isRevealed={isItemRevealed} />}
      {index === 0 && <LetsMeetDoodle />}
    </GridItem>
  );
};
