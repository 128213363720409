import { Grid, GridItem } from '@chakra-ui/react';

import { AnimateWhenInViewWrapper } from '@/components/wrappers';
import { ClutchReviewFragment } from '@/graphql/generated';

import { ReviewCard } from '../ReviewCard';
import { reviewsStyles } from './reviews-styles';

interface ReviewsProps {
  reviews: ClutchReviewFragment[];
}

export const Reviews = ({ reviews }: ReviewsProps) => {
  return (
    <GridItem>
      <Grid
        mt={{ base: 8, sm: 0 }}
        gap={{ base: 3, lg: 0 }}
        gridTemplateColumns={{
          base: '1fr',
          sm: 'repeat(2, minmax(0, 320px))',
        }}
        gridTemplateRows={{ sm: '1fr auto' }}
        rowGap={{ sm: 5, lg: 5 }}
        justifyItems={{ base: 'center' }}
        justifyContent={{ sm: 'end', lg: 'end' }}
        h={{ lg: '100%' }}
        sx={reviewsStyles}
      >
        {reviews.map((review, index) => {
          const { content, ...reviewerData } = review;

          return (
            <AnimateWhenInViewWrapper
              key={`clutch-review-${index}`}
              className="review-card"
              additionalDelay={index * 0.75}
            >
              <ReviewCard review={content} reviewerData={reviewerData} />
            </AnimateWhenInViewWrapper>
          );
        })}
      </Grid>
    </GridItem>
  );
};
